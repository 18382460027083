import { makeStyles } from "@material-ui/core/styles";
import { Clear, Search, ShoppingCart, Visibility } from '@material-ui/icons';
import Tooltip from "@material-ui/core/Tooltip";
import { Alert, FilledInput, FormControl, InputAdornment, InputLabel } from '@mui/material';
import { grayColor, infoColor } from 'assets/jss/material-kit-pro-react';
import Button from "components/CustomButtons/Button";
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClipLoader from "react-spinners/ClipLoader";
import { setSelectedFeatures, unselectFeature } from '../../redux/map';
import { addItem } from '../../redux/shoppingCart';

import asyncSearchStyle from "assets/jss/material-kit-pro-react/components/asyncSearchStyle";
import previewModalStyle from "assets/jss/material-kit-pro-react/components/previewModalStyle";
import CustomDropdown from 'components/CustomDropdown/CustomDropdown';
import SnackbarContent from 'components/Snackbar/SnackbarContent';
import { DownloadItem } from 'models/DownloadItem';
import { DataType } from 'models/enums/DataType';
import { FileType } from 'models/enums/FileType';
import { useGetParcelDownloadPreviewMutation, useGetProductsQuery } from 'redux/dataApi';
import { PriceDropdownItem } from '../CustomDropdown/PriceDropdownItem';
import { PreviewModal } from './PreviewModal';
import { getBounds } from "utils/Helpers";

const useStyles = makeStyles(asyncSearchStyle);
const usePreviewModalStyles = makeStyles(previewModalStyle);

type OptionType = { [key: string]: any }
type OptionsType = Array<OptionType>
type GroupType = {
    label: string,
    options: OptionsType,
}

const DropdownPlaceholder = (props) => {
    const classes = useStyles();

    return (
        <div className={`${classes.dropdownOption} ${classes.dropdownPlaceholder}`}>
            <div className={classes.dropdownLabelContainer}>
                <p className={classes.dropdownOptionLabel}>
                    No Results
                </p>
            </div>
        </div>
    );
}

const DropdownOption = (props) => {

    const {
        label,
        secondaryLabel,
        value,
        onClick
    } = props;

    const classes = useStyles();

    return (
        <div 
            className={classes.dropdownOption} 
            onMouseDown={event => event.preventDefault()}
            onClick={() => { onClick({label, value}) }}
        >
            <div className={classes.dropdownLabelContainer}>
                <p className={classes.dropdownOptionLabel}>
                    {label}
                </p>
                <p className={classes.dropdownOptionSecondaryLabel}>
                    {secondaryLabel}
                </p>
            </div>
        </div>
    );
}

const DropdownGroup = (props) => {

    const {
        label,
        options,
        optionClick
    } = props;

    const classes = useStyles();

    return (
        <>
        <div className={classes.dropdownGroup}>
            <p className={classes.dropdownGroupLabel}>
                {label}
            </p>
        </div>
        { 
            options.map(option => (
                <DropdownOption 
                    key={option.label}
                    label={option.label}
                    secondaryLabel={option.secondaryLabel}
                    value={option.value}
                    onClick={optionClick}
                /> 
            ))
        }
        </>
    );
}

const Dropdown = (props) => {

    const {
        groups,
        optionClick
    } = props;

    const classes = useStyles();

    const optionCount = groups?.length + groups?.reduce((previous, current) => {
        return previous + current.options.length;
    }, 0);

    return (
        <div className={classes.dropdown}>
            {
                optionCount === 0
                ? <DropdownPlaceholder/>
                : groups.map(group => {
                    return (
                        <DropdownGroup 
                            key={group.label}
                            label={group.label} 
                            options={group.options}
                            optionClick={optionClick}
                        />
                    );
                }) 
            }
        </div>
    )
}

const SelectionLabel = ({ dataType, showClear, clear }) => {
    const classes = useStyles();

    return (
        <div className={classes.selectionLabel}>
            <p>Your Selected {DataType[dataType].selectionLabel}</p>
            { showClear && (
                <button className={classes.selectionItemRemoveButton} onClick={clear}>
                    <Clear fontSize='small'/>
                </button>
            )}
        </div>
    )
}

const GetStarted = ({ dataType, hasParcels }) => {
    const classes = useStyles();

    return (
        <div className={classes.selectionItem}>
            <div className={classes.selectionItemContent}>
                { DataType[dataType].getStartedMessage(hasParcels) }
            </div>
        </div>
    )
}

const SelectionItem = ({ label, remove }) => {
    const classes = useStyles();

    return (
        <div className={classes.selectionItem}>
            <p>{label}</p>
            <button className={classes.selectionItemRemoveButton} onClick={remove}>
                <Clear fontSize='small'/>
            </button>
        </div>
    )
}

export const AsyncSearchSection = (props) => {

    const {
        dataType,
        map,
        county,
        countyGeography,
    } = props;

    const dispatch = useDispatch();
    const classes = useStyles();
    const previewModalClasses = usePreviewModalStyles()

    const defaultDataSubType = dataType === DataType.contourLines.key
        ? DataType.contourLines.interval1Ft.key
        : 'default';

    const countyBounds = new google.maps.LatLngBounds(
        countyGeography.countyBoundaries.bounds.sw,
        countyGeography.countyBoundaries.bounds.ne
    )

    const {
        data: products
      } = useGetProductsQuery({ type: dataType, county })
    const { selectedFeatures, selectableFeatures } = useSelector((state: any) => state.map);
    const shoppingCartItems = useSelector((state: any) => DownloadItem.hydrate(state.shoppingCart?.items));
    const [selectedFileType, setSelectedFileType] = useState(null)
    const [dataSubType, setDataSubType] = useState(defaultDataSubType)
    const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete>(null);
    const [parcelData, setParcelData] = useState([]);
    const [addressData, setAddressData] = useState([]);
    const [initialFocus, setInitialFocus] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [displayDropdown, setDisplayDropdown] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [addressMarker, setAddressMarker] = useState<google.maps.Marker>(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [previewOpen, setPreviewOpen] = useState(false);

    const [getParcelDownloadPreview] = useGetParcelDownloadPreviewMutation()

    const optionGroups = [];
    if (!isEmpty(searchTerm) && !isEmpty(parcelData)) {
        optionGroups.push({
            label: 'Parcels',
            options: parcelData?.map(parcel => ({
                label: parcel.properties.Parcel_No,
                value: {
                    type: 'parcel',
                    value: parcel
                }
            }))
        })
    }
    if (!isEmpty(searchTerm) && !isEmpty(addressData)) {
        optionGroups.push({
            label: 'Addresses',
            options: addressData.map(address => ({
                ...address,
                value: {
                    type: 'address',
                    value: address.value
                }
            }))
        })
    }

    useEffect(() => {
        setupAutocomplete();

        // On Unmount
        return () => {
            if (autocomplete) {
                google.maps.event.clearInstanceListeners(autocomplete);
            }
            document.querySelectorAll('.pac-container').forEach(element => element.remove())
        }
    }, []);

    useEffect(() => {
        if (autocomplete) {
            setupSearchObserver();
        }
    }, [autocomplete])

    useEffect(() => {
        setPreviewImage(null)
    }, [selectedFeatures])

    useEffect(() => {
        if (searchTerm) {
            const searchTerms = searchTerm.toUpperCase().split(/[ \-_]/g)
            let results = countyGeography.parcelPointsData?.features?.map(parcel => ({
                parcel,
                currentSearchableValue: parcel.properties.Parcel_No.replace(/ /g, '')
            }))
            searchTerms.forEach(term => {
                results = results?.filter(result => result.currentSearchableValue.search(term) >= 0)
                    .sort((a, b) => {
                        const aIndex = a.currentSearchableValue.search(term)
                        const bIndex = b.currentSearchableValue.search(term)
                        if (aIndex < bIndex) { return -1; }
                        if (bIndex < aIndex) { return 1; }
                        return 0
                    }).map(result => {
                        const newResult = result;
                        const termIndex = result.currentSearchableValue.search(term)
                        const filler = new Array(termIndex + term.length).join(' ')
                        let newSearchTerm = filler + result.currentSearchableValue.slice(termIndex + term.length)
                        newResult.currentSearchableValue = newSearchTerm
                        return newResult
                    })
            });
            //@ts-ignore
            results = results?.filter((value, index, self) => (
                    self.findIndex(result => result.parcel.properties.Parcel_No === value.parcel.properties.Parcel_No) === index
                ))
                .slice(0, 5)
                .map(result => result.parcel)
            setParcelData(results);
        }
    }, [searchTerm])

    useEffect(() => {
        if (selectedOption?.value?.type === 'address') {
            const service = new google.maps.places.PlacesService(map);
            service.findPlaceFromQuery({
                query: selectedOption.value.value,
                fields: ['geometry'],
                locationBias: countyBounds,
            }, (results, status) => {
                const location = results[0];

                const bounds = new google.maps.LatLngBounds();

                if (location.geometry.viewport) {
                    // Only geocodes have viewport.
                    bounds.union(location.geometry.viewport);
                } else {
                    bounds.extend(location.geometry.location);
                }

                const newMarker = new google.maps.Marker({
                    position: location.geometry.location,
                    clickable: false,
                    map
                })

                setAddressMarker(newMarker)

                map.fitBounds(bounds);
            });
        } else if (selectedOption?.value?.type === 'parcel') {
            //@ts-ignore
            const label = selectedOption.label
            const envelope = countyGeography.parcelSummaryData.features.find(feature => feature.properties.Parcel_No === label)
            const bounds = getBounds(envelope.geometry)

            // Note: There is apparently an error with the fitBounds function
            // in that the first time the bounds are set in this way,
            // the map selects the wrong center.
            // The current workaround that seems to work is simply setting
            // the bounds twice
            map.fitBounds(bounds);
            map.fitBounds(bounds);
        }
    }, [selectedOption]);

    const setupAutocomplete = () => {
        if (!autocomplete) {
            const element = document.getElementById('map-search') as HTMLInputElement
            const service = new google.maps.places.Autocomplete(
                element,
                {
                    bounds: countyBounds,
                    strictBounds: true
                }
            );
            setAutocomplete(service);
        }
    }

    const setupSearchObserver = () => {
        if (autocomplete) {
            const observer = new MutationObserver((mutationRecords) => {
                const items = mutationRecords
                    .filter(record => record.addedNodes.length > 0)
                    .map(record => {
                        //@ts-ignore
                        const element = record.addedNodes[0];
                        return {
                            //@ts-ignore
                            label: element.children[1].innerText,
                            //@ts-ignore
                            secondaryLabel: element.children[2].innerText.replace(', USA', ''),
                            //@ts-ignore
                            value: element.children[1].innerText + ' ' + element.children[2].innerText
                        }
                    });
                setAddressData(items);
            });
            setTimeout(() => {
                const element = document.getElementsByClassName('pac-container')[0];
                if (element) {
                    observer.observe(element, { childList: true });
                }
            }, 500)
        }
    }

    const onInputChange = (event) => {
        setSelectedOption(null);
        setSearchTerm(event.target.value);
    }

    const optionClick = (option) => {
        if (addressMarker) {
            addressMarker.setMap(null)
            setAddressMarker(null)
        }

        setSelectedOption(option);
        //@ts-ignore
        document.activeElement.blur();
        
        if (map.onSearchSelect) {
            map.onSearchSelect(option)
        }
    }

    const displayPreview = async () => {
        if (dataType !== DataType.parcels.key) {
            return
        }

        setPreviewOpen(true)
        const response = await getParcelDownloadPreview({
             county, 
             parcelIds: Object.keys(selectedFeatures) 
        }) as unknown as { data: Blob }
        setPreviewImage(response.data)
    }

    const getDownloadItem = (fileType) => {
        const product = products?.[dataSubType]

        return new DownloadItem(
            dataType,
            DataType[dataType][dataSubType].key,
            county,
            fileType,
            Object.keys(selectedFeatures),
            product
        )
    }

    const isInCart = (fileType) => {
        return Object.values(shoppingCartItems).some(shoppingCartItem => 
            DownloadItem.create(shoppingCartItem).matches(getDownloadItem(fileType))
        );
    }

    const addToCart = (fileType) => {
        setPreviewOpen(false)
        dispatch(addItem(getDownloadItem(fileType)))
    }

    const addToCartOptions = DataType[dataType][dataSubType].fileTypes
        .reduce((obj, fileType) => {
            obj[fileType] = <PriceDropdownItem 
                key={fileType}
                label={FileType[fileType].label}
                itemTemplate={getDownloadItem(fileType)}
                strikeThrough={isInCart(fileType)}
            />

            return obj
        }, {})

    let fileTypeSelectionLabel = selectedFileType
        ? <span>
            {`${FileType[selectedFileType].label} ($${(getDownloadItem(selectedFileType).getTotalPrice() / 100).toFixed(2)})`}
        </span>
        : 'Select File Type'

    if (isInCart(selectedFileType)) {
        fileTypeSelectionLabel = <s>{fileTypeSelectionLabel}</s>
    }

    return (
        <div className={classes.container}>
            <div className={classes.searchContainer}>
                <FormControl 
                    variant="filled" 
                    size="small"
                    className={classes.searchControl}
                    color="info"
                    sx={{ width: '100%' }}
                >
                    <InputLabel
                        htmlFor="map-search"
                        className={classes.searchLabel}
                        shrink={displayDropdown || !isEmpty(searchTerm)}
                        sx={{ fontSize: '0.8125rem' }}
                    >
                        {'parcelPointsData' in countyGeography && 'Parcel# or ' }
                        {'tiles' in countyGeography && 'Tile# or '}
                        Address
                    </InputLabel>
                    <FilledInput
                        id="map-search"
                        placeholder=""
                        className={classes.search}
                        endAdornment={
                            <InputAdornment position="end">
                                <Search/>
                            </InputAdornment>
                        }
                        onChange={onInputChange}
                        onFocus={(event) => {
                            const end = event.target.value.length;
                            event.target.setSelectionRange(end, end);
                            event.target.scrollLeft = event.target.scrollWidth;
                            setInitialFocus(true);
                            setDisplayDropdown(true);
                        }}
                        onClick={(event) => {
                            const target = event.target as HTMLInputElement
                            if (initialFocus) {
                                const end = target.value.length;
                                target.setSelectionRange(end, end);
                                target.scrollLeft = target.scrollWidth;
                                setInitialFocus(false);
                            }
                        }}
                        onBlur={(event) => { setDisplayDropdown(false) }}
                        onKeyUp={(event) => { 
                            if (event.key === 'Enter' && optionGroups.length > 0 && optionGroups[0]?.options.length > 0) {
                                optionClick(optionGroups[0].options[0])
                            }
                        }}
                        value={selectedOption?.label || searchTerm}
                        sx={{
                            width: '100%',
                            marginBottom: '15px',
                            fontSize: '0.8125rem',
                            backgroundColor: `${grayColor[16]} !important`
                        }}
                    />
                </FormControl>
                { displayDropdown && (
                    <Dropdown 
                        groups={optionGroups}
                        optionClick={optionClick}
                    />
                )}
            </div>
            <SelectionLabel 
                dataType={dataType}
                showClear={Object.keys(selectedFeatures).length > 0} 
                clear={() => dispatch(setSelectedFeatures({}))}
            />
            <div className={classes.selectionArea}>
            { isEmpty(selectedFeatures)
                ? (<GetStarted dataType={dataType} hasParcels={'parcelPointsData' in countyGeography}/>)
                : Object.keys(selectedFeatures).map(key => (
                    <SelectionItem key={key} label={key} remove={() => dispatch(unselectFeature(key))}/>
                ))
            }
            </div>
            {
                DataType[dataType].selectionLimit &&
                (Object.keys(selectedFeatures).length >= DataType[dataType].selectionLimit ? (
                    <Alert
                        severity='warning'
                        variant='filled'
                    >
                        <span><strong>
                            Maximum ({DataType[dataType].selectionLimit})
                        </strong></span>
                    </Alert>
                ) : (
                    <Alert
                        severity='info'
                        variant='filled'
                    >
                        <span><strong>
                            Select up to {DataType[dataType].selectionLimit - Object.keys(selectedFeatures).length} more
                        </strong></span>
                    </Alert>
                ))
            }
            {
                Object.keys(selectedFeatures).length > 0 && (
                    <div className={classes.actionButtons}>
                        {
                            dataType === DataType.contourLines.key && (
                                <CustomDropdown
                                    buttonText={<span>{DataType[dataType][dataSubType].label}</span>}
                                    buttonProps={{
                                        color: "info",
                                        style: { width: '100%' }
                                    }}
                                    hoverColor="info"
                                    dropdownList={{
                                        [DataType.contourLines.interval1Ft.key]: DataType.contourLines.interval1Ft.label,
                                        [DataType.contourLines.interval2Ft.key]: DataType.contourLines.interval2Ft.label,
                                        [DataType.contourLines.interval10Ft.key]: DataType.contourLines.interval10Ft.label,
                                    }}
                                    onClick={(key) => setDataSubType(key)}
                                />
                            )
                        }
                        {
                            dataType === DataType.parcels.key && (
                                <Button color="info" onClick={displayPreview}>
                                    <Visibility className={classes.icons} />
                                    Preview Data
                                </Button>
                            )
                        }
                        {
                            Object.keys(addToCartOptions).length > 0 && (
                                <CustomDropdown
                                    buttonText={fileTypeSelectionLabel}
                                    buttonProps={{
                                        color: "white",
                                        style: { width: '100%' },
                                        className: classes.actionButton
                                    }}
                                    dropPlacement={'top'}
                                    hoverColor="info"
                                    dropdownList={addToCartOptions}
                                    onClick={(value) => setSelectedFileType(value)}
                                />
                            )
                        }
                        <Tooltip
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                            title={
                                !selectedFileType
                                    ? <span>Please select a data type</span>
                                    : (isInCart(selectedFileType) ? <span>This item is already in your cart</span> : '')
                            }
                        >
                            <span>
                            <Button
                                color="success"
                                style={{ width: '100%' }}
                                disabled={!selectedFileType || isInCart(selectedFileType)}
                                onClick={() => addToCart(selectedFileType)}
                            >
                                <>
                                    <ShoppingCart className={classes.icons} />
                                    <span>Add To Cart</span>
                                </>
                            </Button>
                            </span>
                        </Tooltip>
                    </div>
                )
            }
            {
                dataType === DataType.parcels.key && (
                    <PreviewModal
                        isOpen={previewOpen}
                        close={() => { setPreviewOpen(false) }}
                    >
                        {
                            previewImage === null
                                ? (
                                    <>
                                        <ClipLoader
                                            color={infoColor[0]}
                                            loading={true}
                                            size={30}
                                        />
                                        <p style={{ margin: '15px' }}>Generating image preview...</p>
                                    </>
                                )
                                : (
                                    <img 
                                        alt="parcel_preview.png"
                                        className={previewModalClasses.previewImage}
                                        src={previewImage}
                                    />
                                )
                        }
                    </PreviewModal>
                )
            }
        </div>
    );
}