import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button";
import { ClipLoader } from 'react-spinners';
import { infoColor } from 'assets/jss/material-kit-pro-react';

import modalStyle from "assets/jss/material-kit-pro-react/modalStyle";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CONFIG } from 'constants/config';
import { useCreatePaymentIntentMutation } from "redux/dataApi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "constants/routes";
import { useNavigate } from 'react-router-dom';

//@ts-ignore
const useStyles = makeStyles(modalStyle);

const stripePromise = loadStripe(CONFIG.STRIPE_PUBLISHABLE_KEY)

export const CheckoutForm = (props) => {
    const { token } = props

    const classes = useStyles()
    const dispatch = useDispatch()
    const stripe = useStripe()
    const elements = useElements()

    const [processing, setProcessing] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)

    const submitPayment = async (event) => {
        event.preventDefault()

        setProcessing(true)
        setErrorMessage(null)

        if (!stripe || !elements) {
            return;
        }
      
        const {error} = await stripe.confirmPayment({
            elements,
            confirmParams: {
              return_url: window.location.origin + ROUTES.PAYMENT_SUCCESSFUL.path(token)
            }
        });

        if (error) {
            setProcessing(false)
            setErrorMessage(error.message);
            console.log(error);
        }
    }

    if (stripe && elements) {
        return (
            <form>
                <PaymentElement/>
                {errorMessage && <p className={classes.errorMessage}>{errorMessage}</p>}
                <Button
                    color="success"
                    className={classes.submitPaymentButton}
                    fullWidth
                    disabled={processing || !stripe || !elements}
                    onClick={submitPayment}
                >                    
                    <ClipLoader
                        color={'white'}
                        loading={processing}
                        size={10}
                    />
                    <span className={classes.buttonLabel}>Submit Payment</span>
                </Button>
            </form>
        )
    }
}

export const CheckoutModal = (props) => {
    const {
        isOpen,
        close,
        licenseAccepted,
        email,
        updatesOptIn
    } = props

    const {
        items: shoppingCartItems = [],
        promotionCode
      } = useSelector((state: any) => state.shoppingCart)
    const [clientSecret, setClientSecret] = useState(null)
    const [token, setToken] = useState(null)

    const classes = useStyles()
    const navigate = useNavigate()
    const [createPaymentIntent] = useCreatePaymentIntentMutation()

    useEffect(() => {
        if (licenseAccepted && email) {
            setupPayment()
        }
    }, [licenseAccepted, email])

    const setupPayment = async () => {
        const downloadItems = Object.values(shoppingCartItems)
        const promoCode = promotionCode?.code
        const response = await createPaymentIntent({ licenseAccepted, downloadItems, promoCode, email, updatesOptIn }) as any
        const data = response.data as any

        const needsPayment = data.needsPayment as boolean

        const token = data.token as string
        setToken(token)

        if (needsPayment) {
            const clientSecret = data.clientSecret as string
            setClientSecret(clientSecret)
        } else {
            navigate(ROUTES.PAYMENT_SUCCESSFUL.path(token))
        }
    }

    return (
        <Dialog
            classes={{
                root: classes.modalRoot,
                paper: classes.modal
            }}
            open={isOpen}
            onClose={close}
        >
            <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
            >
            <Button
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                onClick={close}
            >
                <Close className={classes.modalClose} />
            </Button>
                <h4 className={classes.modalTitle}>Checkout</h4>
            </DialogTitle>
            <DialogContent
                className={`${classes.modalBody} ${classes.modalBodyCenter}`}
            >
                {
                    clientSecret === null ? (
                        <ClipLoader
                            color={infoColor[0]}
                            loading={true}
                            size={30}
                        />
                    ) : (
                        <Elements 
                            stripe={stripePromise}
                            options={{clientSecret}}
                        >
                            <CheckoutForm token={token}/>
                        </Elements>
                    )
                }
            </DialogContent>
        </Dialog>
    )
}