/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// core components
import Header from "components/Header/Header";
import HeaderLinks from "components/Header/HeaderLinks";
import Parallax from "components/Parallax/Parallax";
import Footer from "components/Footer/Footer";
import Button from "components/CustomButtons/Button";
// sections for this page
import SectionDescription from "views/PresentationPage/Sections/SectionDescription";

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle";
import SectionDiscounts from "./Sections/SectionDiscounts";

const useStyles = makeStyles(presentationStyle);

export default function PresentationPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        brand="GIS1 Clearinghouse"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="info"
      />
      <Parallax small={false}/>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <SectionDescription />
        <SectionDiscounts />
      </div>
      <Footer/>
    </div>
  );
}
