import {Callback, Middleware} from "./Types";
import jwt from 'jsonwebtoken';
import ErrorPage from "views/ErrorPage/ErrorPage";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const JWT = ({ next }) => {
    const { token } = useParams()
    const key = Buffer.from(process.env.REACT_APP_JWT_RSA_PUBLIC_KEY, 'base64').toString()

    const [element, setElement] = useState(null)


    useEffect(() => {
        evaluateToken()
    }, [])

    const evaluateToken = async () => {
        try {
            jwt.verify(token, key)
            setElement(await next())
        } catch (error) {
            setElement(<ErrorPage/>)
        }
    }

    return element
}

const JwtMiddleware: Middleware = async (next: Callback) => {
    return <JWT next={next}/>
}

export { JwtMiddleware as JWT }