import { MarkerWithLabel } from '@googlemaps/markerwithlabel';
import { makeStyles } from "@material-ui/core/styles";
import { mdiRadioboxMarked, mdiRecord } from '@mdi/js';
import { groupBy, isEmpty, keyBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectFeature, setHover, setSelectedFeatures, setSelectableFeatures, unselectFeature } from '../../redux/map';

import mapPanelStyle from "assets/jss/material-kit-pro-react/components/mapPanelStyle";
import SnackbarContent from "components/Snackbar/SnackbarContent";
import { useGetParcelPointsDownloadQuery, useGetS3FileQuery } from 'redux/dataApi';
import { featureShouldDisplay, featureVisible, sleep } from 'utils/Helpers';

//@ts-ignore
const useStyles = makeStyles(mapPanelStyle);

export const ParcelPointsMapSection = (props) => {
    const {
        map,
        mapRef,
        countyGeography
    } = props;

    const defaultIcon = {
        path: mdiRecord,
        scale: 1,
        fillColor: "yellow",
        fillOpacity: 0.7,
        strokeWeight: 0,
        anchor: new google.maps.Point(5, 5),
    };
    
    const defaultIconHover = {
        path: mdiRecord,
        scale: 1,
        fillColor: "#FFFF99",
        fillOpacity: 1,
        strokeWeight: 0,
        anchor: new google.maps.Point(5, 5),
    };
    
    const selectedIcon = {
        path: mdiRadioboxMarked,
        scale: 1,
        fillColor: "yellow",
        fillOpacity: 0.7,
        strokeWeight: 0,
        anchor: new google.maps.Point(5, 5),
    };
    
    const selectedIconHover = {
        path: mdiRadioboxMarked,
        scale: 1,
        fillColor: "#FFFF99",
        fillOpacity: 1,
        strokeWeight: 0,
        anchor: new google.maps.Point(5, 5),
    };
    
    const dispatch = useDispatch();
    const classes = useStyles();

    const DEBOUNCE_INTERVAL = 200;

    const {
        hover,
        selectedFeatures: selectedParcels
    } = useSelector((state: any) => state.map);
    const [parcelPointGroups, setParcelPointGroups] = useState(null)
    const [mapBounds, setMapBounds] = useState(null)
    const [markerGroups, setMarkerGroups] = useState<{[k: string]: google.maps.Marker[]}>({})
    const [debounceTimeoutId, setDebounceTimeoutId] = useState(null);
    const [markerEvent, setMarkerEvent] = useState(null)

    useEffect(() => {
        dispatch(setSelectedFeatures({}))
    }, []);

    useEffect(() => {
        if (!isEmpty(map) && !isEmpty(countyGeography.parcelPointsData) && isEmpty(parcelPointGroups)) {
            const newParcelPointGroups = groupBy(countyGeography.parcelPointsData.features, feature => feature.properties.Parcel_No)
            setParcelPointGroups(newParcelPointGroups)
            dispatch(setSelectableFeatures(newParcelPointGroups))
        }
    }, [map, countyGeography, parcelPointGroups])

    useEffect(() => {
        if (map) {
            map.addListener('bounds_changed', () => { setMapBounds(map.getBounds()); });

            map.onSearchSelect = async (selectedOption) => {
                if (selectedOption.value.type === 'parcel') {
                    await sleep(1000)
                    await blinkOption(selectedOption.label);
                    await sleep(100)
                    dispatch(selectFeature({id: selectedOption.label, value: markerGroups[selectedOption.label]}))
                }
            }
        }
    }, [map])

    useEffect(() => {
        Object.keys(markerGroups).forEach(key => {
            let icon;
            if (hover === key) {
                icon = isSelected(key) ? selectedIconHover : defaultIconHover
            } else {
                icon = isSelected(key) ? selectedIcon : defaultIcon
            }
            markerGroups[key]?.forEach(
                marker => marker.setIcon(icon)
            )
        })
    }, [selectedParcels, hover])

    useEffect(() => {
        if (mapBounds) {
            if (debounceTimeoutId) {
                clearTimeout(debounceTimeoutId);
                setDebounceTimeoutId(null);
            }

            const timeoutId = setTimeout(() => {
                handleMapBoundsChange();
                setDebounceTimeoutId(null);
            }, DEBOUNCE_INTERVAL);
            setDebounceTimeoutId(timeoutId);
        }
    }, [mapBounds])

    useEffect(() => {
        if (markerEvent) {
            switch(markerEvent.type) {
                case 'mouseover':
                    dispatch(setHover(markerEvent.parcelNo));
                    break;
                case 'mouseout':
                    dispatch(setHover(null));
                    break;
                case 'click':
                    if (isSelected(markerEvent.parcelNo)) {
                        dispatch(unselectFeature(markerEvent.parcelNo))
                    } else if (Object.keys(selectedParcels).length < 5) {
                        dispatch(selectFeature({id: markerEvent.parcelNo, value: markerGroups[markerEvent.parcelNo]}))
                    }
                    break;
            }

            setMarkerEvent(null)
        }
    }, [markerEvent])

    const blinkOption = async (parcelNo, speed = 150, times = 4) => {
        for (let i = 0; i < times; i++) {
            dispatch(setHover(parcelNo))
            await sleep(speed)
            dispatch(setHover(null))
            await sleep(speed)
        }
    }

    const handleMapBoundsChange = () => {
        const mapBounds = map.getBounds()

        const currentMarkerGroups = markerGroups
        const newMarkerGroups = {}

        if (parcelPointGroups && map.getZoom() >= 12) {
            const visibleEnvelopes = keyBy(
                countyGeography.parcelSummaryData.features
                    .filter(feature => featureVisible(feature, mapBounds))
                    .filter(feature => featureShouldDisplay(feature, mapBounds)),
                feature => feature.properties.Parcel_No
            )

            Object.keys(visibleEnvelopes).forEach(parcelNo => {
                if (parcelNo in currentMarkerGroups) {
                    newMarkerGroups[parcelNo] = currentMarkerGroups[parcelNo]
                } else {
                    newMarkerGroups[parcelNo] = parcelPointGroups[parcelNo].map(parcelPoint => {
                        const marker = new MarkerWithLabel({
                            position: {
                                lat: parcelPoint.geometry.coordinates[1],
                                lng: parcelPoint.geometry.coordinates[0]
                            },
                            clickable: true,
                            labelContent: parcelPoint.properties.Parcel_No,
                            labelAnchor: new google.maps.Point(5, 20),
                            labelClass: 'google-maps-marker-label',
                            icon: isSelected(parcelPoint.properties.Parcel_No) ? selectedIcon : defaultIcon,
                            map,
                        })
                        marker.addListener('mouseover', () => {
                            setMarkerEvent({ time: new Date(), type: 'mouseover', parcelNo: parcelPoint.properties.Parcel_No })
                        })
                        marker.addListener('mouseout', () => {
                            setMarkerEvent({ time: new Date(), type: 'mouseout', parcelNo: parcelPoint.properties.Parcel_No })
                        })
                        marker.addListener('click', () => {
                            setMarkerEvent({ time: new Date(), type: 'click', parcelNo: parcelPoint.properties.Parcel_No })
                        })
                        return marker
                    })
                }
            })
        }

        setMarkerGroups(newMarkerGroups)

        Object.keys(currentMarkerGroups).forEach(key => {
            if (!(key in newMarkerGroups)) {
                currentMarkerGroups[key].forEach(marker => marker.setMap(null))
            }
        }) 
    }

    const isSelected = (parcelNo) => {
        return parcelNo in selectedParcels
    }

    return (
        <div className={classes.mapSectionContent}>
            <div className={classes.alerts}>
                <SnackbarContent
                    message={<span><strong>
                    Note that parcel line data is <em>NOT</em> pictured in the map below, but will be available for preview before purchase.
                    </strong></span>}
                    color='warning'
                    icon='warning_amber'
                />
            </div>
            <div className={classes.mapWrapper}>
                <div className={classes.map} ref={mapRef} id="map"/>
            </div>
        </div>
    );
}