import { 
    grayColor
} from "assets/jss/material-kit-pro-react";


const mapPanelStyle = {
    container: {
        width: '100%',
        aspectRatio: 3/2,
        overflow: 'hidden',
        borderRadius: '5px',
        '&> div': {
            boxSizing: 'border-box',
            display: 'inline-block',
            height: '100%'
        }
    },
    dataSection: {
        verticalAlign: 'top',
        width: '25%',
        backgroundColor: grayColor[2],
        padding: '15px'
    },
    search: {
        width: '100%',
    },
    mapSection: {
        width: '75%'
    },
    mapSectionContent: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    mapWrapper: {
        flex: 1,
    },
    map: {
        height: '100%',
        width: '100%'
    },
    alerts: {
        width: '100%',
        '& > div': {
            margin: 0
        }
    }
};

export default mapPanelStyle;