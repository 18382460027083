import React from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { Map } from '../../components/Map/Map';

function MapPage() {
  const render = (status) => {
    switch (status) {
      case Status.LOADING:
        return <p>Loading...</p>;
      case Status.FAILURE:
        return <p>Error</p>;
      case Status.SUCCESS:
        return <Map/>;
    }
  };

  return (
    <div className="Map" style={{ height: '100vh', width: '100vw'}}>
      <Wrapper 
        apiKey='AIzaSyAR9yBNr90Fk8up4EQET0stBVychtDCeZo' 
        libraries={['places']}
        render={render}
      />
    </div>
  );
}

export default MapPage;
