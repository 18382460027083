import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button";
import Footer from "components/Footer/Footer";
import Header from "components/Header/Header";
import Parallax from "components/Parallax/Parallax";
import jwt from 'jsonwebtoken';
import { useDispatch } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { clearShoppingCart } from "redux/shoppingCart";
import { useSendOrderReceiptMutation, useGenerateOrderDownloadMutation } from "redux/dataApi";
import { ROUTES } from "../../constants";
import { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import pageStyle from "assets/jss/material-kit-pro-react/views/parcelDataStyle";
import tableStyle from "assets/jss/material-kit-pro-react/components/tableStyle";
import { ClipLoader } from "react-spinners";
import { infoColor } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles({...tableStyle, ...pageStyle});

export const PaymentSuccessPage = () => {
    const { token } = useParams();
    const decoded = jwt.decode(token)

    const dispatch = useDispatch()
    const classes = useStyles();
    const [sendOrderReceipt] = useSendOrderReceiptMutation();

    const [ready, setReady] = useState(false)

    useEffect(() => {
        dispatch(clearShoppingCart())
        processSuccess()
    }, [])

    const processSuccess = async () => {
        if (!decoded.needsPayment) {
            await sendOrderReceipt(token)
        }

        setReady(true)
    }

    return ready ? (
        <Navigate to={ROUTES.DOWNLOAD.path(token) + window.location.search}/>
    ) : (
        <div>
            <Header/>
            <Parallax/>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                <Card plain>
                    <CardBody plain>
                    <h3 className={classes.cardTitle}>{ROUTES.DOWNLOAD.title}</h3>
                    <div className={classes.textCenter}>
                        <ClipLoader
                            color={infoColor[0]}
                            loading={true}
                            size={50}
                        />
                    </div>
                    </CardBody>
                </Card>
                </div>
            </div>
            <Footer/>
        </div>
    )
}