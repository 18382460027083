import { DownloadItem } from 'models/DownloadItem';
import { apiSlice } from './api';

const dataApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        updateStatus: builder.mutation({
            query: (path) => ({
                url: '/status',
                method: 'POST',
                body: { path }
            })
        }),
        getParcelsCountyList: builder.query({
            query: () => '/parcels/counties'
        }),
        getParcelsCountyDetails: builder.query({
            query: county => `/parcels/${county}`,
        }),
        getParcelPointsDownload: builder.query({
            query: (county) => ({
                url: `/parcels/${county}/index`
            })
        }),
        getParcelDownloadPreview: builder.mutation({
            query: ({ county, parcelIds }) => ({
                url: `/parcels/${county}/preview`,
                method: 'POST',
                body: { parcelIds },
                responseHandler: 'text'
            })
        }),
        getAddressPointsCountyList: builder.query({
            query: () => '/address-points/counties'
        }),
        getCamaCountyList: builder.query({
            query: () => '/cama/counties'
        }),
        getContourLinesCountyList: builder.query({
            query: () => '/contour-lines/counties'
        }),
        getContourLinesCountyDetails: builder.query({
            query: county => `/contour-lines/${county}`,
        }),
        getRoadCenterlinesCountyList: builder.query({
            query: () => '/road-centerlines/counties'
        }),
        getProducts: builder.query({
            query: params => ({
                url: `/payment/products`,
                params
            })
        }),
        getCoupon: builder.query({
            query: code => ({
                url: `/payment/coupon`,
                params: { code }
            })
        }),
        createPaymentIntent: builder.mutation({
            query: body => ({
                url: '/payment/intent',
                method: 'POST',
                body
            })
        }),
        sendOrderReceipt: builder.mutation({
            query: token => ({
                url: '/orders/sendReceipt',
                method: 'POST',
                params: { token }
            })
        }),
        getOrder: builder.query({
            query: token => ({
                url: '/orders',
                params: { token },
            }),
            transformResponse: (response: any) => {
                const order = response;
                return {
                    ...order,
                    downloadItems: order.downloadItems.map((item: any) => new DownloadItem(
                        item.dataType,
                        item.dataSubType,
                        item.county,
                        item.fileType,
                        item.keys,
                        item.product
                    ))
                }
            }
        }),
        getS3File: builder.query({
            query: url => ({
                url
            })
        })
    })
})

export const {
    useUpdateStatusMutation,
    useGetParcelsCountyListQuery,
    useGetParcelsCountyDetailsQuery,
    useGetParcelPointsDownloadQuery,
    useGetParcelDownloadPreviewMutation,
    useGetAddressPointsCountyListQuery,
    useGetCamaCountyListQuery,
    useGetContourLinesCountyListQuery,
    useGetContourLinesCountyDetailsQuery,
    useGetRoadCenterlinesCountyListQuery,
    useGetProductsQuery,
    useGetCouponQuery,
    useCreatePaymentIntentMutation,
    useSendOrderReceiptMutation,
    useGetOrderQuery,
    useGetS3FileQuery,
} = dataApi