/*eslint-disable*/
import React, { useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import MaterialButton from "@mui/material/Button";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
// core components
import Header from "components/Header/Header";
import Parallax from "components/Parallax/Parallax";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Footer from "components/Footer/Footer";
import Table from "components/Table/Table";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

import pageStyle from "assets/jss/material-kit-pro-react/views/parcelDataStyle";
import tableStyle from "assets/jss/material-kit-pro-react/components/tableStyle";
import { ROUTES } from "constants";
import { Link, useNavigate } from "react-router-dom";
import { useGetParcelsCountyListQuery, useGetProductsQuery } from "redux/dataApi";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import ShoppingCart from "@mui/icons-material/ShoppingCart";
import { FileType } from "models/enums/FileType";
import { DataType } from "models/enums/DataType";
import { DownloadItem } from "models/DownloadItem";
import { useDispatch, useSelector } from "react-redux";
import { addItem } from '../../redux/shoppingCart';
import { PriceDropdownItem } from '../../components/CustomDropdown/PriceDropdownItem';
import { ShoppingCartDrawer } from "components/ShoppingCartDrawer/ShoppingCartDrawer";
import { isEmpty } from 'lodash';
import { ClipLoader } from "react-spinners";
import { infoColor } from "assets/jss/material-kit-pro-react";
import { Divider } from "@mui/material";
import { MetadataModal } from "components/MetadataModal/MetadataModal";

const useStyles = makeStyles({...tableStyle, ...pageStyle});

export default function ParcelDataListPage() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();

  const shoppingCartItems = useSelector((state: any) => DownloadItem.hydrate(state.shoppingCart?.items));

  const [selectedDataTypes, setSelectedDataTypes] = useState({})
  const [metadataModalProps, setMetadataModalProps] = useState({ isOpen: false })

  const {
    data: counties
  } = useGetParcelsCountyListQuery();
  const {
    data: products
  } = useGetProductsQuery({ type: DataType.parcelsCounty.key });

  const isInCart = (key) => {
    const [county, dataSubType, fileType] = key.split(':')

    const downloadItem = new DownloadItem(
      DataType.parcelsCounty.key,
      dataSubType,
      county,
      fileType,
      ['Entire County'],
      0
    )

    return Object.values(shoppingCartItems).some(shoppingCartItem => 
      DownloadItem.create(shoppingCartItem).matches(downloadItem)
    );
  }

  const getAddToCartOptions = (county) => {
    if (isEmpty(products)) {
      return {}
    }

    const options = {
      [`${county}:selectIndividualParcels`]: 'Select Individual Parcels (without WinGAP)'
    }

    const dataSubTypes = ['withoutWingap', 'withWingap']

    dataSubTypes.forEach(dataSubType => {
      DataType.parcelsCounty[dataSubType].fileTypes.forEach(fileTypeKey => {
        let key = `${county}:${dataSubType}:${fileTypeKey}`
        let label = DataType.parcelsCounty[dataSubType].label + ' - ' +
          FileType[fileTypeKey].label;

        if (isInCart(key)) {
          label = <s>{label}</s>
        }

        options[key] = label
      })
    })

    return options
  }

  const getTableRows = () => {
    return counties
      .filter(county => county in products)
      .map((county, i) => {
        const { dataSubType = null, fileType = null } = selectedDataTypes[county] ?? {}

        const selectedKey = `${county}:${dataSubType}:${fileType}`

        let label;
        switch (dataSubType) {
          case null: label = 'Choose Data Type'; break;
          case 'selectIndividualParcels': label = 'Select Individual Parcels (without WinGAP)'; break;
          default: label = DataType.parcelsCounty[dataSubType].label + ' - ' + FileType[fileType].label; break;
        }

        const product = products[county][dataSubType]

        if (isInCart(selectedKey)) {
          label = <s>{label}</s>
        }

        return [
          <div className={classes.labelContainer}>
            <span>{county.replaceAll('_', ' ')}</span>
            <MaterialButton
              color="info"
              variant="text"
              size="small"
              onClick={() => setMetadataModalProps({
                isOpen: true,
                close: () => setMetadataModalProps({ isOpen: false }),
                title: county.replaceAll('_', ' ') + ' Parcels',
                metadata: [
                  { name: 'Author', value: 'GIS1, LLC' },
                  { name: 'Updated At', value: '2021-09-01' },
                  { name: 'Note', 'value': <em>This metadata is for testing purposes only</em> }
                ]
              })}
            >
              Metadata
            </MaterialButton>
          </div>,
          <div className={classes.actionButtonContainer}>
            <CustomDropdown
                buttonText={label}
                buttonProps={{
                    color: "white",
                    className: classes.actionButton
                }}
                dropPlacement={'top'}
                className={classes.actionButton}
                hoverColor="info"
                dropdownList={getAddToCartOptions(county)}
                onClick={(value) => {
                  const [county, dataSubType = null, fileType = null] = value.split(':')
                  selectDataType(county, dataSubType, fileType)
                }}
            />
            <Tooltip
              placement="top"
              classes={{ tooltip: classes.tooltip }}
              title={
                !selectedDataTypes[county] 
                    ? <span>Please select a data type</span>
                    : (isInCart(selectedKey) ? <span>This item is already in your cart</span> : '')
              }
            >
              <span>
              <Button
                color={selectedDataTypes[county]?.dataSubType === 'selectIndividualParcels' ? 'info' : 'success'}
                disabled={!selectedDataTypes[county] || isInCart(selectedKey)}
                onClick={() => { 
                  selectedDataTypes[county]?.dataSubType === 'selectIndividualParcels' 
                    ? navigate(ROUTES.PARCEL_DATA.MAP.path(county))
                    : addToCart(selectedKey);
                }}
              > 
                {
                  selectedDataTypes[county]?.dataSubType === 'selectIndividualParcels' 
                    ? <>
                      <span>Go To Map</span>
                      <KeyboardArrowRight/>
                    </>
                    : <>
                      <ShoppingCart className={classes.icons} />
                      <span>Add To Cart{selectedDataTypes[county] && ` ($${(product.default_price.unit_amount / 100).toFixed(2)})`}</span>
                    </>
                }
              </Button>
              </span>
            </Tooltip>
          </div>
        ]
      })
  }

  const selectDataType = (county, dataSubType, fileType) => {
    setSelectedDataTypes({
      ...selectedDataTypes,
      [county]: { dataSubType, fileType }
    })
  }

  const addToCart = (key) => {
    const [county, dataSubType, fileType] = key.split(':')

    const product = products[county][dataSubType]

    dispatch(addItem(new DownloadItem(
        DataType.parcelsCounty.key,
        dataSubType,
        county,
        fileType,
        ['Entire County'],
        product
    )))

    const newSelections = selectedDataTypes;
    delete newSelections[county];
    setSelectedDataTypes(newSelections);
  }

  return (
    <div>
      <Header/>
      <Parallax/>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <Card plain>
            <CardBody plain>
              <h3 className={classes.cardTitle}>{ROUTES.PARCEL_DATA.LIST.title}</h3>
              { (counties && products) ? (
                  <Table
                    tableHead={[
                      'County',
                      '',
                      ''
                    ]}
                    tableData={getTableRows()}
                    tableShopping
                    customHeadCellClasses={[
                      classes.textRight,
                      classes.fit
                    ]}
                    customHeadClassesForCells={[1, 2]}
                    customCellClasses={[
                      classes.textRight,
                      classes.textRight
                    ]}
                    customClassesForCells={[1, 2]}
                  />
                ) : (
                  <div className={classes.loadingContainer}>
                    <ClipLoader
                      color={infoColor[0]}
                      loading={true}
                      size={50}
                    />
                  </div>
                )
              }
            </CardBody>
          </Card>
        </div>
      </div>
      <MetadataModal
        {...metadataModalProps}
      />
      <ShoppingCartDrawer/>
      <Footer/>
    </div>
  );
}
