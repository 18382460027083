/*eslint-disable*/
import React, { useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import MaterialButton from "@mui/material/Button";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
// core components
import Header from "components/Header/Header";
import Parallax from "components/Parallax/Parallax";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Footer from "components/Footer/Footer";
import Table from "components/Table/Table";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { MetadataModal } from "components/MetadataModal/MetadataModal";

import pageStyle from "assets/jss/material-kit-pro-react/views/parcelDataStyle";
import { ROUTES } from "../../constants";
import { useParams } from "react-router-dom";
import { MapPanel } from "components/MapPanel/MapPanel";
import { useGetParcelsCountyDetailsQuery, useGetS3FileQuery } from "redux/dataApi";
import SnackbarContent from "components/Snackbar/SnackbarContent";
import { ShoppingCartDrawer } from "components/ShoppingCartDrawer/ShoppingCartDrawer";
import { ClipLoader } from "react-spinners";
import { infoColor } from "assets/jss/material-kit-pro-react";
import { DataType } from "models/enums/DataType";
import { isEmpty } from 'lodash';

const useStyles = makeStyles({...pageStyle});

export default function ParcelDataMapPage(props) {
  const { county } = useParams();
  const countyName = county.replaceAll('_', ' ');
  
  const [metadataModalProps, setMetadataModalProps] = useState({ isOpen: false })

  const {
    data: countySummary,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetParcelsCountyDetailsQuery(county);

  const { data: parcelPointsData } = useGetS3FileQuery(countySummary?.parcelPointsUrl, { skip: isEmpty(countySummary?.parcelPointsUrl) })
  const { data: parcelSummaryData } = useGetS3FileQuery(countySummary?.parcelSummaryUrl, { skip: isEmpty(countySummary?.parcelSummaryUrl) })

  const countyGeography = { countyBoundaries: countySummary?.countyBoundaries, parcelPointsData, parcelSummaryData }

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();

  return (
    <div>
      <Header/>
      <Parallax/>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <Card plain>
            <CardBody plain>
              <h3 className={classes.cardTitle}>{ROUTES.PARCEL_DATA.MAP.title(countyName)}</h3>
              <div style={{ display: 'none', marginBottom: '10px'}}>
                <MaterialButton
                  color="info"
                  variant="text"
                  size="small"
                  onClick={() => setMetadataModalProps({
                    isOpen: true,
                    close: () => setMetadataModalProps({ isOpen: false }),
                    title: county.replaceAll('_', ' ') + ' Parcels',
                    metadata: [
                      { name: 'Author', value: 'GIS1, LLC' },
                      { name: 'Updated At', value: '2021-09-01' }
                    ]
                  })}
                >
                  Metadata
                </MaterialButton>
              </div>
              <SnackbarContent
                message={<span><strong>
                  Select up to 5 parcels on the map below and receive a download of the corresponding parcel line data, plus any data within 2000 feet of your parcel boundaries.
                </strong></span>}
                color='info'
                icon='info_outline'
              />
              { (countyGeography.countyBoundaries && countyGeography.parcelPointsData && countyGeography.parcelSummaryData) 
                  ? (
                    <MapPanel 
                      dataType={DataType.parcels.key}
                      county={county} 
                      countyGeography={countyGeography}
                    />
                  ) : (
                    <div className={classes.loadingContainer}>
                      <ClipLoader
                        color={infoColor[0]}
                        loading={true}
                        size={50}
                      />
                    </div>
                  )
              }
            </CardBody>
          </Card>
        </div>
      </div>
      <MetadataModal
        {...metadataModalProps}
      />
      <ShoppingCartDrawer/>
      <Footer/>
    </div>
  );
}
