import { 
  grayColor,
  infoColor,
 } from "assets/jss/material-kit-pro-react";
import { Styles } from "@material-ui/core/styles/withStyles";
import { Theme } from "@material-ui/core";
import tooltips from "assets/jss/material-kit-pro-react/tooltipsStyle";


const cardBodyStyle: Styles<Theme, {}, string> = {
  ...tooltips,
  container: {
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
    height: '100%'
  },
  searchContainer: {
    position: 'relative',
  },
  dropdown: {
    width: '100%',
    maxHeight: '300px',
    overflow: 'scroll',
    borderRadius: '3px',
    position: 'absolute',
    transform: 'translateY(-10px)',
    top: '100%',
    backgroundColor: 'white',
    borderStyle: 'solid',
    borderColor: grayColor[17],
    borderWidth: '1px',
    padding: '5px 0',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  dropdownGroup: {
    width: '100%',
    height: '1.5rem',
    position: 'relative',
    backgroundColor: infoColor[5],
  },
  dropdownGroupLabel: {
    display: '-webkit-box',
    position: 'absolute',
    boxSizing: 'border-box',
    top: '50%',
    transform: 'translateY(-50%)',
    height: '1.25rem',
    lineHeight: '1.25rem',
    fontSize: '.75rem',
    margin: 0,
    padding: '0 7px',
    WebkitBoxOrient: 'vertical',
    lineClamp: 2,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  dropdownOption: {
    width: '100%',
    height: '2.5rem',
    position: 'relative',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: infoColor[4]
    }
  },
  dropdownPlaceholder: {
    cursor: 'default',
    '&:hover': {
      backgroundColor: 'inherit'
    }
  },
  dropdownLabelContainer: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  dropdownOptionLabel: {
    display: '-webkit-box',
    maxHeight: '1.25rem',
    width: '100%',
    boxSizing: 'border-box',
    margin: 0,
    padding: '0 7px',
    fontSize: '0.85rem',
    lineHeight: '0.95rem',
    WebkitBoxOrient: 'vertical',
    lineClamp: 1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  dropdownOptionSecondaryLabel: {
    display: '-webkit-box',
    maxHeight: '.75rem',
    width: '100%',
    boxSizing: 'border-box',
    margin: 0,
    padding: '0 7px',
    fontSize: '0.7rem',
    lineHeight: '0.8rem',
    WebkitBoxOrient: 'vertical',
    lineClamp: 1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  selectionArea: {
    overflow: 'scroll',
    flex: '1 1 auto',
    marginBottom: '16'
  },
  selectionLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '4px 0',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: grayColor[5],
    color: grayColor[5],
    margin: '20px 0 16px 0',
    '& p': {
      margin: 0
    }
  },
  selectionItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 16px',
    marginBottom: '16px',
    backgroundColor: 'white',
    borderRadius: '4px',
    overflow: 'hidden',
    '& p': {
      margin: 0,
    }
  },
  selectionItemRemoveButton: {
    cursor: 'pointer',
    border: 'none',
    backgroundColor: 'transparent',
    padding: 0,
    '& svg': {
      display: 'block'
    }
  },
  actionButtons: {
    width: '100%',
    marginTop: '20px',
    '& > button': {
      width: '100%'
    }
  }
};

export default cardBodyStyle;
