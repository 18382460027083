import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Footer from "components/Footer/Footer";
import Header from "components/Header/Header";
import Parallax from "components/Parallax/Parallax";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import pageStyle from "assets/jss/material-kit-pro-react/views/parcelDataStyle";
import tableStyle from "assets/jss/material-kit-pro-react/components/tableStyle";

const useStyles = makeStyles({...tableStyle, ...pageStyle});

export default function ErrorPage() {
    const classes = useStyles();

    return (
        <div>
            <Header/>
            <Parallax/>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                <Card plain>
                    <CardBody plain>
                    <h1 className={classes.cardTitle}>Oops... An error occurred</h1>
                    <h3>If the issue persists, please contact us at <a href="mailto:info@gis1.net?subject=Data Clearinghouse Error">info@gis1.net</a></h3>
                    <h3>Sometimes, clearing your browser's cache and cookies may resolve the issue.</h3>
                    </CardBody>
                </Card>
                </div>
            </div>
            <Footer/>
        </div>
    )
}