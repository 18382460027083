/*eslint-disable*/
import React, { useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty } from 'lodash';
// @material-ui/icons
// core components
import { HelpOutline } from "@material-ui/icons";
import { Tooltip } from "@mui/material";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button";
import Footer from "components/Footer/Footer";
import Header from "components/Header/Header";
import Parallax from "components/Parallax/Parallax";

import tableStyle from "assets/jss/material-kit-pro-react/components/tableStyle";
import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle";

import { ShoppingItems } from "components/ShoppingItems/ShoppingItems";
import { useDispatch, useSelector } from "react-redux";
import { CheckoutModal } from "./CheckoutModal";
import { CouponModal } from "./CouponModal";
import { LicenseModal } from "./LicenseModal";
import { EmailModal } from "./EmailModal";
import { DownloadItem } from "models/DownloadItem";

const useStyles = makeStyles(shoppingCartStyle);
const useTableStyles = makeStyles(tableStyle);

export default function ShoppingCartPage() {
  const shoppingCart = useSelector((state: any) => state.shoppingCart)
  const shoppingCartItems = DownloadItem.hydrate(shoppingCart.items)
  const promotionCode = shoppingCart.promotionCode
  

  const classes = useStyles();
  const tableClasses = useTableStyles();
  const dispatch = useDispatch();

  const [licenseAccepted, setLicenseAccepted] = useState(false)
  const [email, setEmail] = useState(null)
  const [updatesOptIn, setUpdatesOptIn] = useState(false)
  const [showCouponModal, setShowCouponModal] = useState(false)
  const [showCheckoutModal, setShowCheckoutModal] = useState(false)

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const closeCheckoutModal = () => {
    setShowCheckoutModal(false);
    setLicenseAccepted(false);
    setEmail(null);
    setUpdatesOptIn(false);
  }

  return (
    <div>
      <Header/>
      <Parallax/>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <Card plain>
            <CardBody plain>
              <h3 className={classes.cardTitle}>Your Shopping Cart</h3>
              <ShoppingItems 
                interactive 
                shoppingCartItems={shoppingCartItems}
                promotionCode={promotionCode}
                actionRows={
                  Object.values(shoppingCartItems).length > 0 && (
                    <tr>
                      <td colSpan={6} className={tableClasses.right}>
                        { isEmpty(promotionCode) && (
                          <Tooltip
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                            title={<div>
                              <span className={classes.tooltipText}>
                                Students, and government agencies or contractors, and customers purchasing data in bulk may be eligible to download data at a free or discounted rate.
                              </span>
                              <br/>
                              <span className={classes.tooltipText}>
                                Contact us at <a href="mailto:info@gis1.net?subject=Data Clearinghouse Discount">info@gis1.net</a> with information about your project to find out if you're eligible!
                              </span>
                            </div>}
                          >
                            <Button 
                              color="info"
                              className={classes.checkoutButton}
                              onClick={() => setShowCouponModal(true)}
                            >
                                <HelpOutline/>
                                Add a Promo Code
                            </Button>
                          </Tooltip>
                        )}
                        <Button
                          color="success"
                          className={classes.checkoutButton}
                          onClick={() => setShowCheckoutModal(true)}
                        >
                          Checkout
                        </Button>
                      </td>
                    </tr>
                  )
                }
              />
            </CardBody>
          </Card>
        </div>
      </div>
      <CouponModal 
        isOpen={showCouponModal} 
        close={() => setShowCouponModal(false)}
      />
      <LicenseModal
        isOpen={showCheckoutModal && !licenseAccepted && !email} 
        accept={() => {
          setLicenseAccepted(true)
        }}
        close={() => closeCheckoutModal()}
      />
      <EmailModal
        isOpen={showCheckoutModal && licenseAccepted && !email}
        submit={(email, updatesOptIn) => {
          setEmail(email)
          setUpdatesOptIn(updatesOptIn)
        }}
        close={() => closeCheckoutModal()}
      />
      <CheckoutModal 
        isOpen={showCheckoutModal && licenseAccepted && email}
        email={email}
        updatesOptIn={updatesOptIn}
        licenseAccepted={licenseAccepted}
        close={() => closeCheckoutModal()}
      />
      <Footer/>
    </div>
  );
}
