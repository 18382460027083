import { useEffect, useRef, useState } from 'react';

export function Map() {
    const ref = useRef();
    const [clickEvent, setClickEvent] = useState({ id: null, time: null})
    const [map, setMap] = useState<google.maps.Map>(null)
    const [tiles, setTiles] = useState({})

    useEffect(() => {
        const map = new window.google.maps.Map(ref.current, {
            center: { lat: 33.8588611, lng: -84.2484869 },
            zoom: 8,
            streetViewControl: false,
            gestureHandling: 'cooperative',
            fullscreenControl: false,
            // minZoom: 13,
            maxZoom: 18,
            styles: [
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [
                        {
                        "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [
                        {
                        "visibility": "off"
                        }
                    ]
                },
                { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
                { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
                { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
                {
                    featureType: "administrative.locality",
                    elementType: "labels.text.fill",
                    stylers: [{ visibility: 'off' }],
                },                
                {
                    featureType: "administrative.province",
                    elementType: "geometry.stroke",
                    stylers: [{ color: "#FFFFFF" }],
                },
                {
                    featureType: "road",
                    elementType: "labels",
                    stylers: [
                      { visibility: "off" }
                    ]
                },
                {
                featureType: "poi",
                elementType: "labels.text.fill",
                stylers: [{ color: "#d59563" }],
                },
                {
                featureType: "poi.park",
                elementType: "geometry",
                stylers: [{ color: "#263c3f" }],
                },
                {
                featureType: "poi.park",
                elementType: "labels.text.fill",
                stylers: [{ color: "#6b9a76" }],
                },
                {
                featureType: "road",
                elementType: "geometry",
                stylers: [{ color: "#38414e" }],
                },
                {
                featureType: "road",
                elementType: "geometry.stroke",
                stylers: [{ color: "#212a37" }],
                },
                {
                featureType: "road",
                elementType: "labels.text.fill",
                stylers: [{ color: "#9ca5b3" }],
                },
                {
                featureType: "road.highway",
                elementType: "geometry",
                stylers: [{ color: "#746855" }],
                },
                {
                featureType: "road.highway",
                elementType: "geometry.stroke",
                stylers: [{ color: "#1f2835" }],
                },
                {
                featureType: "road.highway",
                elementType: "labels.text.fill",
                stylers: [{ visibility: 'off' }],
                },
                {
                featureType: "transit",
                elementType: "geometry",
                stylers: [{ color: "#2f3948" }],
                },
                {
                featureType: "transit.station",
                elementType: "labels.text.fill",
                stylers: [{ color: "#d59563" }],
                },
                {
                featureType: "water",
                elementType: "geometry",
                stylers: [{ color: "#17263c" }],
                },
                {
                featureType: "water",
                elementType: "labels.text.fill",
                stylers: [{ color: "#515c6d" }],
                },
                {
                featureType: "water",
                elementType: "labels.text.stroke",
                stylers: [{ color: "#17263c" }],
                },
            ]
        });
        setMap(map)

        setupSearch(map)

        // setupTiles(map)
    }, []);

    useEffect(() => {
        if (clickEvent.time) {
            const newTiles = tiles
            const tile = newTiles[clickEvent.id].tile
            const selected = !newTiles[clickEvent.id].selected
            newTiles[clickEvent.id].selected = selected
            tile.setOptions({
                fillColor: selected ? '#FFFF00' : '#000000'
            })
            setTiles(newTiles)
        }
    }, [clickEvent])

    const setupSearch = (map) => {
        // Create the search box and link it to the UI element.
        const input = document.getElementById("pac-input") as HTMLInputElement;
        const searchBox = new window.google.maps.places.SearchBox(input);

        map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(input);

        // Bias the SearchBox results towards current map's viewport.
        map.addListener("bounds_changed", () => {
            searchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
        });

        let markers: google.maps.Marker[] = [];

        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.
        searchBox.addListener("places_changed", () => {
            const places = searchBox.getPlaces();

            if (places.length == 0) {
            return;
            }

            // Clear out the old markers.
            markers.forEach((marker) => {
            marker.setMap(null);
            });
            markers = [];

            // For each place, get the icon, name and location.
            const bounds = new google.maps.LatLngBounds();

            places.forEach((place) => {
            if (!place.geometry || !place.geometry.location) {
                console.log("Returned place contains no geometry");
                return;
            }

            const icon = {
                url: place.icon as string,
                size: new google.maps.Size(71, 71),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(17, 34),
                scaledSize: new google.maps.Size(25, 25),
            };

            // Create a marker for each place.
            markers.push(
                new google.maps.Marker({
                map,
                icon,
                title: place.name,
                position: place.geometry.location,
                })
            );

            if (place.geometry.viewport) {
                // Only geocodes have viewport.
                bounds.union(place.geometry.viewport);
            } else {
                bounds.extend(place.geometry.location);
            }
            });
            map.fitBounds(bounds);
        });
    }

    const setupTiles = async (map) => {
        const data = await fetch('http://localhost:4000/data')
            .then(response => response.json());
        const templateTiles = {}

        const getCallback = (id) => () => { setClickEvent({id, time: Date.now()}) }

        Object.keys(data).forEach(id => {
            const paths = [...data[id], data[id][0]];
            const tile = new window.google.maps.Polygon({
                paths: paths,
                strokeColor: '#FFFF00',
                fillColor: '#000000',
                fillOpacity: 0.2,
                strokeWeight: 1,
                map: map
            });

            tile.addListener('click', getCallback(id));

            templateTiles[id] = { tile: tile, selected: false }
        })

        setTiles(templateTiles)
    }

    return (
        <>
            <input
                id="pac-input"
                className="controls"
                type="text"
                placeholder="Search Box"
            />
            <div 
                style={{
                    height: '100%',
                    width: '100%'
                }}
                ref={ref} 
                id="map"
            />
        </>
    )
}