const priceDropdownItemStyle = {
    container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    spacer: {
        margin: '0 20px'
    }
}

export default priceDropdownItemStyle;