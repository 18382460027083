import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import InfoArea from "components/InfoArea/InfoArea";

// @material-ui icons
import Layers from "@mui/icons-material/Layers";
import Terrain from "@mui/icons-material/Terrain";
import EditRoad from "@mui/icons-material/EditRoad";
import Place from "@mui/icons-material/Place";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/presentationSections/descriptionStyle";
import { ROUTES } from "../../../constants";
import { MapsHomeWork } from "@mui/icons-material";
import { Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(descriptionStyle);

export default function SectionDescription() {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem md={8} sm={8}>
            <h4 className={classes.description}>
              Your premiere source for GIS data in the state of Georgia.
            </h4>
            <h4 className={classes.description}>
              Find and download your GIS data from our extensive library of 
              parcel data, contour lines, and more!
            </h4>
          </GridItem>
        </GridContainer>
        <div className={classes.features}>
          <GridContainer>
            <GridItem md={4} sm={6}>
              <Button
                className={classes.button}
                onClick={() => navigate(ROUTES.CONTOUR_LINES.LIST.path)}
              >
                <InfoArea
                  title={ROUTES.CONTOUR_LINES.title}
                  description={ROUTES.CONTOUR_LINES.description}
                  icon={Terrain}
                  iconColor="success"
                  vertical={true}
                />
              </Button>
            </GridItem>
            <GridItem md={4} sm={6}>
              <Button
                className={classes.button}
                onClick={() => navigate(ROUTES.PARCEL_DATA.LIST.path)}
              >
                <InfoArea
                  title={ROUTES.PARCEL_DATA.title}
                  description={ROUTES.PARCEL_DATA.description}
                  icon={Layers}
                  iconColor="info"
                  vertical={true}
                />
              </Button>
            </GridItem>
            <GridItem md={4} sm={6}>
              <Button
                className={classes.button}
                onClick={() => navigate(ROUTES.CAMA_DATA.LIST.path)}
              >
                <InfoArea
                  title={ROUTES.CAMA_DATA.title}
                  description={ROUTES.CAMA_DATA.description}
                  icon={MapsHomeWork}
                  iconColor="gray"
                  vertical={true}
                />
              </Button>
            </GridItem>
            <GridItem md={4} sm={6}>
              <Button
                className={classes.button}
                onClick={() => navigate(ROUTES.ADDRESS_POINTS.LIST.path)}
              >
                <InfoArea
                  title={ROUTES.ADDRESS_POINTS.title}
                  description={ROUTES.ADDRESS_POINTS.description}
                  icon={Place}
                  iconColor="danger"
                  vertical={true}
                />
              </Button>
            </GridItem>
            <GridItem md={4} sm={6}>
              <Button
                className={classes.button}
                onClick={() => navigate(ROUTES.ROAD_CENTERLINES.LIST.path)}
              >
                <InfoArea
                  title={ROUTES.ROAD_CENTERLINES.title}
                  description={ROUTES.ROAD_CENTERLINES.description}
                  icon={EditRoad}
                  iconColor="warning"
                  vertical={true}
                />
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
