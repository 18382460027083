export const ROUTES = {
    PARCEL_DATA: {
        title: 'Parcel Data',
        description: 'Download parcel data in a particular Area of Interest (AOI), or download an entire county.  Download formats include Shapefile, DWG, and GeoJSON.',

        LIST: {
            title: 'Parcel Data - Choose County',
            path: '/parcels'
        },
        MAP: {
            title: (county) => `Parcel Data - ${county}`,
            path: (county = ':county') => `/parcels/${county}`
        }
    },
    CAMA_DATA: {
        title: 'CAMA Data',
        description: 'Download CAMA data sets for an entire county in DBF format.',

        LIST: {
            title: 'CAMA Data (WinGAP) - Choose County',
            path: '/cama'
        }
    },
    ADDRESS_POINTS: {
        title: 'Address Points',
        description: 'Download address points for an entire county in a Shapefile or GeoJSON format.',

        LIST: {
            title: 'Address Points - Choose County',
            path: '/address-points'
        }
    },
    ROAD_CENTERLINES: {
        title: 'Road Centerlines',
        description: 'Download road centerlines for an entire county in Shapefile or GeoJSON format.',

        LIST: {
            title: 'Road Centerlines - Choose County',
            path: '/road-centerlines'
        }
    },
    CONTOUR_LINES: {
        title: 'Contour Lines (1ft)',
        description: "Download contour lines in 1ft., 2ft., or 10ft. intervals, and in Shapefile, DWG, or GeoJSON format.",

        LIST: {
            title: 'Contour Lines - Choose County',
            path: '/contour-lines'
        },
        MAP: {
            title: (county) => `Contour Lines - ${county}`,
            path: (county = ':county') => `/contour-lines/${county}`
        }
    },
    SHOPPING_CART: {
        title: 'My Cart',
        description: 'View your selected datasets and checkout to download your files instantly!',
        path: '/shopping-cart'
    },
    PAYMENT_SUCCESSFUL: {
        title: 'Payment Successful',
        description: 'Stripe payment was successful',
        path: (token = ':token') => `/payment-success/${token}`
    },
    DOWNLOAD: {
        title: 'Payment Successful',
        description: 'Stripe payment was successful',
        path: (token = ':token') => `/download/${token}`
    },
    RECEIPT: {
        title: 'Receipt',
        description: 'Payment receipt',
        path: (token = ':token') => `/receipt/${token}`
    }
}
