// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import InfoArea from "components/InfoArea/InfoArea";
// @material-ui icons
import AttachMoney from "@material-ui/icons/AttachMoney";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// images

import { AccountBalance, Public, School } from "@mui/icons-material";
import overviewStyle from "assets/jss/material-kit-pro-react/views/presentationSections/overviewStyle";

const useStyles = makeStyles(overviewStyle);

export default function SectionDiscounts() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div
        className={classes.features5}
      >
        <GridItem md={10} className={classNames(classes.mlAuto, classes.mrAuto)}>
          <h2 className={classes.title}>You might be eligible for free or discounted data! </h2>
          <p>We are proud to offer free or discounted data to select individuals and groups! </p>
          <p>For more information, contact us at <a href="mailto:info@gis1.net?subject=Data Clearinghouse Discount">info@gis1.net</a>.</p>
        </GridItem>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem sm={4} className={classes.featuresShow}>
              <InfoArea
                title={<span>Students<br/>& Education</span>}
                description={
                  <p>
                    Students and other educational entities are eligible to receive parcel data for free when being used for educational purposes such as class projects or research.  In some cases, other data may also be available for free. 
                  </p>
                }
                icon={School}
                iconColor="info"
                vertical={true}
              />
            </GridItem>
            {/* <GridItem sm={3} className={classes.featuresShow}>
              <InfoArea
                title={<span>Non-Profit<br/>Organizations</span>}
                description={
                  <p>
                    Non-profit organizations are also eligible for free or discounted data for certain business cases.
                  </p>
                }
                icon={Public}
                iconColor="info"
                vertical={true}
              />
            </GridItem> */}
            <GridItem sm={4} className={classes.featuresShow}>
              <InfoArea
                title={<span>Government Agencies<br/>& Contractors</span>}
                description={
                  <p>
                    Our parcel data is always free for government agencies or contractors using the data in service of a government agency.  In some cases, other data may also be available for free.
                  </p>
                }
                icon={AccountBalance}
                iconColor="warning"
                vertical={true}
              />
            </GridItem>
            <GridItem sm={4} className={classes.featuresShow}>
              <InfoArea
                title={<span>Bulk<br/>Purchases</span>}
                description={
                  <p>
                    For select customers purchasing data in bulk, you may be eligible for a custom discount and less restrictive usage limitations.
                  </p>
                }
                icon={AttachMoney}
                iconColor="success"
                vertical={true}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
