import { configureStore } from '@reduxjs/toolkit';
import apiReducer from './api'
import mapReducer from './map'
import shoppingCartReducer from './shoppingCart'
import {
    persistStore,
    persistReducer
  } from 'redux-persist'
  import storage from 'redux-persist/lib/storage'

export const appStore = configureStore({
    reducer: {
        api: apiReducer,
        map: mapReducer,
        shoppingCart: persistReducer({
            key: 'shoppingCart',
            version: 1,
            storage
        }, shoppingCartReducer)
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false
    })
})

export const persistor = persistStore(appStore)