import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button";

import modalStyle from "assets/jss/material-kit-pro-react/modalStyle";
import { FormControlLabel } from "@material-ui/core";
import { Checkbox } from "@mui/material";
import { useState } from "react";

//@ts-ignore
const useStyles = makeStyles(modalStyle);

export const LicenseModal = (props) => {
    const {
        isOpen,
        accept,
        close,
    } = props

    const classes = useStyles()
    
    const [licenseAccepted, setLicenseAccepted] = useState(false)
    const [resaleAccepted, setResaleAccepted] = useState(false)

    const handleLicenseAgreement = (event) => {
        setLicenseAccepted(event.target.checked)
    }
    const handleResaleAgreement = (event) => {
        setResaleAccepted(event.target.checked)
    }

    return (
        <Dialog
            classes={{
                root: classes.modalRoot,
                paper: classes.modal
            }}
            open={isOpen}
            onClose={close}
        >
            <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
            >
            <Button
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                onClick={close}
            >
                <Close className={classes.modalClose} />
            </Button>
                <h4 className={classes.modalTitle}>License Agreement</h4>
            </DialogTitle>
            <DialogContent
                className={`${classes.modalBody} ${classes.modalBodyFull}`}
            >
                <div>
                    <div className={classes.licenseStatement}>
                        <p>
                            All digital data layers
                            purchased from this site are solely for the 
                            original recipient's internal use in the conduct of 
                            its daily business affairs. Access to the digital 
                            data is limited to the purchaser, and the employees 
                            or co-workers of the purchaser within the same organization.
                            <strong> Digital data layers may not be reproduced 
                            or redistributed, in whole or in part, without 
                            prior written permission from GIS1. </strong>
                            Purchaser agrees to accept digital data layers as-is, 
                            and with no guarantee of quality or accuracy.  
                            Purchaser agrees to hold GIS1 and all their 
                            employees, and agents harmless from any claim, 
                            suit, or proceeding arising out of the use of the 
                            data in accordance with this agreement, including 
                            indemnification of GIS1 for reasonable expenses 
                            incurred in defending such claims.
                        </p>
                    </div>
                    <FormControlLabel
                        style={{marginTop: "1rem"}}
                        control={<Checkbox color="info" onChange={handleLicenseAgreement}/>} 
                        label="I have read and accept the license agreement." 
                    />
                    <FormControlLabel 
                        style={{marginTop: "1rem"}}
                        control={<Checkbox color="info" onChange={handleResaleAgreement}/>} 
                        label={<span>I acknowledge that this data may <strong>NOT BE RESOLD OR REDISTRIBUTED UNDER ANY CIRCUMSTANCES</strong>, except with explicit written permission from GIS1.</span>}
                    />
                </div>
                <Button
                    color="info"
                    onClick={accept}
                    disabled={!(licenseAccepted && resaleAccepted)}
                    className={classes.submitButton}
                >
                    <span className={classes.buttonLabel}>Confirm</span>
                </Button>
            </DialogContent>
        </Dialog>
    )
}