import { makeStyles } from "@material-ui/core/styles";

import priceDropdownItemStyle from "assets/jss/material-kit-pro-react/components/priceDropdownItemStyle";
import { DataType } from "models/enums/DataType";
import { FileType } from "models/enums/FileType";

const useStyles = makeStyles(priceDropdownItemStyle)

export const PriceDropdownItem = (props) => {
    const { label, itemTemplate, strikeThrough} = props

    const classes = useStyles()

    const contents = <>
        <span>{label}</span>
        <span className={classes.spacer}/>
        <span>
            <strong>${(itemTemplate.getTotalPrice() / 100).toFixed(2)}</strong>
        </span>
    </>
    
    return (
        <div className={classes.container}>
            {strikeThrough ? <s>{contents}</s> : contents}
        </div>
    )
}