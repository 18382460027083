import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button";

import modalStyle from "assets/jss/material-kit-pro-react/modalStyle";
import { ShoppingCart } from "@material-ui/icons";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import { PriceDropdownItem } from "../CustomDropdown/PriceDropdownItem";
import { FileType } from "models/enums/FileType";

//@ts-ignore
const useStyles = makeStyles(modalStyle);

export const PreviewModal = (props) => {
    const {
        isOpen,
        close,
        children
    } = props

    const classes = useStyles()

    return (
        <Dialog
            classes={{
                root: classes.modalRoot,
                paper: classes.modal
            }}
            open={isOpen}
            onClose={close}
        >
            <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
            >
            <Button
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                onClick={close}
            >
                {" "}
                <Close className={classes.modalClose} />
            </Button>
                <h4 className={classes.modalTitle}>Parcel Data Preview</h4>
            </DialogTitle>
            <DialogContent
                className={`${classes.modalBody} ${classes.modalBodyCenter}`}
            >
                { children }
            </DialogContent>
            <DialogActions className={classes.modalFooter}>
                <Button onClick={close} color="info">
                    Go Back
                </Button>
            </DialogActions>
        </Dialog>
    )
}