import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import Close from "@material-ui/icons/Close";
// core components
import styles from "assets/jss/material-kit-pro-react/components/headerStyle";

import HeaderLinks from "components/Header/HeaderLinks";
import { Gis1Logo } from "components/Icons/Gis1Logo";
import SnackbarContent from "components/Snackbar/SnackbarContent";
import { Georgia } from "components/Icons/Georgia";

const useStyles = makeStyles(styles);

export default function Header({ tall = false }) {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const classes = useStyles();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  const color = 'info';
  const fixed = false;
  const absolute = true;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  });

  const links = <HeaderLinks dropdownHoverColor={color} />;

  return (
    <AppBar className={appBarClasses}>
      <div>
        <Toolbar className={classes.container}>
          <Button className={classes.title}>
            <Link to="/">
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Georgia/>
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                  <h3 style={{ lineHeight: '24px' }}>Georgia GIS<br/>Clearinghouse</h3>
                  <span style={{ lineHeight: '24px' }}>Powered by GIS1</span>
                </div>
              </div>
            </Link>
          </Button>
          <Hidden smDown implementation="css" className={classes.hidden}>
            <div className={classes.collapse}>{links}</div>
          </Hidden>
          <Hidden mdUp>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
            >
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>
        <Hidden mdUp implementation="js">
          <Drawer
            variant="temporary"
            anchor={"right"}
            open={mobileOpen}
            classes={{
              paper: classes.drawerPaper
            }}
            onClose={handleDrawerToggle}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              className={classes.closeButtonDrawer}
            >
              <Close />
            </IconButton>
            <div className={classes.appResponsive}>{links}</div>
          </Drawer>
        </Hidden>
      </div>
      <div>
        <SnackbarContent
          message={<span>
            <strong>Metadata coming soon!</strong><br/>
            Thank you for your patience as we work to add metadata to all of our available datasets.
          </span>}
          color='warning'
          icon='info_outline'
        />
      </div>
    </AppBar>
  );
}

Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),
  links: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired
  })
};
