import { grayColor } from "assets/jss/material-kit-pro-react";

export const receiptStyle = {
    container: {
        height: '100vh',
        width: '100vw'
    },
    headerBackground: {
        position: 'fixed',
        top: 0,
        left: 0,
        background: 'red',
        height: '20%',
        width: '100%',
        clipPath: "polygon(0% 0%, 100% 0%, 100% 15%, 0% 100%)"
    },
    content: {
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxSizing: 'border-box',
        height: '100%',
        width: '100%',
        paddingTop: '10%',
    },
    logo: {
        filter: 'drop-shadow(0 0 6px rgb(0 0 0 /.6))'
    },
    title: {
        marginBottom: '4px'
    },
    subtitle: {
        marginTop: 0,
        marginBottom: '6px'
    },
    order: {
        marginTop: 0,
        color: grayColor[0]
    },
    summaryContainer: {
        display: 'flex',
        flexDirection: 'row',
        margin: '10px 0 15px 0'
    },
    summaryItem: {
        margin: '0 40px 0 40px',
        '& > p': {
            '&:first-child': {
                fontWeight: 'bold'
            },
            margin: 0
        }
    },
    tableContainer: {
        marginTop: '15px',
        width: '85%'
    },
    footer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        margin: '15px 0',
        '& > p': {
            margin: '3px 0'
        }
    }
}