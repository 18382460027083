import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { Outlet, useLocation } from "react-router-dom"
import { setSelectedFeatures } from "redux/map"
import { setDrawerOpen } from "redux/shoppingCart"
import { useUpdateStatusMutation } from "redux/dataApi"

export const Root = () => {
    const location = useLocation()
    const dispatch = useDispatch()

    const [updateStatus] = useUpdateStatusMutation();

    useEffect(() => {
        dispatch(setDrawerOpen(false))
        dispatch(setSelectedFeatures({}))

        const body = document.getElementsByTagName('body')[0]
        body.style.removeProperty('overflow')

        updateStatus(location.pathname);
    }, [location])
  
    return <Outlet/>
}