import { makeStyles } from "@material-ui/core"
import { useParams } from "react-router-dom"
import { useGetOrderQuery } from "redux/dataApi"

import { receiptStyle } from "assets/jss/material-kit-pro-react/views/receiptStyle"
import { Gis1Logo } from "components/Icons/Gis1Logo"
import { ShoppingItems } from "components/ShoppingItems/ShoppingItems"
import { useEffect } from "react"
import { DownloadItem } from "models/DownloadItem"
// @ts-ignore
const useStyles = makeStyles(receiptStyle)

export const Receipt = () => {
    const styles = useStyles()

    const { token } = useParams()

    const { data: order } = useGetOrderQuery(token)

    useEffect(() => {
        if (order) {
            setTimeout(() => {
                document.dispatchEvent(new CustomEvent('ready'));
            }, 100); //Delay after render to allow DOM to update
        }
    }, [order]);

    return order && (
        <div className={styles.container}>
            <div className={styles.headerBackground}></div>
            <div className={styles.content}>
                <Gis1Logo size={125} className={styles.logo}/>
                <h1 className={styles.title}>Receipt from GIS1, LLC</h1>
                <h2 className={styles.subtitle}>GIS Data Clearinghouse</h2>
                <h3 className={styles.order}>Order {order.id}</h3>
                <div className={styles.summaryContainer}>
                    <div className={styles.summaryItem}>
                        <p>Amount Paid</p>
                        <p>${(order.total / 100).toFixed(2)}</p>
                    </div>
                    <div className={styles.summaryItem}>
                        <p>Order Date</p>
                        <p>{new Date(order.date).toLocaleDateString('en-us', { month:'short', day: 'numeric', year: 'numeric' })}</p>
                    </div>
                    {order.paymentIntent && (
                        <div className={styles.summaryItem}>
                            <p>Credit Card</p>
                            <p>**** **** **** {order.paymentIntent.payment_method.card.last4}</p>
                        </div>
                    )}
                </div>
                <div className={styles.tableContainer}>
                    <ShoppingItems
                        shoppingCartItems={DownloadItem.hydrate(order.downloadItems)}
                        promotionCode={order.promotionCode}
                    />
                </div>
                <div className={styles.footer}>
                    <p>© {new Date().getFullYear()} GIS1, LLC</p>
                    <p>Questions about your order? Contact us at <a href="mailto:info@gis1.net">info@gis1.net</a></p>
                </div>
            </div>
        </div>
    )
}