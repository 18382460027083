
import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Close, Delete } from "@material-ui/icons";
import { Tooltip } from "@mui/material";
import Button from "components/CustomButtons/Button";
import { DataType } from "models/enums/DataType";
import { FileType } from "models/enums/FileType";
import { useDispatch } from "react-redux";
import { removeItem, setPromotionCode } from "redux/shoppingCart";

import tableStyle from "assets/jss/material-kit-pro-react/components/tableStyle";
import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle";

const useTableStyles = makeStyles(tableStyle);
const useStyles = makeStyles(shoppingCartStyle);

export const ShoppingItems = (props) => {
    const { 
      interactive, 
      shoppingCartItems, 
      promotionCode,
      actionRows
    } = props;

    const classes = useStyles();
    const tableClasses = useTableStyles();
    const dispatch = useDispatch();

    const getTableRows = () => {
    return Object.keys(shoppingCartItems).length > 0 
        ? (
        Object.keys(shoppingCartItems).map(uuid => {
            const item = shoppingCartItems[uuid]

            return (
            <tr key={uuid}>
                { interactive ? (
                  <td>
                    <Tooltip
                        key={uuid}
                        title="Remove item"
                        placement="left"
                        classes={{ tooltip: classes.tooltip }}
                    >
                        <Button link className={classes.actionButton} onClick={() => dispatch(removeItem(uuid))}>
                        <Delete />
                        </Button>
                    </Tooltip>
                  </td>
                ) : (
                  <td></td>
                )}
                <td>
                { item.county.replaceAll('_', ' ') }
                </td>
                <td>
                { DataType[item.dataType][item.dataSubType].label }
                </td>
                <td>
                { FileType[item.fileType].label }
                </td>
                <td>
                {
                    item.keys.map(key => 
                    <Chip key={key} size="small" label={key}/>
                    )
                }
                </td>
                <td className={tableClasses.right}>
                {
                  item.getTotalPrice(promotionCode) === item.getTotalPrice()
                    ? <span>${(item.getTotalPrice(promotionCode) / 100).toFixed(2)}</span>
                    : <span><em><s>${(item.getTotalPrice() / 100).toFixed(2)}</s></em><br/>${(item.getTotalPrice(promotionCode) / 100).toFixed(2)}</span>
                }
                </td>
            </tr>
            )
        })
        ) : ([
        <tr>
            <td colSpan={6} className={tableClasses.center}>Your cart is empty!</td>
        </tr>
        ])
    }

    const calculateTotal = (usePromo = false) => {
        let total = Object.values(shoppingCartItems).reduce(
            (prev, item) => prev + item.getTotalPrice(usePromo ? promotionCode : null), 0
        )

        if (usePromo && promotionCode?.coupon?.applies_to?.products?.length === 0) {
          total -= promotionCode.coupon.amount_off ?? (total * (promotionCode.coupon.percent_off / 100));
          total = Math.max(total, 0);
        }

        return (total / 100).toFixed(2)
    }

    return (
        <table className={tableClasses.table}>
                <thead className={tableClasses.tableShoppingHead}>
                  <tr>
                    <th></th>
                    <th>County</th>
                    <th>Data Type</th>
                    <th>File Format</th>
                    <th>Selection</th>
                    <th className={tableClasses.right}>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {getTableRows()}
                  { promotionCode && (
                    <tr>
                      <td colSpan={6} className={tableClasses.tableCellTotal}>
                        {interactive && (
                            <Button link className={classes.actionButton} onClick={() => dispatch(setPromotionCode(null))}>
                            <Close />
                            </Button>
                        )}
                        <strong>Coupon: </strong>{promotionCode.coupon.name}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan={6} className={tableClasses.tableCellTotal}>
                      <strong>Total: </strong> 
                      {
                        calculateTotal() === calculateTotal(true)
                          ? <span>${ calculateTotal() }</span>
                          : <span><em><s>${ calculateTotal() }</s></em><br/>${ calculateTotal(true) }</span>
                      }
                    </td>
                  </tr>
                  { actionRows }
                </tbody>
              </table>
    )
}