import {
  section,
  mrAuto,
  mlAuto,
  title,
  container,
  description,
  cardTitle,
  coloredShadow,
  blackColor,
  whiteColor,
  grayColor,
  hexToRgb
} from "assets/jss/material-kit-pro-react";

const overviewStyle = {
  mrAuto,
  mlAuto,
  title,
  container,
  description: {
    ...description,
    marginBottom: "70px"
  },
  section: {
    ...section,
    padding: "20px 0px"
  },
  sectionTestimonials: {
    paddingTop: "50px",
    paddingBottom: "80px",
    textAlign: "center",
    "& $cardTitle": {
      marginTop: "0px"
    }
  },
  features5: {
    ...section,
    backgroundRepeat: "no-repeat",
    position: "relative",
    padding: "20px 0px",
    textAlign: "center",
    "& $title": {
      zIndex: "2",
      position: "relative",
      marginBottom: "30px",
      color: grayColor[0]
    },
    "& p": {
      color: blackColor
    },
    // "&:after": {
    //   position: "absolute",
    //   width: "100%",
    //   height: "100%",
    //   content: "''",
    //   zIndex: "0",
    //   left: "0px",
    //   top: "0px"
    // }
  },
  features: {
    textAlign: "center",
    paddingTop: "30px",
    pddingBottom: "0px"
  },
  featuresShow: {
    zIndex: 3,
    borderTop: 0,
    borderBottom: 0,
    "&:last-of-type": {
      borderRight: 0
    },
    "&:first-of-type": {
      borderLeft: 0
    },
    "& h4,& .fab,& .fas,& .far,& .fal": {
      color: blackColor
    },
    "& p": {
      color: grayColor[0],
      lineHeight: "24px",
      fontSize: "16px"
    }
  },
  ourClients: {
    textAlign: "center",
    "& img": {
      width: "100%",
      maxWidth: "140px",
      margin: "0 auto",
      display: "inline-block"
    }
  },
  cardTitle: {
    ...cardTitle,
    marginTop: 0
  },
  cardDescription: {
    fontSize: "16px",
    lineHeight: "1.6em",
    color: grayColor[0]
  },
  coloredShadow,
  alignLeft: {
    textAlign: "left"
  }
};

export default overviewStyle;
