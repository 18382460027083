import { createSlice } from '@reduxjs/toolkit';

export const mapSlice = createSlice({
    name: 'map',
    initialState: { 
        hover: null,
        selectableFeatures: {},
        selectedFeatures: {},
    },
    reducers: {
        setHover: (state, action) => { state.hover = action.payload },
        setSelectableFeatures: (state, action) => { state.selectableFeatures = action.payload },
        setSelectedFeatures: (state, action) => { state.selectedFeatures = action.payload },
        selectFeature: (state, action) => { state.selectedFeatures[action.payload.id] = action.payload.value },
        unselectFeature: (state, action) => { delete state.selectedFeatures[action.payload] }
    }
});

export const { 
    setHover,
    setSelectableFeatures,
    setSelectedFeatures, 
    selectFeature, 
    unselectFeature 
} = mapSlice.actions;
export default mapSlice.reducer;