import {
    blackColor,
    whiteColor,
    hexToRgb,
    grayColor
  } from "assets/jss/material-kit-pro-react";

export const shoppingCartDrawerStyle = {
    container: {
        height: '100%',
        width: '100%',
        maxWidth: '25rem',
        display: 'flex',
        flexFlow: 'column',
        backgroundColor: grayColor[14]
    },
    titleContainer: {
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px',
        backgroundColor: 'white',
        boxShadow:
            "0 12px 20px -10px rgba(" + hexToRgb(whiteColor) + ", 0.28)," +
            "0 4px 20px 0px rgba(" + hexToRgb(blackColor) + ", 0.24)," +
            "0 7px 8px -5px rgba(" + hexToRgb(whiteColor) + ", 0.2)",
        '& div': {
            display: 'flex',
            flexBasis: 'column',
            alignItems: 'center',
        },
        '& h2': {
            margin: '0 10px'
        }
    },
    spacer: {
        margin: '0 16px'
    },
    iconButton: {
        marginLeft: '16px',
        cursor: 'pointer',
        border: 'none',
        backgroundColor: 'transparent',
        padding: 0,
        '& svg': {
            display: 'block'
        },
    },
    itemContainer: {
        width: '100%',
        overflow: 'scroll',
        flex: '1 1 auto',
    },
    item: {
        margin: '8px',
        padding: '16px',
        '& h3,h4': {
            margin: 0,
            marginBottom: '8px'
        }
    },
    itemContent: {
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'start',
        justifyContent: 'space-between',
        marginBottom: '8px'
    },
    chip: {
        margin: '2px'
    },
    price: {
        width: '100%'
    },
    checkoutButton: {
        margin: '16px'
    }
}