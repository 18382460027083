import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button";
import { ClipLoader } from 'react-spinners';
import { infoColor } from 'assets/jss/material-kit-pro-react';

import modalStyle from "assets/jss/material-kit-pro-react/modalStyle";
import { TextField } from "@mui/material";
import { useGetCouponQuery } from "redux/dataApi";
import { isEmpty } from 'lodash'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPromotionCode } from "redux/shoppingCart";

//@ts-ignore
const useStyles = makeStyles(modalStyle);

export const CouponModal = (props) => {
    const {
        isOpen,
        close
    } = props

    const classes = useStyles()
    const dispatch = useDispatch()

    const [couponCodeInput, setCouponCodeInput] = useState(null)
    const [searchCouponCode, setSearchCouponCode] = useState(null)

    const {
        data: stripePromotionCode,
        isFetching,
        isSuccess,
        isError,
        error
     } = useGetCouponQuery(searchCouponCode, { skip: isEmpty(searchCouponCode) })

    useEffect(() => {
        if (isSuccess && !isFetching) {
            dispatch(setPromotionCode(stripePromotionCode))
            close()
        }
    }, [isSuccess, isFetching, stripePromotionCode])

    let errorMessage = '';
    if (isError) {
        //@ts-ignore
        if (error.status === 404) {
            errorMessage = 'Code invalid or expired'
        } else {
            errorMessage = 'An unknown error occurred'
        }
    }

    return (
        <Dialog
            classes={{
                root: classes.modalRoot,
                paper: classes.modal
            }}
            open={isOpen}
            onClose={close}
        >
            <DialogTitle
                disableTypography
                className={classes.modalHeader}
            >
                <Button
                    simple
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    onClick={close}
                >
                    <Close className={classes.modalClose} />
                </Button>
                <h4 className={classes.modalTitle}>Add a Promo Code</h4>
            </DialogTitle>
            <DialogContent
                className={`${classes.modalBody} ${classes.modalBodyFull}`}
            >
                <TextField
                    variant="outlined"
                    size="small"
                    color="info"
                    label="Promo Code"
                    error={isError}
                    helperText={errorMessage}
                    onChange={event => setCouponCodeInput(event.target.value)}
                />
                <Button
                    color="info"
                    onClick={() => setSearchCouponCode(couponCodeInput)}
                    disabled={isEmpty(couponCodeInput) || isFetching}
                    className={classes.submitButton}
                >
                    <ClipLoader
                        color={'white'}
                        loading={isFetching}
                        size={10}
                    />
                    <span className={classes.buttonLabel}>Redeem</span>
                </Button>
            </DialogContent>
        </Dialog>
    )
}