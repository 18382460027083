import { FileType } from "./FileType";

export const DataType = {
    parcels: { 
        key: 'parcels', 
        label: 'Parcels',
        selectionLabel: 'Parcels',
        selectionLimit: 5,
        pricePerSelection: false,
        getStartedMessage: () => (
            <>
                <p>To get started, search for your parcel number or address, or zoom in to your area of interest.</p>
                <br/>
                <p>Click up to <strong>5</strong> yellow parcel markers to select them for download.</p>
                <br/>
                <p><strong>If you do not see any yellow parcel markers, try zooming in or out, or pan around the map slightly.</strong></p>
            </>
        ),

        default: {
            key: 'default',
            label: 'Parcels',

            fileTypes: [
                FileType.shapefile.key,
                FileType.dxf.key,
                FileType.kml.key,
                FileType.json.key
            ]
        }
    },
    parcelsCounty: { 
        key: 'parcelsCounty', 
        label: 'Parcels',
        pricePerSelection: false,

        withWingap: {
            key: 'withWingap',
            label: 'Entire County (with WinGAP)',
            fileTypes: [
                FileType.shapefile.key,
                // FileType.json.key
            ]
        },
        withoutWingap: {
            key: 'withoutWingap',
            label: 'Entire County (without WinGAP)',
            fileTypes: [
                FileType.shapefile.key,
                // FileType.dwg.key,
                // FileType.json.key
            ]
        },
    },
    addressPoints: { 
        key: 'addressPoints', 
        label: 'Address Points',
        pricePerSelection: false,

        default: {
            key: 'default',
            label: 'Address Points',

            fileTypes: [
                FileType.shapefile.key,
                FileType.json.key
            ]
        }
    },
    cama: {
        key: 'cama',
        label: 'CAMA Data',
        pricePerSelection: false,

        default: {
            key: 'default',
            label: 'CAMA Data',

            fileTypes: [
                FileType.dbf.key
            ]
        }
    },
    roadCenterlines: { 
        key: 'roadCenterlines',
        label: 'Road Centerlines',    
        pricePerSelection: false,

        default: {
            key: 'default',
            label: 'Road Centerlines',

            fileTypes: [
                FileType.shapefile.key,
                FileType.json.key
            ]
        }
    },
    contourLines: {
        key: 'contourLines', 
        label: 'Contour Lines',
        selectionLabel: 'Contour Tiles',
        pricePerSelection: true,
        getStartedMessage: (hasParcels) => (
            <>
                <p>To get started, search for your { hasParcels ? 'parcel number or address': 'address' }, or zoom in to your area of interest.</p>
                <br/>
                <p>Click any number yellow tiles to select the area of contour lines you want to download.</p>
                <br/>
                <p><strong>If you do not see any yellow tiles, try zooming in or out, or pan around the map slightly.</strong></p>
            </>
        ),

        interval1Ft: {
            key: 'interval1Ft',
            label: 'Countour Lines (1ft)',

            fileTypes: [
                FileType.shapefile.key,
                FileType.dwg.key,
                FileType.json.key
            ]
        },
        interval2Ft: {
            key: 'interval2Ft',
            label: 'Contour Lines (2ft.)',

            fileTypes: [
                FileType.shapefile.key,
                FileType.dwg.key,
                FileType.json.key
            ]
        },
        interval10Ft: {
            key: 'interval10Ft',
            label: 'Contour Lines (10ft.)',

            fileTypes: [
                FileType.shapefile.key,
                FileType.dwg.key,
                FileType.json.key
            ]
        },
    }
}