import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Footer from "components/Footer/Footer";
import Header from "components/Header/Header";
import Parallax from "components/Parallax/Parallax";
import { useParams } from "react-router-dom";
import jwt from 'jsonwebtoken';

import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import pageStyle from "assets/jss/material-kit-pro-react/views/parcelDataStyle";
import tableStyle from "assets/jss/material-kit-pro-react/components/tableStyle";
import { ROUTES } from "constants";

const useStyles = makeStyles({...tableStyle, ...pageStyle});

export const DownloadPage = () => {
    const { token } = useParams()
    const decoded = jwt.decode(token)

    const classes = useStyles();
    
    return (
        <div>
            <Header/>
            <Parallax/>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                <Card plain>
                    <CardBody plain>
                    <h3 className={classes.cardTitle}>{ROUTES.DOWNLOAD.title}</h3>
                    <h4>We are gathering your files now!</h4>
                    <p>You will receive an email with your download link when it becomes available.</p>
                    <p>
                        If you do not receive an email after about an hour, or if you experience trouble with your 
                        download, please contact us at <a href={`mailto:info@gis1.net?subject=Data Clearinghouse Order ${decoded.orderId}`}>info@gis1.net</a>.
                    </p>
                    </CardBody>
                </Card>
                </div>
            </div>
            <Footer/>
        </div>
    )
}