import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setHover, setSelectedFeatures, selectFeature, unselectFeature } from '../../redux/map';
import { makeStyles } from "@material-ui/core/styles";
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { isEmpty, keyBy } from 'lodash';

import mapPanelStyle from "assets/jss/material-kit-pro-react/components/mapPanelStyle";
import { AsyncSearchSection } from './AsyncSearchSection';
import { CONFIG } from 'constants/config';
import { mapStyle } from 'assets/jss/google-maps';
import { featureVisible, featureVisibleCenter } from 'utils/Helpers';
import SnackbarContent from 'components/Snackbar/SnackbarContent';
import { MarkerWithLabel } from '@googlemaps/markerwithlabel';
import { mdiRecord } from '@mdi/js';

//@ts-ignore
const useStyles = makeStyles(mapPanelStyle);

export const ContourTilesMapSection = (props) => {
    const {
        map,
        mapRef,
        tileData
    } = props;
    
    const dispatch = useDispatch();
    const classes = useStyles();

    const DEBOUNCE_INTERVAL = 200;

    const tiles = keyBy(tileData, feature => feature.properties.TILE_NUM)

    const {
        hover,
        selectedFeatures: selectedTiles
    } = useSelector((state: any) => state.map);
    const [mapBounds, setMapBounds] = useState(null)
    const [polygons, setPolygons] = useState<{[k: string]: google.maps.Polygon}>({})
    const [labels, setLabels] = useState<{[k: string]: google.maps.Marker}>({})
    const [debounceTimeoutId, setDebounceTimeoutId] = useState(null);
    const [polygonEvent, setPolygonEvent] = useState(null)


    useEffect(() => {
        dispatch(setSelectedFeatures({}))
    }, []);

    useEffect(() => {
        if (map) {
            google.maps.event.addListener(map, 'bounds_changed', () => { setMapBounds(map.getBounds()); });
        }
    }, [map])

    useEffect(() => {
        Object.keys(polygons).forEach(key => {
            polygons[key].setOptions({
                fillOpacity: isSelected(key) ? 0.6 : 0,
                strokeWeight: hover === key ? 4 : 1
            })
        })
    }, [selectedTiles, hover])

    useEffect(() => {
        if (mapBounds) {
            if (debounceTimeoutId) {
                clearTimeout(debounceTimeoutId);
                setDebounceTimeoutId(null);
            }

            const timeoutId = setTimeout(() => {
                handleMapBoundsChange();
                setDebounceTimeoutId(null);
            }, DEBOUNCE_INTERVAL);
            setDebounceTimeoutId(timeoutId);
        }
    }, [mapBounds])

    useEffect(() => {
        if (polygonEvent) {
            switch(polygonEvent.type) {
                case 'mouseover':
                    dispatch(setHover(polygonEvent.tileNum));
                    break;
                case 'mouseout':
                    dispatch(setHover(null));
                    break;
                case 'click':
                    if (isSelected(polygonEvent.tileNum)) {
                        dispatch(unselectFeature(polygonEvent.tileNum))
                    } else {
                        dispatch(selectFeature({id: polygonEvent.tileNum, value: polygons[polygonEvent.tileNum]}))
                    }
                    break;
            }

            setPolygonEvent(null)
        }
    }, [polygonEvent])

    const handleMapBoundsChange = () => {
        const mapBounds = map.getBounds()

        const currentPolygons = polygons
        const newPolygons = {}

        const currentLabels = labels
        const newLabels = {}

        if (tiles && map.getZoom() >= 10) {
            const visibleTiles = keyBy(
                Object.values(tiles).filter(tile => featureVisible(tile, mapBounds)),
                tile => tile.properties.TILE_NUM
            )
            Object.keys(visibleTiles).forEach(tileNum => {
                if (tileNum in currentPolygons) {
                    newPolygons[tileNum] = currentPolygons[tileNum]
                } else {
                    const polygon = new google.maps.Polygon({
                        map,
                        clickable: true,
                        paths: tiles[tileNum].geometry.coordinates.map(polygons => polygons.map(point => ({
                            lat: point[1],
                            lng: point[0]
                        }))),
                        strokeColor: 'yellow',
                        strokeWeight: 1,
                        fillColor: 'yellow',
                        fillOpacity: 0
                    })
                    google.maps.event.addListener(polygon, 'mouseover', () => {
                        setPolygonEvent({ time: new Date(), type: 'mouseover', tileNum })
                    })
                    google.maps.event.addListener(polygon, 'mouseout', () => {
                        setPolygonEvent({ time: new Date(), type: 'mouseout', tileNum })
                    })
                    google.maps.event.addListener(polygon, 'click', () => {
                        setPolygonEvent({ time: new Date(), type: 'click', tileNum })
                    })
                    newPolygons[tileNum] = polygon
                }

                if (map.getZoom() >= 13.5) {
                    const visibleCenter = featureVisibleCenter(tiles[tileNum], mapBounds);

                    const label = new MarkerWithLabel({
                        position: visibleCenter,
                        clickable: false,
                        draggable: false,
                        labelContent: tileNum,
                        labelClass: 'google-maps-marker-label',
                        icon: {
                            path: mdiRecord,
                            scale: 1,
                            fillColor: 'transparent',
                            strokeColor: 'transparent'
                        },
                        map
                    })
                    newLabels[tileNum] = label
                }
            })
        }

        setPolygons(newPolygons)
        setLabels(newLabels)

        Object.keys(currentLabels).forEach(key => {
            currentLabels[key].setMap(null)
        })
        Object.keys(currentPolygons).forEach(key => {
            if (!(key in newPolygons)) {
                currentPolygons[key].setMap(null)
            }
        }) 
    }

    const isSelected = (tileNum) => {
        return tileNum in selectedTiles
    }

    return (
        <div className={classes.mapSectionContent}>
            <div className={classes.alerts}>
                <SnackbarContent
                    message={<span><strong>
                        Note that contour line data is <em>NOT</em> pictured in the map below.<br/>
                        Note that parcel lines are <em>NOT</em> included in contour line downloads.
                    </strong></span>}
                    color='warning'
                    icon='warning_amber'
                />
            </div>
            <div className={classes.mapWrapper}>
                <div className={classes.map} ref={mapRef} id="map"/>
            </div>
        </div>
    );
}