import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button";

import modalStyle from "assets/jss/material-kit-pro-react/modalStyle";
import { FormControlLabel, InputLabel, TextField } from "@material-ui/core";
import { Checkbox } from "@mui/material";
import { useState } from "react";

//@ts-ignore
const useStyles = makeStyles(modalStyle);

export const EmailModal = (props) => {
    const {
        isOpen,
        submit,
        close,
    } = props

    const classes = useStyles()
    
    const [email, setEmail] = useState(null)
    const [updatesOptIn, setUpdatesOptIn] = useState(false)

    const emailValid = () => {
        return email && email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    }

    const handleEmail = (event) => {
        setEmail(event.target.value)
    }
    const handleUpdatesOptIn = (event) => {
        setUpdatesOptIn(event.target.checked)
    }

    return (
        <Dialog
            classes={{
                root: classes.modalRoot,
                paper: classes.modal
            }}
            open={isOpen}
            onClose={close}
        >
            <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
            >
            <Button
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                onClick={close}
            >
                <Close className={classes.modalClose} />
            </Button>
                <h4 className={classes.modalTitle}>Email Address</h4>
            </DialogTitle>
            <DialogContent
                className={`${classes.modalBody} ${classes.modalBodyFull}`}
            >
                <div>
                    <p><strong>Your email address will be used to deliver your receipt and your download link when it becomes available.</strong></p>
                    <TextField 
                        label="Enter Your Email Address" 
                        variant="outlined"
                        size="small"
                        type="email"
                        value={email}
                        onChange={handleEmail}
                        fullWidth
                    />
                    <FormControlLabel 
                        control={<Checkbox color="info" onChange={handleUpdatesOptIn}/>} 
                        label="I want to receive occasional updates when new data becomes available." 
                        className={classes.input}
                    />
                    <p>We will never share or sell your email address with any additional parties for any reason, and we will never contact you with promotions or advertisements unless you opt in above.</p>
                </div>
                <Button
                    color="info"
                    onClick={() => { 
                        submit(email, updatesOptIn);
                        setEmail(null);
                        setUpdatesOptIn(false);
                    }}
                    disabled={!emailValid()}
                    className={classes.submitButton}
                >
                    <span className={classes.buttonLabel}>Confirm</span>
                </Button>
            </DialogContent>
        </Dialog>
    )
}