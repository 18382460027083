import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button";

import modalStyle from "assets/jss/material-kit-pro-react/modalStyle";

//@ts-ignore
const useStyles = makeStyles(modalStyle);

export const MetadataModal = (props) => {
    const {
        isOpen,
        close,
        title,
        metadata,
    } = props

    const classes = useStyles()

    return (
        <Dialog
            classes={{
                root: classes.modalRoot,
                paper: classes.modal
            }}
            open={isOpen}
            onClose={close}
        >
            <DialogTitle
                disableTypography
                className={classes.modalHeader}
            >
                <Button
                    simple
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    onClick={close}
                >
                    <Close className={classes.modalClose} />
                </Button>
                <h4 className={classes.modalTitle}>{title}</h4>
            </DialogTitle>
            <DialogContent
                className={`${classes.modalBody} ${classes.modalBodyFull}`}
            >
                <div>
                    {metadata?.map((item) => (
                        <ul><b>{item.name}:</b> {item.value}</ul>
                    ))}
                </div>
            </DialogContent>
        </Dialog>
    )
}