import { Card, Chip, Drawer } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles";
import { Check, Clear, Delete, ShoppingCart } from "@material-ui/icons";
import { infoColor, successColor } from "assets/jss/material-kit-pro-react";
import { DownloadItem } from '../../models/DownloadItem'

import { shoppingCartDrawerStyle } from "assets/jss/material-kit-pro-react/components/shoppingCartDrawerStyle";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DataType } from "models/enums/DataType";
import { FileType } from "models/enums/FileType";
import { setDrawerOpen, removeItem } from "redux/shoppingCart";
import Button from "components/CustomButtons/Button";
import { ROUTES } from "../../constants";
const useStyles = makeStyles(shoppingCartDrawerStyle)

const ShoppingCartDrawerItem = (props: { id: string, downloadItem: DownloadItem }) => {
    const {
        id,
        downloadItem
    } = props

    const classes = useStyles()
    const dispatch = useDispatch()

    const remove = () => {
        dispatch(removeItem(id))
    }

    return (
        <Card className={classes.item}>
            <div className={classes.itemContent}>
                <div>
                    <h3>{ downloadItem.county.replaceAll('_', ' ')}</h3>
                    <h4>{DataType[downloadItem.dataType][downloadItem.dataSubType].label}</h4>
                    <h4>{FileType[downloadItem.fileType].label}</h4>
                    <div>
                        {downloadItem.keys.map(key => (
                            <Chip key={key} className={classes.chip} size="small" label={key}/>
                        ))}
                    </div>
                </div>
                <button className={classes.iconButton} onClick={remove}>
                    <Delete fontSize='small'/>
                </button>
            </div>
            <Chip className={classes.price} label={`$${(downloadItem.getTotalPrice() / 100).toFixed(2)}`} color="info"/>
        </Card>
    )
}

export const ShoppingCartDrawer = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const shoppingCart = useSelector((state: any) => state.shoppingCart);
    const drawerOpen = shoppingCart?.drawerOpen
    const recentlyAdded = shoppingCart?.recentlyAdded
    const downloadItems = DownloadItem.hydrate(shoppingCart?.items)

    const close = () => {
        dispatch(setDrawerOpen(false))
    }

    return (
        <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={close}
        >
            <div className={classes.container}>
                <div className={classes.titleContainer}>
                    <div>
                        {
                            recentlyAdded 
                                ? (
                                    <>
                                        <Check htmlColor={successColor[0]}/>
                                        <h2>Added to Cart!</h2>
                                    </>
                                ) : (
                                    <>
                                        <ShoppingCart htmlColor={infoColor[1]}/>
                                        <h2>Shopping Cart</h2>
                                    </>
                                )
                        }
                    </div>
                    <div className={classes.spacer}/>
                    <button className={classes.iconButton} onClick={close}>
                        <Clear fontSize='medium'/>
                    </button>
                </div>
                <div className={classes.itemContainer}>
                    {Object.keys(downloadItems).map(id => (
                        <ShoppingCartDrawerItem key={id} id={id} downloadItem={downloadItems[id]}/>
                    ))}
                </div>
                <Button 
                    color="success" 
                    variant="contained"
                    className={classes.checkoutButton}
                    onClick={() => navigate(ROUTES.SHOPPING_CART.path)}
                >
                    <ShoppingCart />
                    View Cart
                </Button>
            </div>
        </Drawer>
    )
}