import { DataType } from "./enums/DataType"

export class DownloadItem {
    public readonly county: string
    public readonly dataType: string
    public readonly dataSubType: string
    public readonly fileType: string
    public readonly keys: string[]
    public readonly product: any

    constructor(dataType: string, dataSubType: string, county: string, fileType: string, keys: string[], product: any) {
        this.dataType = dataType
        this.dataSubType = dataSubType
        this.county = county
        this.fileType = fileType
        this.keys = keys
        this.product = product
    }

    static create(data): DownloadItem {
        return new DownloadItem(
            data.dataType,
            data.dataSubType,
            data.county,
            data.fileType,
            data.keys,
            data.product
        )
    }

    static hydrate(data): {[key: string]: DownloadItem} {
        const items: {[key: string]: DownloadItem} = {}
        Object.keys(data).forEach(uuid => items[uuid] = DownloadItem.create(data[uuid]))
        return items
    }

    getTotalPrice(promotionCode = null): number {
        let itemPrice = DataType[this.dataType].pricePerSelection
            ? this.product.default_price.unit_amount * this.keys.length
            : this.product.default_price.unit_amount;

        if (promotionCode?.coupon?.applies_to?.products?.includes?.(this.product.id)) {
            itemPrice -= promotionCode.coupon.amount_off ?? itemPrice * promotionCode.coupon.percent_off / 100;
        }

        return itemPrice;
    }

    matches(item: DownloadItem): boolean {
        const keyMatches = this.keys.filter(key => item.keys.includes(key))
        return this.county === item.county
            && this.dataType === item.dataType
            && this.dataSubType === item.dataSubType
            && this.fileType === item.fileType
            && this.keys.length === keyMatches.length
            && item.keys.length === keyMatches.length
    }
}