export const mapStyle = [
    {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'transit',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    { 
        featureType: 'administrative.land_parcel',
        elementType: 'geometry.stroke', 
        stylers: [
            {
                 visibility: 'off'
            }
        ] 
    },
]