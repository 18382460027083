/*eslint-disable*/
import React, { useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import MaterialButton from "@mui/material/Button";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
// core components
import Header from "components/Header/Header";
import Parallax from "components/Parallax/Parallax";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Footer from "components/Footer/Footer";
import Table from "components/Table/Table";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

import pageStyle from "assets/jss/material-kit-pro-react/views/parcelDataStyle";
import tableStyle from "assets/jss/material-kit-pro-react/components/tableStyle";
import { ROUTES } from "constants";
import { Link } from "react-router-dom";
import { useGetContourLinesCountyListQuery } from "redux/dataApi";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import ShoppingCart from "@mui/icons-material/ShoppingCart";
import { FileType } from "models/enums/FileType";
import { DataType } from "models/enums/DataType";
import { DownloadItem } from "models/DownloadItem";
import { useDispatch, useSelector } from "react-redux";
import { addItem } from '../../redux/shoppingCart';
import { PriceDropdownItem } from '../../components/CustomDropdown/PriceDropdownItem';
import { ShoppingCartDrawer } from "components/ShoppingCartDrawer/ShoppingCartDrawer";
import { isEmpty } from 'lodash';
import { ClipLoader } from "react-spinners";
import { infoColor } from "assets/jss/material-kit-pro-react";
import { MetadataModal } from "components/MetadataModal/MetadataModal";

const useStyles = makeStyles({...tableStyle, ...pageStyle});

export default function ContourLinesListPage() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [metadataModalProps, setMetadataModalProps] = useState({ isOpen: false })

  const {
    data: counties
  } = useGetContourLinesCountyListQuery();

  const getTableRows = () => {
    return counties.map((county, i) => [
      <div className={classes.labelContainer}>
            <span>{county.replaceAll('_', ' ')}</span>
            <MaterialButton
              color="info"
              variant="text"
              size="small"
              onClick={() => setMetadataModalProps({
                isOpen: true,
                close: () => setMetadataModalProps({ isOpen: false }),
                title: county.replaceAll('_', ' ') + ' Contours',
                metadata: [
                  { name: 'Author', value: 'GIS1, LLC' },
                  { name: 'Updated At', value: '2021-09-01' },
                  { name: 'Note', 'value': <em>This metadata is for testing purposes only</em> }
                ]
              })}
            >
              Metadata
            </MaterialButton>
          </div>,
      <div className={classes.actionButtonContainer}>
        <Link to={ROUTES.CONTOUR_LINES.MAP.path(county)} className={`${classes.actionButton} ${classes.link}`}>
          <Button className={classes.actionButton} color="info">
            Go To Map
            <KeyboardArrowRight />
          </Button>
        </Link>
      </div>
    ])
  }

  return (
    <div>
      <Header/>
      <Parallax/>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <Card plain>
            <CardBody plain>
              <h3 className={classes.cardTitle}>{ROUTES.CONTOUR_LINES.LIST.title}</h3>
              {counties ? (
                <Table
                  tableHead={[
                    'County',
                    '',
                    ''
                  ]}
                  tableData={getTableRows()}
                  tableShopping
                  customHeadCellClasses={[
                    classes.textRight,
                    classes.fit
                  ]}
                  customHeadClassesForCells={[1, 2]}
                  customCellClasses={[
                    classes.textRight,
                    classes.textRight
                  ]}
                  customClassesForCells={[1, 2]}
                />
              ) : (
                <div className={classes.loadingContainer}>
                  <ClipLoader
                    color={infoColor[0]}
                    loading={true}
                    size={50}
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </div>
      </div>
      <MetadataModal
        {...metadataModalProps}
      />
      <ShoppingCartDrawer/>
      <Footer/>
    </div>
  );
}
