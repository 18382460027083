import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { DownloadItem } from 'models/DownloadItem';

interface ShoppingCartState {
    drawerOpen: boolean,
    recentlyAdded: boolean,
    items: DownloadItem[],
    promotionCode: any
}

const initialState = {
    drawerOpen: false,
    recentlyAdded: false,
    items: {},
    promotionCode: null,
} as ShoppingCartState

export const shoppingCartSlice = createSlice({
    name: 'shoppingCart',
    initialState,
    reducers: {
        addItem: (state, action: PayloadAction<DownloadItem>) => {
            if (Object.values(current(state).items).filter(item =>
                DownloadItem.create(item).matches(action.payload)).length === 0
            ) {
                const uuid = uuidv4()
                state.items[uuid] = action.payload
                state.recentlyAdded = true
                state.drawerOpen = true
            }
        },
        removeItem: (state, action: PayloadAction<string>) => {
            state.recentlyAdded = false
            console.log('REMOVE ITEM: ' + action.payload)
            delete state.items[action.payload]
        },
        setDrawerOpen: (state, action: PayloadAction<boolean>) => { 
            state.drawerOpen = action.payload 
            state.recentlyAdded = true
        },
        setPromotionCode: (state, action) => {
            state.promotionCode = action.payload
        },
        clearShoppingCart: (state) => {
            return initialState
        }
    }
});

export const { 
    addItem,
    removeItem,
    setDrawerOpen,
    setPromotionCode,
    clearShoppingCart
} = shoppingCartSlice.actions;
export default shoppingCartSlice.reducer;