import React, { useEffect } from 'react';
import { Route, RouterProvider, Routes, createBrowserRouter, createRoutesFromElements, useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setDrawerOpen } from 'redux/shoppingCart'

import { ROUTES } from './constants';

import PresentationPage from "./views/PresentationPage/PresentationPage";
import ShoppingCartPage from "./views/ShoppingCartPage/ShoppingCartPage";
import MapPage from './views/MapPage/MapPage';
import ParcelDataMapPage from 'views/ParcelDataMapPage/ParcelDataMapPage';
import AddressPointsListPage from 'views/AddressPointsListPage/AddressPointsListPage';
import ContourLinesListPage from 'views/ContourLinesListPage/ContourLinesListPage';
import RoadCenterlinesListPage from 'views/RoadCenterlinesListPage/RoadCenterlinesListPage';
import ParcelDataCountiesListPage from 'views/ParcelDataListPage/ParcelDataListPage';
import { setSelectedFeatures } from 'redux/map';
import { PaymentSuccessPage } from 'views/PaymentSuccessPage/PaymentSuccessPage';
import { DownloadPage } from 'views/DownloadPage/DownloadPage';
import ContourLinesMapPage from 'views/ContourLinesMapPage/ContourLinesMapPage';
import CamaDataListPage from 'views/CamaDataListPage/CamaDataListPage';
import { withMiddleware } from 'utils/middleware/WithMiddleware';
import { JWT } from 'utils/middleware/JWT';
import { Receipt } from 'views/Receipt/Receipt';
import { Root } from 'Root';
import ErrorPage from 'views/ErrorPage/ErrorPage';

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements([
        <Route path="/" element={<Root/>} errorElement={<ErrorPage/>}>
          <Route path="/" element={<PresentationPage/>}/>
          <Route path={ROUTES.PARCEL_DATA.LIST.path} element={<ParcelDataCountiesListPage/>}/>
          <Route path={ROUTES.PARCEL_DATA.MAP.path()} element={<ParcelDataMapPage/>}/>
          <Route path={ROUTES.CAMA_DATA.LIST.path} element={<CamaDataListPage/>}/>
          <Route path={ROUTES.ADDRESS_POINTS.LIST.path} element={<AddressPointsListPage/>}/>
          <Route path={ROUTES.CONTOUR_LINES.LIST.path} element={<ContourLinesListPage/>}/>
          <Route path={ROUTES.CONTOUR_LINES.MAP.path()} element={<ContourLinesMapPage/>}/>
          <Route path={ROUTES.ROAD_CENTERLINES.LIST.path} element={<RoadCenterlinesListPage/>}/>
          <Route path={ROUTES.CONTOUR_LINES.LIST.path} element={<MapPage/>}/>
          <Route path={ROUTES.SHOPPING_CART.path} element={<ShoppingCartPage/>}/>
          <Route path={ROUTES.PAYMENT_SUCCESSFUL.path()} element={<PaymentSuccessPage/>}/>
          <Route 
            path={ROUTES.DOWNLOAD.path()} 
            element={withMiddleware(
              <DownloadPage/>,
              <></>,
              [ JWT ]
            )}
          />
          <Route 
            path={ROUTES.RECEIPT.path()} 
            element={withMiddleware(
              <Receipt/>,
              <></>,
              [ JWT ]
            )}
          />
        </Route>
      ]
    )
  )

  return (
    <RouterProvider router={router} />
  );
}

export default App;
