import * as React from "react"

export const Georgia = ({size = 85, stroke = 'white', fill = 'transparent', className = ''}) => (

<svg 
    xmlSpace="preserve"
    className={className}
    height={size}
    width={size * 692.7 / 799.2}
    viewBox="0 0 692.7 799.2"
    xmlns="http://www.w3.org/2000/svg"
>
<path
    style={{
        fill: fill
    }}
    d="M496.2,799.2c-0.2,0-0.4,0-0.6,0l-0.5,0c-0.5,0-1-0.1-1.5-0.2l-0.7-0.1c-1.4-0.3-2.7-0.9-3.9-1.8l-0.2-0.2
	c-0.6-0.5-1.2-1-1.7-1.6l-0.8-1c-0.5-0.4-1-0.8-1.4-1.2l-0.4-0.5c-0.8-0.9-1.4-1.8-1.9-2.9L481,786c-0.2-0.6-0.4-1.1-0.6-1.7
	l-0.9-3.8c-0.2-0.9-0.3-1.8-0.2-2.7l0.1-1.3c0-0.8,0.2-1.6,0.4-2.4c0-0.2,0-0.5-0.1-0.7l0-0.6c0-0.5,0-1.1,0.1-1.6
	c-0.3-0.3-0.6-0.7-0.8-1l-0.3-0.4c-0.4-0.5-0.7-1.1-0.9-1.7l-0.5-1.2c-0.4-0.9-0.6-1.8-0.7-2.8c-0.1-0.1-0.1-0.3-0.2-0.4l-35-2.5
	c-0.1,0-0.1,0-0.2,0l-2.7-0.2c-0.1,0-0.3,0-0.4,0l-0.3,0l-18.1-1.2c-0.1,0-0.2,0-0.3,0l-24.9-1.7c-0.1,0-0.1,0-0.2,0l-35.7-2.4
	c-0.1,0-0.2,0-0.2,0l-5.8-0.4c0,0-0.1,0-0.1,0l-19.1-1.3c-0.1,0-0.1,0-0.2,0l-0.2,0l-16.2-1.1c-0.1,0-0.1,0-0.2,0l-2.7-0.1
	c-0.1,0-0.2,0-0.2,0l-7.1-0.5l-15.7-0.9l-9.3-0.6l-18.8-1.1c-0.1,0-0.1,0-0.2,0l-1.4-0.1l-8-0.5c-0.1,0-0.2,0-0.3,0l-22.6-1.3
	c-0.1,0-0.3,0-0.4,0l-2-0.1l-7.1-0.3c-0.1,0-0.2,0-0.3,0l-2.3-0.2l-17.4-1l-4.3-0.2c0,0-0.1,0-0.1,0l-13.1-0.7l-13.9-0.5
	c-0.1,0-0.2,0-0.4,0l-9.1-0.6l-9.2-0.5c-0.1,0-0.2,0-0.3,0l-5.4-0.3l-26.9-1.4l-3.9-0.1c-3.8-0.1-7.1-2.3-8.7-5.7
	c-0.6-0.5-1.1-1.1-1.6-1.7c-0.2-0.1-0.4-0.2-0.6-0.3l-0.3-0.2c-1.8-0.9-3.2-2.4-4.1-4.2l-0.1-0.2c-0.3-0.6-0.6-1.3-0.8-2
	c-0.7-1.4-1.1-2.9-1.2-4.5l-0.2-0.4c-0.4-0.9-0.7-1.9-0.9-2.9l-0.1-0.7c0-0.1,0-0.3-0.1-0.4c-0.4-0.9-0.7-1.8-0.8-2.7l-0.1-0.6
	c-0.1-0.9-0.2-1.7-0.1-2.6l0.1-1.1c0-0.5,0.1-1,0.2-1.5c-0.1-0.5-0.2-1.1-0.2-1.6l0-0.4c0-0.2-0.1-0.3-0.1-0.5l-0.1-0.4
	c0-0.2-0.1-0.3-0.1-0.5c-0.3-0.4-0.5-0.9-0.7-1.3l-0.7-0.7c-0.6-0.6-1.1-1.3-1.5-2.1c-0.1-0.1-0.1-0.1-0.2-0.2l-0.4-0.3
	c-1.5-1.4-2.4-3.3-2.8-5.2l-0.1-0.4c-0.1-0.3-0.1-0.6-0.1-0.8c-0.3-0.3-0.7-0.7-0.9-1.1l-0.2-0.2c-0.7-0.9-1.2-2-1.6-3.1l-0.1-0.4
	c-0.3-0.9-0.4-1.9-0.4-2.9l0-0.5c0-1.1,0.2-2.1,0.5-3.2c0-0.3,0-0.5,0-0.8c-0.1-0.2-0.1-0.4-0.1-0.6c-0.5-1.2-0.8-2.4-0.9-3.7
	l-1.4-2.3c-0.9-1.5-1.3-3.2-1.4-4.9l-0.2-0.2c-1.2-1-2.2-2.3-2.8-3.8l-0.6-1.4c-0.5-0.3-1-0.6-1.5-0.9l-1.1-0.9
	c-2.1-1.5-3.4-3.8-3.9-6.4l0-0.2c0-0.1,0-0.2-0.1-0.4c-0.6-1.2-1-2.6-1.1-3.9l-0.1-1.5c0-0.7,0-1.3,0.1-2l0.2-1.2
	c0.1-0.8,0.3-1.6,0.6-2.4c0,0,0-0.1,0-0.1l-0.1-0.3c-0.3-0.5-0.5-1.1-0.7-1.6l-0.1-0.3c-0.2-0.6-0.3-1.1-0.4-1.7l-0.1-0.8
	c-0.1-0.6-0.1-1.1-0.1-1.7c-0.1-0.5-0.2-1-0.2-1.5l0-0.2c-0.1-1.9,0.4-3.8,1.3-5.5l0.3-0.4c0.6-0.9,1.3-1.8,2.1-2.5
	c-0.1-0.7-0.2-1.5-0.1-2.2l0,0c-0.3-1-0.5-2.1-0.5-3.2l0-0.9c0-0.5,0.1-0.9,0.1-1.4l0-0.3c0.1-0.9,0.4-1.7,0.8-2.5l0.8-1.9l0.1-0.4
	c-0.1-0.7-0.1-1.5,0-2.2l0-0.2c0.1-1,0.4-2.1,0.8-3c0,0,0-0.1,0-0.1l0.2-0.6c0.1-0.4,0.3-0.9,0.5-1.3l-0.3-2c-0.2-1.5-0.1-3,0.4-4.4
	l0.8-2.6c0.3-0.8,0.6-1.6,1.1-2.3c0.1-0.3,0.3-0.7,0.4-1l0.5-1.1c0-0.4,0.1-0.8,0.1-1.3l0,0c-0.1-0.3-0.2-0.6-0.3-0.9l0,0
	c-0.9-1.7-1.3-3.6-1.2-5.5c-0.1-0.6-0.1-1.1-0.1-1.7l0-1c0-0.1-0.1-0.1-0.1-0.2c-1.4-1.2-2.5-2.8-3.1-4.5l-0.1-0.3
	c-0.1-0.4-0.2-0.8-0.3-1.3l-1.3-1.6c-0.4-0.4-0.7-0.9-0.9-1.4c-0.4-0.4-0.9-0.9-1.2-1.4l-0.5-0.6c-1.1-1.5-1.8-3.3-1.9-5.1l-0.2-2.3
	c0-0.4,0-0.7,0-1.1l0-1.3c0-0.9,0.2-1.8,0.4-2.6l-0.2-0.7c-0.1-0.3-0.2-0.7-0.3-1c-0.2-0.3-0.4-0.6-0.6-0.9l-0.2-0.4
	c-1.7-3-1.7-6.6-0.1-9.6l-0.4-0.8c-0.8-1.9-1-4-0.6-6c-0.2-1.6,0.1-3.2,0.6-4.7l0.1-0.2c0.1-0.2,0.1-0.3,0.2-0.5
	c-0.2-1.2-0.1-2.4,0.2-3.6l0-0.1c0.5-2,1.6-3.8,3.1-5.2l0.2-0.1c0.2-0.2,0.5-0.4,0.7-0.6c0.6-1,1.3-1.9,2.2-2.6
	c0.1-0.1,0.2-0.3,0.3-0.4c0-0.1,0-0.2,0-0.3l0-0.2c0.1-1.4,0.5-2.8,1.1-4.1l0.1-0.3c0.4-0.7,0.9-1.4,1.4-2c0.1-0.6,0.2-1.2,0.4-1.8
	l0.5-1.5c0.2-0.5,0.4-1.1,0.7-1.6c0,0,0-0.1,0-0.1l-0.1-0.3c-0.4-1.5-0.3-3.1,0-4.6c0-0.5,0.1-1.1,0.2-1.6c-0.1-1.3,0.1-2.7,0.5-3.9
	c0-0.2-0.1-0.3-0.1-0.5l-0.1-0.4l-0.3-1c-0.4-0.8-0.6-1.6-0.7-2.5l-0.1-0.5c-0.3-2,0-4.1,0.9-5.9l0.1-0.3c0.5-1,1.2-2,2-2.8l0.3-0.3
	c0.5-0.4,0.9-0.8,1.5-1.2l0.3-0.2l0.5-0.7c0.2-0.3,0.4-0.6,0.6-0.9l0.5-0.7c0.2-0.3,0.5-0.7,0.8-1l1.1-1.1c0.4-0.6,0.9-1.1,1.4-1.6
	l0.3-0.2c0.5-0.4,1-0.8,1.5-1.1l0.4-0.2c0.8-0.5,1.6-0.9,2.5-1.1l0.2,0c0-0.1,0.1-0.1,0.1-0.2l0.1-0.1c0.3-0.4,0.5-0.7,0.8-1.1
	l0.2-0.2c0.4-0.6,0.9-1.2,1.5-1.7l0.1-0.1l-0.9-0.4l-1-0.3c-2.3-0.6-4.3-2-5.7-4l-0.7-1.1c-1-1.4-1.6-3-1.7-4.7l-0.1-1.4
	c-0.1-1.5,0-2.9,0.5-4.3l0.5-1.4c0.5-1.6,1.5-2.9,2.7-4.1l0.2-0.2c0-0.1,0-0.2,0-0.3c-0.2-1.1-0.2-2.2-0.1-3.3l0.1-0.6
	c-0.2-0.2-0.3-0.5-0.5-0.7l-0.3-0.4c-0.5-0.8-0.9-1.7-1.1-2.6l-0.4-1.4c-0.2-0.8-0.3-1.6-0.3-2.4l0-1.4c0-0.7,0.1-1.5,0.2-2.2
	c0-0.2-0.1-0.4-0.1-0.6l-0.1-0.5c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.3-0.3l-0.3-0.4c-0.2-0.3-0.4-0.5-0.6-0.8L84,434
	c-0.2-0.4-0.4-0.8-0.6-1.2l-4.3-4.1c-0.9-0.8-1.6-1.8-2.2-2.9l-1.3-2.5c-0.2-0.3-0.3-0.6-0.4-1l-0.5-1.5c-0.2-0.5-0.3-1.1-0.4-1.6
	c-0.3-0.5-0.5-1-0.6-1.5l-0.1-0.4c0-0.1-0.1-0.2-0.1-0.3c-0.5-0.8-0.9-1.6-1.2-2.5l-0.2-0.8c-0.4-1.2-0.5-2.4-0.4-3.7l-0.1-0.2
	c-0.8-1-1.4-2.1-1.8-3.4l-0.3-1.1c-0.1-0.3-0.2-0.6-0.2-0.9l-0.8-4c-0.1-0.6-0.2-1.2-0.2-1.8c-0.5-0.6-0.9-1.3-1.2-2.1l-0.3-0.7
	c-0.4-0.9-0.6-1.8-0.7-2.7l-0.1-1c0-0.3-0.1-0.6-0.1-0.9l-0.9-1.1c-0.3-0.3-0.5-0.6-0.7-1l-0.8-1.2c-0.7-1-1.1-2.1-1.4-3.3l-0.1-0.4
	c-0.2-0.8-0.3-1.6-0.2-2.4l0-1.4l-0.4-0.6c-0.8-1.1-1.3-2.4-1.6-3.8l-0.1-0.3c-0.1-0.6-0.2-1.3-0.2-1.9l0-0.5l-0.4-2.2l-6.2-36.8
	l-10.2-63.4l-1.3-7.8c0-0.2-0.1-0.3-0.1-0.5l-0.1-0.8l-6-36.2c0-0.1,0-0.1,0-0.2l-0.6-3.6c0-0.1,0-0.2-0.1-0.3l-2.2-14l-3.9-23.8
	c-0.1-0.3-0.2-0.7-0.2-1.1l-3-19.1c0-0.2-0.1-0.4-0.1-0.6l-0.1-1.5l-3.6-21.1c0-0.2-0.1-0.3-0.1-0.5l0-0.1l-0.3-1.9
	c0-0.1,0-0.2-0.1-0.3l-0.1-0.5l-1.6-9.9l-4.5-24.8c0,0,0-0.1,0-0.2l-1.5-8.1c0-0.1-0.1-0.3-0.1-0.4l0-0.2c0-0.1,0-0.2,0-0.3
	l-0.4-2.8l-1.4-7.7c0-0.1,0-0.1,0-0.2l-1-6.1l-1-5.3c0-0.1,0-0.1,0-0.2l-1.5-8.6c0-0.1,0-0.1,0-0.2l-1.2-7.1c0,0,0-0.1,0-0.1
	l-3.1-19L1.5,24.7c0,0,0-0.1,0-0.1L1,21c0-0.2-0.1-0.5-0.1-0.7l-0.1-0.8l-0.8-5.3c-0.4-2.9,0.5-5.8,2.4-8C4.4,4,7.2,2.7,10.2,2.8
	l19.5,0.3l11.3,0l10.9-0.2c0.1,0,0.3,0,0.4,0l1.2,0L56,2.7c0.1,0,0.2,0,0.2,0l3.1,0L62,2.6c0.1,0,0.1,0,0.2,0h1.5c0,0,0.1,0,0.1,0
	l0.4,0c0.2,0,0.3,0,0.5,0h0.1l5-0.1c0,0,0.1,0,0.1,0L89,2.4c0.1,0,0.1,0,0.2,0l14.7-0.2c0,0,0.1,0,0.1,0l11,0c0,0,0.1,0,0.1,0l0.3,0
	l3.6,0c0.2,0,0.4,0,0.6,0l0.5,0c0.4,0,0.8,0,1.2,0l1,0c0,0,0,0,0,0l10.9-0.1c0.1,0,0.2,0,0.3,0l0.4,0l13.7,0c0.4,0,0.8-0.1,1.1-0.1
	l15.6,0.1c0.1,0,0.2,0,0.3,0l15.9,0c0.1,0,0.2,0,0.3,0l10.2-0.1c0,0,0.1,0,0.1,0l27.2,0.2l13.9,0l1.1,0c0,0,0.1,0,0.1,0l12,0l14.9,0
	l11.4-0.1c0.1,0,0.1,0,0.2,0l14.1,0.1c1.3-0.6,2.7-0.9,4.1-0.9l9.9-0.1L332,0.8l18.6-0.6c0,0,0.1,0,0.1,0L362.3,0
	c3.7-0.1,7.1,1.9,8.9,5.1l0.4,0.8c0.5,0.9,0.8,1.8,1,2.8l0.1,0.6c0.1,0.6,0.2,1.2,0.2,1.8l0,0.6c0,1.1-0.1,2.2-0.4,3.2l-0.4,1.1
	c-0.3,1-0.8,1.9-1.4,2.8c0,0.1-0.1,0.2-0.2,0.3c0.1,1.1,0.1,2.3-0.2,3.4l-0.1,0.4c-0.5,2-1.6,3.8-3.1,5.2l-0.1,0.1
	c-0.6,0.7-1.2,1.4-2,2l-1.4,1c-1.1,0.8-2.3,1.4-3.7,1.7c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.2,0.2-0.2,0.3l-0.8,0.9
	c-0.2,0.3-0.5,0.5-0.8,0.8c-0.3,0.5-0.6,0.9-0.9,1.4l-0.6,0.8c-1.4,1.7-3.4,2.9-5.5,3.4c-0.3,0.8-0.8,1.5-1.3,2.2
	c-0.3,1.7-1.1,3.3-2.2,4.7l-0.4,0.5c-1,1.2-2.2,2.1-3.6,2.7l-0.6,0.3c-0.1,0-0.2,0.1-0.3,0.1c0.1,2.6-0.7,5.2-2.5,7.2l-0.2,0.2
	c0.9,0.5,1.7,1.2,2.4,2l0.4,0.5c0.4,0.5,0.8,1,1.1,1.6c0.3,0.1,0.7,0.2,1,0.4l1,0.4c1.2,0.5,2.2,1.3,3.1,2.2l1,1
	c0.3,0.3,0.5,0.6,0.8,0.9l0.6,0.2c0.9-0.1,1.9-0.1,2.8,0.1l0.8,0.1c1.2,0.2,2.4,0.7,3.5,1.3l0.5,0.3c1.7,1,3,2.5,3.8,4.2
	c1,0.9,1.8,2,2.3,3.3l0,0c1.2,0.7,2.3,1.6,3.2,2.7l1.7,2.1c1.2,0.6,2.3,1.4,3.2,2.4l1,1.1c0.6,0.7,1.1,1.4,1.5,2.2
	c0.9,0.6,1.7,1.3,2.3,2.1c1-0.3,2.1-0.4,3.2-0.4l1.5,0.1c1.7-0.6,3.5-0.8,5.4-0.5l0.8,0.1c0.3-0.1,0.6-0.1,0.9-0.1l0.8-0.1
	c1.7-0.2,3.4,0.1,5,0.7l2.8,1.2c0.7,0.3,1.4,0.7,2.1,1.2l0.8,0.6c0.9,0.7,1.7,1.5,2.4,2.5l0.4,0.6c0.1,0.2,0.3,0.4,0.4,0.6
	c0.1,0.1,0.2,0.2,0.3,0.3l0.2,0.2c1.3,1.4,2.1,3.1,2.4,5c0.4,1,0.7,2,0.8,3c0.4,0.7,0.6,1.5,0.8,2.3l0.1,0.6
	c0.1,0.5,0.2,1.1,0.2,1.7l0.2,0.6c0.1,0.4,0.3,0.8,0.4,1.2l0.1,0.4c0.1,0.3,0.1,0.6,0.2,0.9c0.2,0.4,0.3,0.9,0.4,1.3l0.3,0.2
	c0.1,0.1,0.3,0.2,0.4,0.4l0.3,0.3c1.1,0.6,2.1,1.4,2.9,2.4l0.2,0.2c0.9,1.1,1.5,2.4,1.9,3.7l0.4,1.5c0.2,0.8,0.3,1.6,0.3,2.4
	l0.1,0.1c0.2,0.3,0.3,0.5,0.4,0.8c0.2,0.1,0.3,0.3,0.5,0.5l1,0.9c0.3,0.3,0.6,0.6,0.9,0.9l0.7,0.9c0.3,0.4,0.6,0.8,0.9,1.2l0.5,0.8
	c0.9,1.5,1.3,3.1,1.4,4.8c0.3,1.2,0.4,2.5,0.3,3.7l0,0l0.2,1c0.1,0.5,0.1,1,0.2,1.5c0.1,0.6,0.2,1.1,0.2,1.7
	c0.3,0.5,0.5,1.1,0.7,1.7l0.1,0.4c0.3,0.9,0.4,1.7,0.4,2.6c0,0,0,0.1,0.1,0.1l0.2,0.5c0.6,0.3,1.2,0.7,1.7,1.1
	c1.2,0.5,2.2,1.3,3.1,2.2l0.3,0.3c0.5,0.5,0.9,1,1.2,1.6l1,1.6c0.2,0.4,0.4,0.7,0.6,1.1c0.1,0.1,0.1,0.1,0.2,0.2l0.2,0.1
	c0.5,0.4,0.9,0.8,1.3,1.3l0.8,1c0.8,1,1.4,2.1,1.8,3.3l0.1,0.2c0.1,0.4,0.2,0.7,0.3,1.1c0,0.2,0.1,0.3,0.1,0.5l0.5,0.6
	c0.5,0.6,0.9,1.3,1.3,2c0.3,0.4,0.6,0.8,0.8,1.2c0.7,0.6,1.3,1.2,1.9,1.9l0.2,0.3c1,1.3,1.6,2.8,1.8,4.4l0.1,0.5c0,0.1,0,0.2,0,0.4
	c0.5,0.6,1,1.3,1.4,2l0.1,0.2c0.6,1.1,0.9,2.2,1.1,3.4v0c1,0.3,2,0.7,2.8,1.3l1.6,1.1c0.9,0.6,1.6,1.3,2.3,2.1l0.7,0.9
	c0.2,0.3,0.5,0.6,0.7,1l0.6,0.9c0.2,0.3,0.3,0.6,0.5,0.9l0.3,0.5c0.5,0.4,0.9,0.8,1.4,1.2l0.8,0.9l0.7,0.6c0.4,0.3,0.8,0.6,1.1,1
	c0.3,0.2,0.6,0.3,0.9,0.5c0.5,0,1,0.1,1.4,0.2l0.6,0.1c1.4,0.3,2.7,0.9,3.9,1.8l0.5,0.4c0.2,0.2,0.4,0.3,0.6,0.5l0.6,0.4
	c0.3,0.2,0.7,0.4,1,0.7l0.7,0.6c0.4,0.3,0.7,0.6,1,0.9l0.6,0.3c0.3,0.1,0.5,0.2,0.8,0.4l0.7,0.4c0.8,0.4,1.6,1,2.3,1.6l0.6,0.6
	l1.1,0.7c0.7,0.4,1.3,0.9,1.9,1.5l1.4,1.5c0,0,0.1,0.1,0.1,0.1c0.5,0.4,1,0.8,1.5,1.2l0.4,0.4c0.8,0.8,1.4,1.8,1.9,2.8l0.1,0.3
	c0.1,0.2,0.2,0.5,0.3,0.7l0.1,0.1c0.2,0.1,0.4,0.2,0.6,0.4l0.2,0.2c0.4,0.3,0.9,0.6,1.2,1c0.7,0.5,1.4,1,2,1.7l1.1,1.2
	c1.3,1.4,2.1,3.1,2.5,5l0.4,0.9c0.2,0.4,0.3,0.8,0.4,1.3l0.2,0.7c0.1,0.3,0.2,0.6,0.2,0.9l0.4,2.5c0.2,0.2,0.3,0.4,0.5,0.6l0.3,0.3
	c0.3,0.4,0.6,0.8,0.8,1.2l0.7,1.2c0,0,0,0.1,0.1,0.1c1.2,1.3,2.1,2.8,2.5,4.5l0.1,0.5c0.2,0.7,0.3,1.4,0.3,2.2
	c0.4,0.2,0.8,0.4,1.2,0.7l0.3,0.2c0.2,0.2,0.5,0.3,0.7,0.5l0.4,0c1.2,0,2.4,0.2,3.6,0.6l0.4,0.2c1.6,0.6,3.1,1.6,4.2,2.9
	c0.8,0.3,1.6,0.6,2.4,1.1l0.6,0.4c0.5,0.3,0.9,0.6,1.3,1c0.2,0.1,0.3,0.1,0.5,0.2l0.4,0.2c1.3,0.6,2.5,1.6,3.5,2.7l1.3,1.6
	c0.3,0.3,0.5,0.7,0.7,1c0,0,0,0,0.1,0.1l0.7,0.7c0.1,0.1,0.2,0.2,0.3,0.3c0.5,0.4,0.9,0.8,1.4,1.2l0.2,0.2c0.6,0.6,1.1,1.3,1.5,2.1
	c0.6,0.6,1,1.4,1.4,2.1l1.3,2.7c0,0,0,0,0,0.1l1.5,0.8l0.2,0.1c1,0.3,1.9,0.7,2.8,1.2l0.7,0.4c0.8,0.5,1.5,1.1,2.1,1.8l0.4,0.5
	c0.7,0.8,1.3,1.8,1.8,2.8l0.5,1.3l0.7,1.3c0.8,1.4,1.2,2.9,1.3,4.5l0,0.7c0.1,1.5-0.2,3-0.8,4.4c0,1.1-0.1,2.1-0.5,3.1l-0.1,0.4
	c-0.1,0.4-0.3,0.7-0.4,1.1c0.1,0.6,0.2,1.2,0.2,1.8l0.1,0.1c0.3,0.3,0.6,0.5,0.9,0.8c0,0,0,0,0,0l2,0.6c0.9,0.3,1.8,0.7,2.6,1.2
	l0.9,0.6c1.9,1.2,3.2,3.1,3.9,5.2l0.2,0.5c0.4,1.2,0.6,2.5,0.5,3.7c0.5,1,0.9,2.2,1.1,3.4c0.1,0,0.3,0,0.4,0.1l0.6,0.1
	c1.7,0.3,3.3,1.1,4.7,2.3l0.7,0.6c0.7,0.6,1.4,1.4,1.9,2.2l0.2,0.3c0.4,0.7,0.8,1.4,1.1,2.2c0.4,0.5,0.7,1.1,0.9,1.7l0.2,0.4
	c1,2.2,1.2,4.6,0.5,6.9l0.9,0.7c0.3,0.2,0.5,0.4,0.7,0.6l0,0c0.1,0,0.3,0.1,0.4,0.1l1.9,0.7c0.3,0.1,0.6,0.2,0.8,0.3l3.5,1.6
	c0.3,0.1,0.6,0.3,0.9,0.5c0.6,0,1.2,0,1.8,0l0.4,0c1.3,0.1,2.5,0.5,3.6,1.1l0.3,0.2c0.6,0.3,1.1,0.7,1.6,1.1l0.3,0.2
	c0.5,0.4,0.9,0.9,1.3,1.3c1,0.8,1.8,1.7,2.4,2.7l0.2,0.3l0.3,0.2c0.3,0.1,0.7,0.3,1,0.5l0.1,0.1c0.6,0.3,1.2,0.7,1.7,1.1
	c1.3,0.6,2.5,1.4,3.5,2.5l0.3,0.3c0.3,0.1,0.5,0.2,0.8,0.3l0.3,0.1c1.8,0.8,3.3,2.2,4.4,3.9l0.2,0.4c1.1,0.8,2,1.9,2.7,3.1l0.1,0.2
	c0.8,1.4,1.2,3,1.2,4.6l0,1.7c0,0.4,0,0.7,0,1.1l0,0.4c0,0.3-0.1,0.5-0.1,0.8l-0.5,2.8c-0.1,0.4-0.1,0.7-0.2,1l-0.2,0.7
	c0.7,0.8,1.2,1.7,1.5,2.7c0.4,0.4,0.8,0.8,1.1,1.2l0.3,0.3c1,1.4,1.7,3,1.9,4.7c0.6,0.8,1,1.8,1.3,2.7l0,0.2
	c0.8,0.6,1.6,1.3,2.2,2.2l0.7,0.9c0.8,1.1,1.4,2.5,1.7,3.8l0.1,0.7c0,0.1,0.1,0.3,0.1,0.4l0.4,2.2c0.2,1.1,0.2,2.2,0,3.4l-0.1,0.4
	c-0.1,0.7-0.3,1.4-0.6,2c0.1,0.5,0.2,1,0.3,1.6l0,0.2c0,0.1,0,0.1,0,0.2l0.1,0.2c0.2,0.6,0.4,1.2,0.6,1.7c0.3,0.7,0.6,1.5,0.7,2.3
	l0,0.2c0.2,1.2,0.2,2.4,0,3.5l-0.1,0.3c-0.1,0.7-0.3,1.3-0.5,2c0.1,0.2,0.2,0.4,0.3,0.6l1,2.5c0.4,1,0.6,2,0.7,3l0,0.4
	c0,0.1,0,0.2,0,0.3c0.2,0.4,0.4,0.8,0.5,1.2l0.1,0.3c0.6,1.8,0.7,3.8,0.2,5.7c0.8,0.7,1.6,1.6,2.1,2.5c0.1,0,0.3,0,0.4,0l0.4,0
	c1.5-0.1,2.9,0.1,4.3,0.6l0.2,0.1c0.3,0.1,0.5,0.2,0.8,0.3l2.2,1c1.1,0.5,2.1,1.3,3,2.2l0.3,0.3c0.9,1,1.6,2.2,2.1,3.4l0.1,0.3
	c0.3,0.1,0.7,0.3,1,0.4l0.3,0.2c2.7,1.4,4.6,3.9,5.2,6.8l0.1,0.4c0,0.2,0.1,0.4,0.1,0.6l0.3,0.3c1.5,0.6,2.9,1.6,4,2.8l0.2,0.2
	c2.3,2.7,3,6.4,1.8,9.7l-0.1,0.2c-0.2,0.5-0.4,1.1-0.7,1.6l0.4,0.6c0,0.1,0.1,0.1,0.1,0.2l0.5,0.8c0.7,1.1,1.2,2.3,1.4,3.6
	c0.9,0.5,1.8,1.2,2.5,2l0.2,0.3c0.2,0.2,0.4,0.4,0.5,0.6l1,1.4c1.2,1.5,1.9,3.4,2,5.3l0.1,1.1c0,0.1,0,0.1,0,0.2l0,0
	c0.5,0.8,0.8,1.7,1.1,2.6l0.1,0.3c0.1,0.4,0.2,0.8,0.3,1.3l0.4,2.6c0,0.2,0,0.3,0.1,0.5l0,0.3c0.2,1.8-0.2,3.6-1,5.2l-0.3,0.5
	c-0.2,0.4-0.4,0.8-0.7,1.2l-0.9,1.4c0,0,0,0,0,0c0.1,0.2,0.2,0.3,0.3,0.5l0.7,1.2c0.4,0.6,0.6,1.2,0.9,1.9l0.7,2
	c0.3,0.8,0.5,1.7,0.5,2.6l0,0.7c0.1,0.9,0,1.8-0.2,2.7c0,0.6-0.1,1.1-0.2,1.6c0,0,0,0,0,0l0.1,0.6c0.3,0.2,0.6,0.5,0.9,0.8
	c0.7-0.3,1.4-0.6,2.2-0.8l0.7-0.2c1.6-0.4,3.4-0.3,5,0.2l0.6,0.2c0.6,0.2,1.1,0.4,1.6,0.6l0.3,0.2c0.9,0.4,1.7,1,2.5,1.7l0.8,0.7
	c0.6,0.5,1.1,1.1,1.5,1.8l0,0c0.7,0.2,1.3,0.6,1.9,0.9l0.7,0.4c0.8,0.5,1.5,1.1,2.1,1.8l1.6,1.8c0.1,0.1,0.2,0.3,0.3,0.4l0.1,0.1
	l0.1,0l0.7,0c1.3,0.1,2.6,0.4,3.8,1l0.6,0.3c0.9,0,1.8,0.2,2.7,0.5l1.3,0.4c3.6,1.1,6.2,4.2,6.8,7.9l0.4,2.2c0.2,1,0.2,2,0.1,3
	l-0.2,1.4c-0.1,0.8-0.3,1.5-0.6,2.3l-0.9,2.4c-0.2,0.6-0.6,1.2-0.9,1.8l-2,3.2c-0.8,1.3-1.9,2.4-3.2,3.1l-2.3,1.4l-1.6,1.3
	c-0.5,0.4-1.1,0.8-1.7,1.1c-0.4,1.5-1.1,2.9-2.1,4.1l-1.8,2.2c-0.3,0.4-0.7,0.7-1,1l-3,2.7l-2.6,2.9c-1.1,1.2-2.4,2.1-3.9,2.7
	c-0.3,1.8-1.2,3.5-2.4,4.8l-0.6,0.7l-2.3,4.5c-0.5,0.9-1.1,1.8-1.9,2.5l-1.3,1.2c-0.8,0.8-1.7,1.4-2.8,1.9l-1.1,0.5l-0.8,0.8
	c0.1,1.1-0.1,2.3-0.4,3.4l-0.1,0.4l0.3,1.6c0.2,0.8,0.2,1.6,0.2,2.4l-0.2,5.2c-0.1,1.3-0.4,2.6-0.9,3.8l-2.2,4.6l-1.3,3.5
	c-0.3,0.9-0.8,1.7-1.3,2.4l-0.6,0.8l0.1,0.4c0.5,2.8-0.3,5.6-2,7.8l-0.9,1.2l-1.6,3.8c-0.3,0.7-0.6,1.3-1.1,1.9l-5.6,7.8l-1.2,2.2
	l-1.4,3.1c3,2.9,3.9,7.4,2.1,11.3l-0.9,1.9c-0.3,0.7-0.7,1.4-1.2,2l-0.1,2.5c-0.1,1.3-0.4,2.7-1,3.9l-0.4,0.9l-0.2,0.7l0,0.8
	c0.1,2.6-0.8,5.2-2.5,7.1l-0.7,0.8c-1.7,1.9-3.9,3-6.4,3.3l-0.4,0.6c-0.2,1.4-0.6,2.8-1.4,4l-1.3,2.1c-0.8,1.3-1.9,2.5-3.2,3.3
	l0,2.1c0,0.8-0.1,1.7-0.3,2.5l-2,7.6c0,0.2-0.1,0.3-0.1,0.5c0.5,0.9,0.9,1.9,1.2,2.9l0.7,3.3c0.2,0.8,0.3,1.6,0.2,2.4l-0.2,8.2
	c0,0.8-0.1,1.6-0.4,2.4l-3.3,11.8c-0.1,0.3-0.2,0.5-0.3,0.8l-0.3,0.9c-0.4,1-0.9,2-1.6,2.8l-0.7,0.9l-0.2,0.3l-0.7,1.8l-0.4,2
	l-0.1,1.1l0,0.1l0.6,1.8c2,2.8,2.3,6.5,0.9,9.7c-1.5,3.3-4.6,5.6-8.2,5.9l-0.6,0.1c-0.7,0.1-1.4,0.1-2,0l-1.6-0.2
	c-0.2,0-0.3,0-0.5-0.1l-0.6-0.1c-0.5-0.1-1-0.2-1.5-0.4l-1.1-0.4c-0.5-0.2-1.1-0.4-1.6-0.7l-0.1,0c-1.2,0.6-2.5,1-3.8,1.1l-0.2,0
	c-0.8,0.1-1.7,0-2.5-0.1l-0.2,0c-0.2,0-0.3-0.1-0.5-0.1l-0.4-0.1c0,0-0.1,0-0.1,0c-1.6,0-3.2-0.4-4.6-1.1c-1.3,0.3-2.7,0.3-4.1,0.1
	l-1.2-0.2c-0.8-0.2-1.6-0.4-2.3-0.7c-1.3,0-2.5-0.3-3.7-0.8l-0.4-0.2c-0.8-0.3-1.5-0.7-2.1-1.2l-1.4-0.2c-0.5-0.1-1.1-0.2-1.6-0.4
	c-1.5,0.2-3.1,0-4.5-0.6l-1-0.4c-0.6-0.2-1.2-0.5-1.8-0.9l-0.7-0.4c-0.7-0.4-1.4-1-1.9-1.6c-1.7-0.1-3.3-0.6-4.7-1.5l-0.5-0.3
	c-0.6-0.4-1.1-0.8-1.6-1.3c-2,0.2-3.9-0.1-5.7-1c-2.2-0.1-4.2-0.9-5.9-2.3c-1,1.6-2.5,2.9-4.3,3.7l-0.7,0.3
	c-0.6,0.3-1.2,0.5-1.9,0.7c-0.2,0.2-0.3,0.4-0.5,0.6l0,0.4c0.1,1.3,0,2.6-0.5,3.9l-0.8,2.3c-0.1,0.2-0.1,0.3-0.2,0.5l0.3,0.9
	c0.1,0.4,0.3,0.8,0.4,1.2l0.4,0.7c0.4,0.7,0.7,1.4,0.9,2.1c0.7,0.8,1.2,1.7,1.6,2.7l0.5,1.3c0.2,0.5,0.4,1.1,0.5,1.6l0.6,2.7
	c0,0.1,0,0.3,0.1,0.4l0.4,2.2c0.3,2.1,0,4.2-0.9,6.1l-0.6,1.2c0,0,0,0,0,0.1l0.1,0.8c0.1,0.8,0.2,1.5,0.1,2.3l-0.2,2.9
	c0,0.4-0.1,0.9-0.2,1.3l0,0.6c0,0.7,0,1.4-0.2,2.1l-0.1,0.6c-0.2,1.1-0.6,2.1-1.1,3.1c0,0,0,0.1,0,0.1l-0.4,1.7
	c-0.1,0.6-0.3,1.2-0.6,1.7c0,1.2-0.2,2.4-0.6,3.6c0.3,1.2,0.4,2.5,0.3,3.7l-0.1,1.2c-0.1,1-0.4,2-0.8,2.9l-0.6,1.3
	c-0.4,1-1,1.9-1.7,2.7l-1,1.1c-0.8,0.9-1.9,1.7-3,2.3l-0.3,0.2c-0.7,0.3-1.4,0.6-2.2,0.8l-1.4,0.3c-0.2,0.1-0.5,0.1-0.7,0.1
	l-1.2,0.2c-0.4,0.1-0.8,0.1-1.2,0.1l-1.8,0.1c-0.7,0-1.3,0-2-0.1l-1.9-0.3c-0.8-0.1-1.5-0.3-2.2-0.7l-1.1,0.2
	C499.2,798.8,497.7,799.2,496.2,799.2z"
/>
<path 
    style={{
        fill: stroke
    }}
    d="M692.6,514.6l-0.4-2.2c-0.6-3.7-3.3-6.7-6.8-7.9l-1.3-0.4c-0.9-0.3-1.8-0.4-2.7-0.5l-0.6-0.3c-1.2-0.6-2.5-0.9-3.8-1l-0.7,0
	l-0.1,0l-0.1-0.1c-0.1-0.1-0.2-0.3-0.3-0.4l-1.6-1.8c-0.6-0.7-1.3-1.3-2.1-1.8l-0.7-0.4c-0.6-0.4-1.2-0.7-1.9-0.9l0,0
	c-0.4-0.6-0.9-1.2-1.5-1.8l-0.8-0.7c-0.7-0.7-1.6-1.3-2.5-1.7l-0.3-0.2c-0.5-0.3-1.1-0.5-1.6-0.6l-0.6-0.2c-1.6-0.5-3.3-0.5-5-0.2
	l-0.7,0.2c-0.8,0.2-1.5,0.4-2.2,0.8c-0.3-0.3-0.6-0.5-0.9-0.8l-0.1-0.6c0,0,0,0,0,0c0.1-0.5,0.2-1.1,0.2-1.6
	c0.2-0.9,0.3-1.8,0.2-2.7l0-0.7c-0.1-0.9-0.2-1.8-0.5-2.6l-0.7-2c-0.2-0.7-0.5-1.3-0.9-1.9l-0.7-1.2c-0.1-0.2-0.2-0.3-0.3-0.5
	c0,0,0,0,0,0l0.9-1.4c0.3-0.4,0.5-0.8,0.7-1.2l0.3-0.5c0.8-1.6,1.1-3.4,1-5.2l0-0.3c0-0.2,0-0.3-0.1-0.5l-0.4-2.6
	c-0.1-0.4-0.1-0.8-0.3-1.3l-0.1-0.3c-0.2-0.9-0.6-1.8-1.1-2.6l0,0c0-0.1,0-0.1,0-0.2l-0.1-1.1c-0.2-1.9-0.8-3.7-2-5.3l-1-1.4
	c-0.2-0.2-0.3-0.4-0.5-0.6l-0.2-0.3c-0.7-0.8-1.6-1.5-2.5-2c-0.2-1.3-0.7-2.5-1.4-3.6l-0.5-0.8c0-0.1-0.1-0.1-0.1-0.2l-0.4-0.6
	c0.3-0.5,0.5-1,0.7-1.6l0.1-0.2c1.2-3.3,0.5-7-1.8-9.7l-0.2-0.2c-1.1-1.3-2.4-2.2-4-2.8l-0.3-0.3c0-0.2-0.1-0.4-0.1-0.6l-0.1-0.4
	c-0.6-2.9-2.6-5.4-5.2-6.8l-0.3-0.2c-0.3-0.2-0.6-0.3-1-0.4l-0.1-0.3c-0.5-1.3-1.2-2.5-2.1-3.4l-0.3-0.3c-0.9-0.9-1.9-1.7-3-2.2
	l-2.2-1c-0.3-0.1-0.5-0.2-0.8-0.3l-0.2-0.1c-1.4-0.5-2.9-0.7-4.3-0.6l-0.4,0c-0.1,0-0.3,0-0.4,0c-0.6-1-1.3-1.8-2.1-2.5
	c0.5-1.8,0.4-3.8-0.2-5.7l-0.1-0.3c-0.1-0.4-0.3-0.8-0.5-1.2c0-0.1,0-0.2,0-0.3l0-0.4c-0.1-1-0.3-2.1-0.7-3l-1-2.5
	c-0.1-0.2-0.2-0.4-0.3-0.6c0.2-0.6,0.4-1.3,0.5-2l0.1-0.3c0.2-1.2,0.2-2.4,0-3.5l0-0.2c-0.1-0.8-0.4-1.5-0.7-2.3
	c-0.1-0.6-0.3-1.2-0.6-1.7l-0.1-0.2c0-0.1,0-0.1,0-0.2l0-0.2c-0.1-0.5-0.2-1.1-0.3-1.6c0.3-0.7,0.5-1.3,0.6-2l0.1-0.4
	c0.2-1.1,0.2-2.2,0-3.4l-0.4-2.2c0-0.1,0-0.3-0.1-0.4l-0.1-0.7c-0.3-1.4-0.9-2.7-1.7-3.8l-0.7-0.9c-0.6-0.8-1.3-1.6-2.2-2.2l0-0.2
	c-0.3-1-0.7-1.9-1.3-2.7c-0.2-1.7-0.9-3.3-1.9-4.7l-0.3-0.3c-0.3-0.4-0.7-0.8-1.1-1.2c-0.4-1-0.9-1.9-1.5-2.7l0.2-0.7
	c0.1-0.3,0.2-0.7,0.2-1l0.5-2.8c0-0.2,0.1-0.5,0.1-0.8l0-0.4c0-0.4,0-0.7,0-1.1l0-1.7c0-1.6-0.5-3.2-1.2-4.6l-0.1-0.2
	c-0.7-1.2-1.6-2.3-2.7-3.1l-0.2-0.4c-1-1.7-2.6-3.1-4.4-3.9l-0.3-0.1c-0.3-0.1-0.5-0.2-0.8-0.3l-0.3-0.3c-1-1.1-2.2-1.9-3.5-2.5
	c-0.5-0.4-1.1-0.8-1.7-1.1l-0.1-0.1c-0.3-0.2-0.7-0.3-1-0.5l-0.3-0.2l-0.2-0.3c-0.6-1.1-1.4-2-2.4-2.7c-0.4-0.5-0.9-0.9-1.3-1.3
	l-0.3-0.2c-0.5-0.4-1-0.8-1.6-1.1l-0.3-0.2c-1.1-0.6-2.4-1-3.6-1.1l-0.4,0c-0.6-0.1-1.2-0.1-1.8,0c-0.3-0.2-0.6-0.3-0.9-0.5
	l-3.5-1.6c-0.3-0.1-0.6-0.2-0.8-0.3l-1.9-0.7c-0.1,0-0.3-0.1-0.4-0.1l0,0c-0.2-0.2-0.5-0.4-0.7-0.6l-0.9-0.7
	c0.7-2.3,0.5-4.7-0.5-6.9l-0.2-0.4c-0.3-0.6-0.6-1.1-0.9-1.7c-0.3-0.8-0.6-1.5-1.1-2.2l-0.2-0.3c-0.5-0.8-1.1-1.5-1.9-2.2l-0.7-0.6
	c-1.3-1.2-2.9-1.9-4.7-2.3l-0.6-0.1c-0.1,0-0.3,0-0.4-0.1c-0.1-1.2-0.5-2.3-1.1-3.4c0.1-1.2-0.1-2.5-0.5-3.7l-0.2-0.5
	c-0.7-2.1-2.1-3.9-3.9-5.2l-0.9-0.6c-0.8-0.5-1.7-0.9-2.6-1.2l-2-0.6c0,0,0,0,0,0c-0.3-0.3-0.6-0.6-0.9-0.8l-0.1-0.1
	c0-0.6-0.1-1.2-0.2-1.8c0.2-0.4,0.3-0.7,0.4-1.1l0.1-0.4c0.3-1,0.5-2.1,0.5-3.1c0.6-1.4,0.9-2.9,0.8-4.4l0-0.7
	c-0.1-1.6-0.5-3.1-1.3-4.5l-0.7-1.3l-0.5-1.3c-0.4-1-1-2-1.8-2.8l-0.4-0.5c-0.6-0.7-1.3-1.3-2.1-1.8l-0.7-0.4
	c-0.9-0.5-1.8-1-2.8-1.2l-0.2-0.1l-1.5-0.8c0,0,0,0,0-0.1l-1.3-2.7c-0.4-0.8-0.9-1.5-1.4-2.1c-0.4-0.8-0.9-1.5-1.5-2.1l-0.2-0.2
	c-0.4-0.4-0.9-0.8-1.4-1.2c-0.1-0.1-0.2-0.2-0.3-0.3l-0.7-0.7c0,0,0,0-0.1-0.1c-0.2-0.3-0.5-0.7-0.7-1l-1.3-1.6
	c-0.9-1.2-2.1-2.1-3.5-2.7l-0.4-0.2c-0.2-0.1-0.3-0.1-0.5-0.2c-0.4-0.4-0.9-0.7-1.3-1l-0.6-0.4c-0.7-0.5-1.6-0.8-2.4-1.1
	c-1.1-1.3-2.6-2.3-4.2-2.9l-0.4-0.2c-1.1-0.4-2.3-0.6-3.6-0.6l-0.4,0c-0.2-0.2-0.5-0.3-0.7-0.5l-0.3-0.2c-0.4-0.3-0.8-0.5-1.2-0.7
	c0-0.7-0.1-1.5-0.3-2.2l-0.1-0.5c-0.4-1.7-1.3-3.3-2.5-4.5c0,0,0-0.1-0.1-0.1l-0.7-1.2c-0.2-0.4-0.5-0.8-0.8-1.2l-0.3-0.3
	c-0.2-0.2-0.3-0.4-0.5-0.6l-0.4-2.5c-0.1-0.3-0.1-0.6-0.2-0.9l-0.2-0.7c-0.1-0.4-0.3-0.8-0.4-1.3l-0.4-0.9c-0.3-1.9-1.2-3.6-2.5-5
	l-1.1-1.2c-0.6-0.7-1.3-1.2-2-1.7c-0.4-0.4-0.8-0.7-1.2-1l-0.2-0.2c-0.2-0.1-0.4-0.3-0.6-0.4l-0.1-0.1c-0.1-0.2-0.2-0.5-0.3-0.7
	l-0.1-0.3c-0.5-1-1.1-2-1.9-2.8l-0.4-0.4c-0.4-0.5-0.9-0.9-1.5-1.2c0,0-0.1-0.1-0.1-0.1l-1.4-1.5c-0.6-0.6-1.2-1.1-1.9-1.5l-1.1-0.7
	l-0.6-0.6c-0.7-0.6-1.4-1.2-2.3-1.6l-0.7-0.4c-0.3-0.1-0.5-0.3-0.8-0.4l-0.6-0.3c-0.3-0.3-0.7-0.6-1-0.9l-0.7-0.6
	c-0.3-0.2-0.7-0.5-1-0.7l-0.6-0.4c-0.2-0.2-0.4-0.3-0.6-0.5l-0.5-0.4c-1.1-0.9-2.5-1.5-3.9-1.8l-0.6-0.1c-0.5-0.1-1-0.2-1.4-0.2
	c-0.3-0.2-0.6-0.3-0.9-0.5c-0.4-0.4-0.7-0.7-1.1-1l-0.7-0.6l-0.8-0.9c-0.4-0.4-0.9-0.9-1.4-1.2l-0.3-0.5c-0.1-0.3-0.3-0.6-0.5-0.9
	l-0.6-0.9c-0.2-0.3-0.4-0.6-0.7-1l-0.7-0.9c-0.6-0.8-1.4-1.5-2.3-2.1l-1.6-1.1c-0.9-0.6-1.8-1-2.8-1.3v0c-0.2-1.2-0.5-2.3-1.1-3.4
	l-0.1-0.2c-0.4-0.7-0.9-1.4-1.4-2c0-0.1,0-0.2,0-0.4l-0.1-0.5c-0.2-1.6-0.9-3.1-1.8-4.4l-0.2-0.3c-0.5-0.7-1.2-1.4-1.9-1.9
	c-0.2-0.4-0.5-0.8-0.8-1.2c-0.3-0.7-0.8-1.4-1.3-2l-0.5-0.6c0-0.2-0.1-0.3-0.1-0.5c-0.1-0.4-0.2-0.8-0.3-1.1l-0.1-0.2
	c-0.4-1.2-1-2.3-1.8-3.3l-0.8-1c-0.4-0.5-0.8-0.9-1.3-1.3l-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.4-0.4-0.8-0.6-1.1l-1-1.6
	c-0.3-0.6-0.7-1.1-1.2-1.6l-0.3-0.3c-0.9-0.9-1.9-1.7-3.1-2.2c-0.5-0.4-1.1-0.8-1.7-1.1l-0.2-0.5c0,0,0-0.1-0.1-0.1
	c0-0.9-0.2-1.8-0.4-2.6l-0.1-0.4c-0.2-0.6-0.4-1.1-0.7-1.7c0-0.6-0.1-1.1-0.2-1.7c0-0.5-0.1-1-0.2-1.5l-0.2-1l0,0
	c0.1-1.2,0-2.5-0.3-3.7c-0.1-1.7-0.5-3.3-1.4-4.8l-0.5-0.8c-0.3-0.4-0.6-0.9-0.9-1.2l-0.7-0.9c-0.3-0.3-0.6-0.6-0.9-0.9l-1-0.9
	c-0.2-0.2-0.3-0.3-0.5-0.5c-0.1-0.3-0.3-0.6-0.4-0.8l-0.1-0.1c0-0.8-0.1-1.6-0.3-2.4l-0.4-1.5c-0.4-1.4-1-2.6-1.9-3.7l-0.2-0.2
	c-0.8-1-1.8-1.8-2.9-2.4l-0.3-0.3c-0.1-0.1-0.3-0.2-0.4-0.4l-0.3-0.2c-0.1-0.5-0.3-0.9-0.4-1.3c0-0.3-0.1-0.6-0.2-0.9l-0.1-0.4
	c-0.1-0.4-0.2-0.8-0.4-1.2l-0.2-0.6c0-0.6-0.1-1.1-0.2-1.7l-0.1-0.6c-0.2-0.8-0.5-1.6-0.8-2.3c-0.1-1-0.4-2.1-0.8-3
	c-0.3-1.8-1.2-3.6-2.4-5l-0.2-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.2-0.2-0.4-0.4-0.6l-0.4-0.6c-0.6-1-1.4-1.8-2.4-2.5l-0.8-0.6
	c-0.6-0.5-1.4-0.9-2.1-1.2l-2.8-1.2c-1.6-0.7-3.3-0.9-5-0.7l-0.8,0.1c-0.3,0-0.6,0.1-0.9,0.1l-0.8-0.1c-1.8-0.3-3.7-0.2-5.4,0.5
	l-1.5-0.1c-1.1,0-2.1,0.1-3.2,0.4c-0.7-0.8-1.4-1.6-2.3-2.1c-0.4-0.8-0.9-1.5-1.5-2.2l-1-1.1c-0.9-1-2-1.8-3.2-2.4l-1.7-2.1
	c-0.9-1.1-2-2-3.2-2.7l0,0c-0.5-1.2-1.3-2.4-2.3-3.3c-0.8-1.7-2.2-3.2-3.8-4.2l-0.5-0.3c-1.1-0.6-2.2-1.1-3.5-1.3l-0.8-0.1
	c-0.9-0.2-1.9-0.2-2.8-0.1l-0.6-0.2c-0.2-0.3-0.5-0.6-0.8-0.9l-1-1c-0.9-0.9-1.9-1.7-3.1-2.2l-1-0.4c-0.3-0.1-0.6-0.3-1-0.4
	c-0.3-0.6-0.7-1.1-1.1-1.6l-0.4-0.5c-0.7-0.8-1.5-1.5-2.4-2l0.2-0.2c1.7-2,2.6-4.6,2.5-7.2c0.1,0,0.2-0.1,0.3-0.1l0.6-0.3
	c1.4-0.6,2.7-1.5,3.6-2.7l0.4-0.5c1.1-1.3,1.9-3,2.2-4.7c0.5-0.7,0.9-1.4,1.3-2.2c2.1-0.5,4.1-1.7,5.5-3.4l0.6-0.8
	c0.4-0.4,0.7-0.9,0.9-1.4c0.3-0.2,0.5-0.5,0.8-0.8l0.8-0.9c0.1-0.1,0.2-0.2,0.2-0.3c0.1,0,0.1-0.1,0.2-0.1c1.3-0.3,2.6-0.9,3.7-1.7
	l1.4-1c0.8-0.6,1.4-1.2,2-2l0.1-0.1c1.6-1.4,2.6-3.2,3.1-5.2l0.1-0.4c0.3-1.1,0.3-2.3,0.2-3.4c0.1-0.1,0.1-0.2,0.2-0.3
	c0.6-0.8,1.1-1.8,1.4-2.8l0.4-1.1c0.3-1,0.5-2.1,0.4-3.2l0-0.6c0-0.6-0.1-1.2-0.2-1.8l-0.1-0.6c-0.2-1-0.6-1.9-1-2.8l-0.4-0.8
	c-1.8-3.2-5.2-5.2-8.9-5.1l-11.6,0.2c0,0-0.1,0-0.1,0L332,0.8l-31.9,0.6l-9.9,0.1c-1.4,0-2.8,0.3-4.1,0.9L272,2.3
	c-0.1,0-0.1,0-0.2,0l-11.4,0.1l-14.9,0l-12,0c0,0-0.1,0-0.1,0l-1.1,0l-13.9,0l-27.2-0.2c0,0-0.1,0-0.1,0L181,2.2c-0.1,0-0.2,0-0.3,0
	l-15.9,0c-0.1,0-0.2,0-0.3,0l-15.6-0.1c-0.4,0-0.8,0-1.1,0.1l-13.7,0l-0.4,0c-0.1,0-0.2,0-0.3,0l-10.9,0.1c0,0,0,0,0,0l-1,0
	c-0.4,0-0.8,0-1.2,0l-0.5,0c-0.2,0-0.4,0-0.6,0l-3.6,0l-0.3,0c0,0-0.1,0-0.1,0l-11,0c0,0-0.1,0-0.1,0L89.2,2.4c-0.1,0-0.1,0-0.2,0
	L69.9,2.5c0,0-0.1,0-0.1,0l-5,0.1h-0.1c-0.2,0-0.3,0-0.5,0l-0.4,0c0,0-0.1,0-0.1,0h-1.5c-0.1,0-0.1,0-0.2,0l-2.7,0.1l-3.1,0
	c-0.1,0-0.2,0-0.2,0l-2.5,0.1l-1.2,0c-0.1,0-0.3,0-0.4,0L41,3.1l-11.3,0L10.2,2.8C7.2,2.7,4.4,4,2.5,6.2c-1.9,2.2-2.8,5.1-2.4,8
	l0.8,5.3l0.1,0.8c0,0.2,0,0.5,0.1,0.7l0.5,3.5c0,0,0,0.1,0,0.1l1.7,11.1l3.1,19c0,0,0,0.1,0,0.1l1.2,7.1c0,0.1,0,0.1,0,0.2l1.5,8.6
	c0,0.1,0,0.1,0,0.2l1,5.3l1,6.1c0,0.1,0,0.1,0,0.2l1.4,7.7l0.4,2.8c0,0.1,0,0.2,0,0.3l0,0.2c0,0.1,0.1,0.3,0.1,0.4l1.5,8.1
	c0,0.1,0,0.1,0,0.2l4.5,24.8l1.6,9.9l0.1,0.5c0,0.1,0,0.2,0.1,0.3l0.3,1.9l0,0.1c0,0.2,0,0.3,0.1,0.5l3.6,21.1l0.1,1.5
	c0,0.2,0,0.4,0.1,0.6l3,19.1c0.1,0.4,0.1,0.7,0.2,1.1l3.9,23.8l2.2,14c0,0.1,0,0.2,0.1,0.3l0.6,3.6c0,0.1,0,0.1,0,0.2l6,36.2
	l0.1,0.8c0,0.1,0,0.3,0.1,0.5l1.3,7.8l10.2,63.4l6.2,36.8l0.4,2.2l0,0.5c0,0.6,0.1,1.3,0.2,1.9l0.1,0.3c0.3,1.4,0.8,2.6,1.6,3.8
	l0.4,0.6l0,1.4c0,0.8,0.1,1.6,0.2,2.4l0.1,0.4c0.3,1.2,0.7,2.3,1.4,3.3l0.8,1.2c0.2,0.3,0.5,0.6,0.7,1l0.9,1.1c0,0.3,0,0.6,0.1,0.9
	l0.1,1c0.1,0.9,0.3,1.8,0.7,2.7l0.3,0.7c0.3,0.7,0.7,1.4,1.2,2.1c0,0.6,0.1,1.2,0.2,1.8l0.8,4c0.1,0.3,0.1,0.6,0.2,0.9l0.3,1.1
	c0.4,1.2,1,2.4,1.8,3.4l0.1,0.2c-0.1,1.2,0,2.5,0.4,3.7l0.2,0.8c0.3,0.9,0.7,1.7,1.2,2.5c0,0.1,0.1,0.2,0.1,0.3l0.1,0.4
	c0.2,0.5,0.4,1,0.6,1.5c0.1,0.5,0.3,1.1,0.4,1.6l0.5,1.5c0.1,0.3,0.3,0.7,0.4,1l1.3,2.5c0.5,1.1,1.3,2.1,2.2,2.9l4.3,4.1
	c0.2,0.4,0.4,0.8,0.6,1.2l0.6,1.1c0.2,0.3,0.4,0.6,0.6,0.8l0.3,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0,0,0,0,0,0l0.1,0.5
	c0,0.2,0.1,0.4,0.1,0.6c-0.1,0.7-0.2,1.5-0.2,2.2l0,1.4c0,0.8,0.1,1.6,0.3,2.4l0.4,1.4c0.2,0.9,0.6,1.8,1.1,2.6l0.3,0.4
	c0.2,0.3,0.3,0.5,0.5,0.7l-0.1,0.6c-0.2,1.1-0.1,2.2,0.1,3.3c0,0.1,0,0.2,0,0.3l-0.2,0.2c-1.2,1.1-2.1,2.5-2.7,4.1l-0.5,1.4
	c-0.5,1.4-0.7,2.8-0.5,4.3l0.1,1.4c0.2,1.7,0.8,3.3,1.7,4.7l0.7,1.1c1.4,2,3.4,3.4,5.7,4l1,0.3l0.9,0.4l-0.1,0.1
	c-0.6,0.5-1.1,1-1.5,1.7l-0.2,0.2c-0.3,0.3-0.6,0.7-0.8,1.1l-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2l-0.2,0c-0.9,0.2-1.7,0.6-2.5,1.1
	l-0.4,0.2c-0.5,0.3-1,0.7-1.5,1.1l-0.3,0.2c-0.5,0.5-1,1-1.4,1.6l-1.1,1.1c-0.3,0.3-0.6,0.6-0.8,1l-0.5,0.7
	c-0.2,0.3-0.4,0.6-0.6,0.9l-0.5,0.7l-0.3,0.2c-0.5,0.3-1,0.7-1.5,1.2l-0.3,0.3c-0.8,0.8-1.5,1.8-2,2.8l-0.1,0.3
	c-0.9,1.8-1.2,3.9-0.9,5.9l0.1,0.5c0.1,0.9,0.4,1.7,0.7,2.5l0.3,1L78,502c0,0.2,0.1,0.3,0.1,0.5c-0.4,1.3-0.6,2.6-0.5,3.9
	c-0.1,0.5-0.2,1.1-0.2,1.6c-0.4,1.5-0.4,3.1,0,4.6l0.1,0.3c0,0,0,0.1,0,0.1c-0.3,0.5-0.5,1-0.7,1.6l-0.5,1.5
	c-0.2,0.6-0.3,1.2-0.4,1.8c-0.6,0.6-1,1.3-1.4,2l-0.1,0.3c-0.7,1.3-1.1,2.6-1.1,4.1l0,0.2c0,0.1,0,0.2,0,0.3
	c-0.1,0.1-0.2,0.3-0.3,0.4c-0.9,0.8-1.6,1.6-2.2,2.6c-0.2,0.2-0.5,0.4-0.7,0.6l-0.2,0.1c-1.5,1.4-2.6,3.2-3.1,5.2l0,0.1
	c-0.3,1.2-0.4,2.4-0.2,3.6c-0.1,0.2-0.1,0.3-0.2,0.5l-0.1,0.2c-0.6,1.5-0.8,3.1-0.6,4.7c-0.4,2-0.2,4.1,0.6,6l0.4,0.8
	c-1.6,3-1.6,6.6,0.1,9.6l0.2,0.4c0.2,0.3,0.4,0.6,0.6,0.9c0.1,0.4,0.2,0.7,0.3,1l0.2,0.7c-0.3,0.8-0.4,1.7-0.4,2.6l0,1.3
	c0,0.4,0,0.7,0,1.1l0.2,2.3c0.1,1.9,0.8,3.6,1.9,5.1l0.5,0.6c0.4,0.5,0.8,1,1.2,1.4c0.3,0.5,0.6,0.9,0.9,1.4l1.3,1.6
	c0.1,0.4,0.2,0.9,0.3,1.3l0.1,0.3c0.6,1.8,1.7,3.3,3.1,4.5c0,0.1,0.1,0.1,0.1,0.2l0,1c0,0.6,0,1.1,0.1,1.7c-0.1,1.9,0.3,3.8,1.2,5.5
	l0,0c0.1,0.3,0.2,0.6,0.3,0.9l0,0c-0.1,0.4-0.1,0.8-0.1,1.3l-0.5,1.1c-0.2,0.3-0.3,0.7-0.4,1c-0.4,0.7-0.8,1.5-1.1,2.3l-0.8,2.6
	c-0.5,1.4-0.6,2.9-0.4,4.4l0.3,2c-0.2,0.4-0.3,0.9-0.5,1.3l-0.2,0.6c0,0,0,0.1,0,0.1c-0.4,1-0.7,2-0.8,3l0,0.2
	c-0.1,0.7-0.1,1.5,0,2.2l-0.1,0.4l-0.8,1.9c-0.4,0.8-0.6,1.7-0.8,2.5l0,0.3c-0.1,0.5-0.1,0.9-0.1,1.4l0,0.9c0,1.1,0.1,2.2,0.5,3.2
	l0,0c0,0.7,0,1.5,0.1,2.2c-0.8,0.7-1.5,1.6-2.1,2.5l-0.3,0.4c-1,1.7-1.4,3.5-1.3,5.5l0,0.2c0,0.5,0.1,1,0.2,1.5c0,0.6,0,1.1,0.1,1.7
	l0.1,0.8c0.1,0.6,0.2,1.2,0.4,1.7l0.1,0.3c0.2,0.6,0.4,1.1,0.7,1.6l0.1,0.3c0,0,0,0.1,0,0.1c-0.3,0.8-0.5,1.6-0.6,2.4l-0.2,1.2
	c-0.1,0.7-0.1,1.3-0.1,2l0.1,1.5c0.1,1.4,0.4,2.7,1.1,3.9c0,0.1,0,0.2,0.1,0.4l0,0.2c0.4,2.5,1.8,4.8,3.9,6.4l1.1,0.9
	c0.5,0.3,0.9,0.6,1.5,0.9l0.6,1.4c0.6,1.5,1.5,2.8,2.8,3.8l0.2,0.2c0,1.7,0.5,3.4,1.4,4.9l1.4,2.3c0.1,1.3,0.3,2.5,0.9,3.7
	c0,0.2,0.1,0.4,0.1,0.6c0,0.3,0,0.5,0,0.8c-0.3,1-0.5,2.1-0.5,3.2l0,0.5c0,1,0.1,1.9,0.4,2.9l0.1,0.4c0.3,1.1,0.9,2.2,1.6,3.1
	l0.2,0.2c0.3,0.4,0.6,0.7,0.9,1.1c0,0.3,0.1,0.6,0.1,0.8l0.1,0.4c0.4,2,1.4,3.8,2.8,5.2l0.4,0.3c0.1,0.1,0.1,0.1,0.2,0.2
	c0.4,0.7,0.9,1.4,1.5,2.1l0.7,0.7c0.2,0.5,0.5,0.9,0.7,1.3c0,0.2,0.1,0.3,0.1,0.5l0.1,0.4c0,0.2,0.1,0.3,0.1,0.5l0,0.4
	c0,0.5,0.1,1.1,0.2,1.6c-0.1,0.5-0.2,1-0.2,1.5l-0.1,1.1c-0.1,0.9-0.1,1.7,0.1,2.6l0.1,0.6c0.1,0.9,0.4,1.9,0.8,2.7
	c0,0.1,0,0.3,0.1,0.4l0.1,0.7c0.1,1,0.4,2,0.9,2.9l0.2,0.4c0,1.6,0.4,3.1,1.2,4.5c0.2,0.7,0.5,1.3,0.8,2l0.1,0.2
	c0.9,1.8,2.4,3.2,4.1,4.2l0.3,0.2c0.2,0.1,0.4,0.2,0.6,0.3c0.5,0.6,1,1.2,1.6,1.7c1.6,3.4,4.9,5.6,8.7,5.7l3.9,0.1l26.9,1.4l5.4,0.3
	c0.1,0,0.2,0,0.3,0l9.2,0.5l9.1,0.6c0.1,0,0.2,0,0.4,0l13.9,0.5l13.1,0.7c0,0,0.1,0,0.1,0l4.3,0.2l17.4,1l2.3,0.2c0.1,0,0.2,0,0.3,0
	l7.1,0.3l2,0.1c0.1,0,0.3,0,0.4,0l22.6,1.3c0.1,0,0.2,0,0.3,0l8,0.5l1.4,0.1c0.1,0,0.1,0,0.2,0l18.8,1.1l9.3,0.6l15.7,0.9l7.1,0.5
	c0.1,0,0.2,0,0.2,0l2.7,0.1c0.1,0,0.1,0,0.2,0l16.2,1.1l0.2,0c0.1,0,0.1,0,0.2,0l19.1,1.3c0,0,0.1,0,0.1,0l5.8,0.4
	c0.1,0,0.2,0,0.2,0l35.7,2.4c0.1,0,0.1,0,0.2,0l24.9,1.7c0.1,0,0.2,0,0.3,0l18.1,1.2l0.3,0c0.1,0,0.3,0,0.4,0l2.7,0.2
	c0.1,0,0.1,0,0.2,0l35,2.5c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.9,0.3,1.9,0.7,2.8l0.5,1.2c0.2,0.6,0.6,1.2,0.9,1.7l0.3,0.4
	c0.2,0.4,0.5,0.7,0.8,1c-0.1,0.5-0.1,1.1-0.1,1.6l0,0.6c0,0.2,0,0.5,0.1,0.7c-0.2,0.8-0.4,1.6-0.4,2.4l-0.1,1.3c0,0.9,0,1.8,0.2,2.7
	l0.9,3.8c0.1,0.6,0.3,1.2,0.6,1.7l1.6,3.7c0.5,1.1,1.1,2.1,1.9,2.9l0.4,0.5c0.4,0.5,0.9,0.9,1.4,1.2l0.8,1c0.5,0.6,1.1,1.1,1.7,1.6
	l0.2,0.2c1.2,0.9,2.5,1.5,3.9,1.8l0.7,0.1c0.5,0.1,1,0.2,1.5,0.2l0.5,0c0.2,0,0.4,0,0.6,0c1.5,0,3.1-0.4,4.5-1l1.1-0.2
	c0.7,0.3,1.5,0.5,2.2,0.7l1.9,0.3c0.7,0.1,1.3,0.2,2,0.1l1.8-0.1c0.4,0,0.8-0.1,1.2-0.1l1.2-0.2c0.2,0,0.5-0.1,0.7-0.1l1.4-0.3
	c0.8-0.2,1.5-0.4,2.2-0.8l0.3-0.2c1.1-0.6,2.1-1.3,3-2.3l1-1.1c0.7-0.8,1.3-1.7,1.7-2.7l0.6-1.3c0.4-0.9,0.7-1.9,0.8-2.9l0.1-1.2
	c0.1-1.3,0-2.5-0.3-3.7c0.4-1.1,0.6-2.4,0.6-3.6c0.2-0.6,0.4-1.1,0.6-1.7l0.4-1.7c0,0,0-0.1,0-0.1c0.5-1,0.9-2,1.1-3.1l0.1-0.6
	c0.1-0.7,0.2-1.4,0.2-2.1l0-0.6c0.1-0.4,0.1-0.9,0.2-1.3l0.2-2.9c0-0.8,0-1.5-0.1-2.3l-0.1-0.8c0,0,0,0,0-0.1l0.6-1.2
	c0.9-1.9,1.3-4,0.9-6.1l-0.4-2.2c0-0.1,0-0.3-0.1-0.4l-0.6-2.7c-0.1-0.5-0.3-1.1-0.5-1.6l-0.5-1.3c-0.4-1-0.9-1.9-1.6-2.7
	c-0.2-0.7-0.6-1.4-0.9-2.1l-0.4-0.7c-0.1-0.4-0.2-0.8-0.4-1.2l-0.3-0.9c0.1-0.2,0.1-0.3,0.2-0.5l0.8-2.3c0.4-1.3,0.6-2.6,0.5-3.9
	l0-0.4c0.2-0.2,0.3-0.4,0.5-0.6c0.6-0.2,1.3-0.4,1.9-0.7l0.7-0.3c1.8-0.8,3.2-2.1,4.3-3.7c1.7,1.4,3.8,2.2,5.9,2.3
	c1.8,0.9,3.8,1.2,5.7,1c0.5,0.5,1,0.9,1.6,1.3l0.5,0.3c1.4,0.9,3.1,1.4,4.7,1.5c0.6,0.6,1.2,1.1,1.9,1.6l0.7,0.4
	c0.6,0.3,1.1,0.6,1.8,0.9l1,0.4c1.4,0.5,3,0.7,4.5,0.6c0.5,0.2,1,0.3,1.6,0.4l1.4,0.2c0.7,0.5,1.4,0.9,2.1,1.2l0.4,0.2
	c1.2,0.5,2.4,0.8,3.7,0.8c0.7,0.3,1.5,0.6,2.3,0.7l1.2,0.2c1.4,0.3,2.8,0.2,4.1-0.1c1.4,0.7,3,1.1,4.6,1.1c0,0,0.1,0,0.1,0l0.4,0.1
	c0.2,0,0.3,0.1,0.5,0.1l0.2,0c0.8,0.1,1.7,0.2,2.5,0.1l0.2,0c1.3-0.1,2.6-0.5,3.8-1.1l0.1,0c0.5,0.3,1,0.5,1.6,0.7l1.1,0.4
	c0.5,0.2,1,0.3,1.5,0.4l0.6,0.1c0.2,0,0.3,0,0.5,0.1l1.6,0.2c0.7,0.1,1.4,0.1,2,0l0.6-0.1c3.6-0.3,6.8-2.6,8.2-5.9
	c1.4-3.2,1-6.8-0.9-9.7l-0.6-1.8l0-0.1l0.1-1.1l0.4-2l0.7-1.8l0.2-0.3l0.7-0.9c0.7-0.8,1.2-1.8,1.6-2.8l0.3-0.9
	c0.1-0.3,0.2-0.5,0.3-0.8l3.3-11.8c0.2-0.8,0.3-1.6,0.4-2.4l0.2-8.2c0-0.8-0.1-1.6-0.2-2.4l-0.7-3.3c-0.2-1.1-0.6-2.1-1.2-2.9
	c0-0.2,0.1-0.3,0.1-0.5l2-7.6c0.2-0.8,0.3-1.6,0.3-2.5l0-2.1c1.3-0.8,2.4-1.9,3.2-3.3l1.3-2.1c0.7-1.2,1.2-2.6,1.4-4l0.4-0.6
	c2.5-0.3,4.7-1.4,6.4-3.3l0.7-0.8c1.7-1.9,2.7-4.5,2.5-7.1l0-0.8l0.2-0.7l0.4-0.9c0.6-1.2,0.9-2.5,1-3.9l0.1-2.5
	c0.5-0.6,0.9-1.3,1.2-2l0.9-1.9c1.8-3.9,0.9-8.4-2.1-11.3l1.4-3.1l1.2-2.2l5.6-7.8c0.4-0.6,0.8-1.2,1.1-1.9l1.6-3.8l0.9-1.2
	c1.7-2.2,2.5-5.1,2-7.8l-0.1-0.4l0.6-0.8c0.5-0.7,1-1.5,1.3-2.4l1.3-3.5l2.2-4.6c0.6-1.2,0.9-2.5,0.9-3.8l0.2-5.2
	c0-0.8,0-1.6-0.2-2.4l-0.3-1.6l0.1-0.4c0.3-1.1,0.5-2.3,0.4-3.4l0.8-0.8l1.1-0.5c1-0.5,2-1.1,2.8-1.9l1.3-1.2
	c0.8-0.7,1.4-1.6,1.9-2.5l2.3-4.5l0.6-0.7c1.2-1.4,2.1-3,2.4-4.8c1.5-0.6,2.8-1.5,3.9-2.7l2.6-2.9l3-2.7c0.4-0.3,0.7-0.7,1-1
	l1.8-2.2c1-1.2,1.7-2.6,2.1-4.1c0.6-0.3,1.2-0.7,1.7-1.1l1.6-1.3l2.3-1.4c1.3-0.8,2.4-1.9,3.2-3.1l2-3.2c0.4-0.6,0.7-1.2,0.9-1.8
	l0.9-2.4c0.3-0.7,0.5-1.5,0.6-2.3l0.2-1.4C692.8,516.6,692.7,515.6,692.6,514.6z M623.1,535.8c-0.1,0.2-0.1,0.4-0.2,0.5
	c-5.3,2-10,5.7-13.3,10.6l-1.5,2.3c-4.6,6.9-5.8,15.3-3.7,22.9c-1.3,1.3-2.5,2.8-3.6,4.4l-0.8,1.3c-1.9,2.9-3.2,6.2-4,9.6l-0.5,2.6
	c-0.8,4-0.8,8.2,0.2,12.2l0.1,0.3c0.4,1.6,0.9,3.2,1.6,4.7l-1.9,4.1c-1.6,3.5-2.5,7.1-2.6,11l0,1.3l-0.3,2.7c-0.3,2.3-0.3,4.6,0,6.8
	c-1.2,1.2-2.2,2.5-3.2,3.8l-0.8,1.1c-5.9,3.9-10.3,9.9-12,17l-0.3,1.4c-0.3,1.4-0.6,2.9-0.7,4.3l-0.1,1.7c-0.3,0.3-0.5,0.5-0.8,0.8
	c-4.6,3.3-8.2,8-10.1,13.4l-0.4,1.2c-2,5.6-2.1,11.6-0.5,17.2c-0.8-0.4-1.6-0.7-2.5-0.9c-0.5-0.3-1.1-0.5-1.6-0.8l-2-0.9
	c-1.8-0.8-3.7-1.4-5.6-1.8l-0.3-0.1c-0.5-0.1-0.9-0.2-1.4-0.2c-3.4-2.5-7.4-4.2-11.6-5l-0.5-0.1c-1.3-0.2-2.5-0.4-3.8-0.4
	c-2.3-0.6-4.6-0.9-7-1l-1.3,0c-5.9,0-11.8,1.9-16.6,5.5l-1.4,1c-0.6,0.4-1.1,0.8-1.6,1.3c-2.3,0.9-4.4,2.1-6.4,3.5l-0.8,0.6
	c-2.7,2-5.1,4.5-6.9,7.3l-0.1,0.2c-1.8,2.8-3.1,5.9-3.8,9.2c-0.4,0.8-0.7,1.6-1,2.5l-0.1,0.3c-0.7,1.9-1.2,3.8-1.5,5.8
	c-0.4,1.6-0.7,3.3-0.8,4.9c-0.1,0-42.1-3-42.2-3.1l-1.1-0.1c-0.3,0-0.7-0.1-1-0.1l-18.1-1.2l-25.3-1.8c-0.2,0-35.6-2.4-35.6-2.4
	l-3.2-0.3c-0.2,0-0.5,0-0.7-0.1l-21.8-1.5l-16.8-1.1c-0.2,0-0.3,0-0.5,0l-9.7-0.7l0.1,0l-43.5-2.6l-1.4-0.1l-0.5,0l0.1,0l-8.3-0.5
	c-0.2,0-0.5,0-0.7,0l-17.4-1c0,0-5.5-0.3-5.7-0.4l-8.3-0.4l-2.1-0.2l-0.6,0l-17.6-1l-4.6-0.2l-13-0.7l-0.6,0l-13.5-0.5l-8.7-0.6
	c-0.2,0-0.3,0-0.5,0c0,0-9.4-0.5-9.5-0.5l-5.4-0.3l-14-0.7c-0.1-0.8-0.3-1.6-0.5-2.4c-0.2-4.2-1.4-8.4-3.5-12.1l-0.2-0.4
	c-0.9-1.6-2-3.2-3.2-4.6c-0.5-2.3-1.2-4.5-2.2-6.6l-0.3-0.6c-0.3-1.9-0.7-3.7-1.4-5.5c-0.2-1.1-0.4-2.2-0.7-3.2l-0.4-1.3
	c-0.5-1.9-1.3-3.8-2.2-5.6l-0.3-1.1c-0.4-1.6-1-3.2-1.7-4.7c-1-2.8-2.5-5.4-4.4-7.8l-0.2-0.3c-0.3-0.4-0.6-0.8-1-1.2
	c-0.4-0.7-0.8-1.5-1.3-2.2c0-0.5-0.1-1.1-0.1-1.6c0.3-0.9,0.5-1.8,0.7-2.7c0,0,0.3-1.6,0.3-1.6c0.8-2.8,1.1-5.6,1-8.5l-0.1-1.7
	c0-0.9-0.1-1.9-0.2-2.8c0,0,0,0,0,0c0.2-0.7,0.4-1.5,0.6-2.2c1.4-3.2,2.1-6.6,2.2-10.2l0-1.5c0-0.5,0-0.9,0-1.4
	c0.3-0.6,0.6-1.3,0.9-1.9l0.1-0.2c0.8-2.1,1.4-4.3,1.7-6.5l0.1-0.4c0.1-0.4,0.1-0.9,0.1-1.3c0.1-0.5,0.2-1,0.2-1.5l0.1-0.5
	c0.3-2.5,0.3-5,0-7.4l-0.1-0.6c-0.3-2.3-0.9-4.5-1.7-6.7c0-1.1-0.1-2.3-0.2-3.4c-0.2-2.8-0.7-5.5-1.7-8.2l-0.1-0.3
	c-1-2.6-2.3-5-3.9-7.2c-0.3-0.7-0.7-1.4-1.1-2.1l-0.2-0.3c-0.6-1.1-1.2-2.1-2-3.1c-0.2-1.2-0.4-2.3-0.7-3.5
	c-0.1-1.9-0.4-3.8-0.9-5.6l0-0.3c0-0.6,0-1.2,0-1.7c0.2-0.5,0.5-1,0.7-1.5c2.8-3.3,4.8-7.1,5.9-11.3c0.1-0.2,0.5-1.1,0.5-1.1
	c1.1-2.6,1.8-5.2,2.1-7.9l0,0c1.1-2.9,1.7-5.9,1.8-8.9c0-0.1,0-0.5,0-0.5c0.1-1.8,0.1-3.6-0.1-5.4c0.1-0.3,0.1-0.6,0.2-0.8l0.1-0.4
	c0.1-0.4,0.2-0.8,0.3-1.3c1.4-1,2.6-2.1,3.8-3.2c0.6-0.4,1.2-0.7,1.7-1.1l0.4-0.3c2.8-2,5.2-4.5,7.1-7.3l0.4-0.5
	c0.3-0.4,0.5-0.8,0.8-1.2c1.1-1.1,2.2-2.3,3.1-3.7l0.2-0.2c2.7-3.9,4.5-8.5,5-13.2l0-0.3c0.5-4.7-0.2-9.5-2.1-13.8l-0.1-0.3
	c-0.8-2-1.9-3.9-3.2-5.6l-0.3-0.4c-1.1-1.4-2.3-2.8-3.6-4l-1.2-1.1c0.8-4.3,0.5-8.8-0.8-13l-0.6-1.9c-0.5-1.8-1.3-3.5-2.2-5.2
	L124,431c-0.3-0.6-0.6-1.1-0.9-1.6l0-0.1l-0.1-0.8c-0.4-3.1-1.4-6.2-2.8-9l-0.8-1.5c-0.8-1.6-1.8-3.1-2.9-4.5
	c-1.2-2.1-2.7-4-4.5-5.8l-1.1-1.1c-0.2-2.6-0.7-5.2-1.6-7.7l-0.7-2c-0.6-1.7-1.4-3.3-2.3-4.8c-0.1-1.3-0.4-2.5-0.7-3.8l-0.1-0.2
	c-0.3-1.1-0.6-2.2-1-3.2c-0.4-3.5-1.5-7-3.1-10.1l-0.1-0.3c-0.2-0.4-0.4-0.8-0.7-1.2c-0.1-0.9-0.3-1.7-0.5-2.6l0-0.2
	c-0.4-2.1-1.1-4.2-2-6.1l-0.1-0.3c-0.7-1.4-1.5-2.8-2.4-4.1l-5.4-32l-10.2-63.5l-1.3-7.5l-0.1-0.4c0-0.3-0.1-0.7-0.1-1l-5.9-36
	l-0.4-2.7c0-0.3-0.1-0.6-0.1-0.9l-2.3-14.6l-4-24.6c-0.1-0.5-0.2-1.1-0.3-1.6l-2.7-16.9l-0.1-1c-0.1-0.6-0.1-1.3-0.3-2l-3.5-20.8
	c0-0.4-0.1-0.8-0.2-1.1l-0.3-1.7l0-0.2c0-0.2-0.1-0.5-0.1-0.7l-1.7-10l0,0.1l-0.1-0.5l-4.5-25L52,95.1l0.1,0.3l-1.3-7.4
	c-0.1-0.4-0.1-0.8-0.2-1.1l0-0.1l-0.3-2.1C50.1,84.3,50,84,50,83.7l-1.4-7.6l-1-5.9c0-0.2-0.1-0.4-0.1-0.6l-1-5.3L45,55.5l-1.2-7
	L42.6,41c0,0,10.9-0.2,11.1-0.2l2.9-0.1l7.6-0.1c0.5,0,0.9,0,1.4,0l23.8-0.2l14.8-0.2l10.6,0l0.7,0l3.2,0l0.3,0c0.4,0,0.9,0,1.3,0
	c0.2,0,0.4,0,0.6,0l0.6,0c0.3,0,0.6,0,0.9,0c0.2,0,10.9-0.1,10.9-0.1c0.3,0,0.5,0,0.6,0l14.5,0c0.6,0,1.2,0,1.8-0.1l14.6,0.1l0.3,0
	l15.9,0c0.2,0,0.5,0,0.7,0l9.6-0.1l27,0.2l14.5,0l-0.1,0l13,0l15.1,0l11.2-0.1l18.4,0.2c2.5,0,5-0.3,7.4-1l3,0l4.3-0.1
	c-0.1,0.5-0.2,1-0.3,1.5c-0.7,1.2-1.3,2.4-1.9,3.6l-0.6,1.5c-1.6,3.9-2.4,8.1-2.2,12.3l0.1,2.2c0.1,1.2,0.2,2.3,0.4,3.5
	c0.1,2,0.5,4,1.1,5.9l0.1,0.3c0.8,2.6,1.9,5,3.3,7.2c2,4.1,5,7.6,8.7,10.3l0.4,0.3c0.6,0.4,1.2,0.9,1.9,1.3c0.6,0.4,1.1,0.9,1.7,1.2
	c0.9,0.8,1.9,1.6,2.9,2.3l0.3,0.2c0.4,0.3,0.8,0.6,1.2,0.9c1.6,1.9,3.4,3.6,5.4,5l1.6,1.1c2.8,1.9,5.9,3.4,9.2,4.2l0.2,0.2
	c0.4,0.4,0.9,0.9,1.4,1.3c0.1,0.1,0.3,0.5,0.3,0.5c0.6,0.9,1.3,1.9,2.1,2.7c1.2,1.8,2.5,3.4,4.1,4.8l2.5,2.4
	c1.9,1.8,3.9,3.2,6.2,4.4c0.1,0.1,0.3,0.2,0.4,0.3l1.3,0.9c2.3,1.6,4.8,2.9,7.4,3.8c3.3,1.8,7,2.9,10.8,3.3c0.6,1.5,1.3,3,2.1,4.5
	l0.2,0.3c1.4,2.4,3.1,4.5,5.2,6.4c0,0,0,0,0,0c0.8,2.4,2,4.6,3.4,6.7l0.5,0.7c0,0.3,0.1,0.5,0.1,0.8l0,0.3c0.2,2.4,0.8,4.7,1.6,6.9
	c0,0.1,0.1,0.6,0.1,0.6c0.3,1.5,0.7,3,1.2,4.5l0.1,0.4c0.5,1.3,1.1,2.6,1.7,3.9c1,2.9,2.4,5.5,4.2,8l0.3,0.4c1.5,2,3.3,3.8,5.3,5.3
	l1,0.8c0.6,1.6,1.4,3.2,2.3,4.6l0.5,0.8c1.3,2.1,2.8,3.9,4.5,5.6c0.4,1.1,0.8,2.2,1.3,3.2c0.7,2.2,1.7,4.3,2.9,6.2l0.1,0.2
	c0.6,1.3,1.2,2.6,2,3.8l1.1,1.8c1.7,2.8,3.9,5.3,6.5,7.3l0.3,0.3c2,1.6,4.1,2.8,6.4,3.8c0.9,0.8,1.8,1.6,2.7,2.3
	c0.7,0.8,1.5,1.5,2.4,2.2c1.1,1.1,2.4,2.2,3.7,3.1l1,0.7c0.2,0.2,0.4,0.3,0.7,0.5c0.4,0.4,0.9,0.7,1.4,1.1l0.3,0.2
	c1.9,1.4,4,2.6,6.2,3.4l0.9,0.4c0.7,0.3,1.4,0.5,2.1,0.7c0.1,0,0.5,0.3,0.5,0.3c0.4,0.2,0.7,0.5,1.1,0.7c0,0,0.2,0.4,0.2,0.4
	c1.6,2.6,3.6,4.9,5.9,6.9l0.1,0.4c1,3.4,2.6,6.7,4.8,9.5l0.3,1.1c1.1,4.6,3.3,8.8,6.4,12.3l1.5,1.6c0.5,0.6,1.1,1.1,1.7,1.7l0.9,0.8
	c1.4,1.3,2.9,2.4,4.5,3.4c0,0,1,0.6,1.2,0.7c0.3,0.2,0.5,0.4,0.8,0.7l0.8,0.6c1.5,1.2,3.2,2.2,4.9,3l1.3,0.6c2.1,1,4.3,1.7,6.5,2.2
	c1,2.6,2.4,5,4.2,7.2l0,0.4c-0.4,4,0.1,8,1.3,11.8c1.4,7.4,5.7,14,12.1,18.2c0.3,0.3,0.6,0.6,0.9,0.9c1,3.6,2.8,6.9,5.1,9.8l0.8,1
	c1.5,1.9,3.3,3.5,5.2,5c0.5,0.5,1.1,1,1.6,1.4c0.4,0.4,0.7,0.8,1.1,1.1c1.1,2.5,2.5,4.8,4.3,6.9l1.1,1.3c0.3,0.3,0.6,0.7,0.9,1
	l1.6,1.7c1.2,1.3,2.5,2.4,3.8,3.4l1.7,1.2c0.7,0.6,1.5,1.2,2.2,1.8l1.8,1.3c2.8,2,5.9,3.5,9.2,4.4c1.5,1,3.2,1.9,4.9,2.6l0.1,0
	c0.5,0.4,1,0.8,1.6,1.1c0.2,0.2,0.5,0.4,0.7,0.7c0.2,1.5,0.6,3,1,4.4c0.6,3,1.6,5.8,3.1,8.4c0.8,5,3,9.7,6.4,13.6l0.1,0.1
	c0.1,0.3,0.3,0.6,0.5,0.8c0.1,0.6,0.2,1.2,0.3,1.9c0.1,1,0.2,2.1,0.4,3.1c-1.1,4.9-0.8,10,0.7,14.7c0,0.5-0.1,1-0.1,1.5l0,0.9
	c-0.2,4.8,0.9,9.6,3.1,13.9l1,1.8c0.9,1.8,2.1,3.6,3.4,5.2l1,1.2c0.3,0.3,0.6,0.7,0.9,1c1.4,1.9,3,3.6,4.8,5.1
	c2.4,2.8,5.4,5,8.7,6.7l5.4,2.7c0.6,0.3,1.2,0.6,1.8,0.8c0.1,0.4,0.2,0.8,0.3,1.3l0.5,2c0.7,3.3,2.1,6.3,3.9,9.1
	c0.6,2,1.4,3.8,2.4,5.6c0.1,0.2,0.3,0.7,0.3,0.7c0.2,0.4,0.3,0.7,0.5,1.1c-1.1,4.2-1.3,8.6-0.4,12.9l0.1,0.4
	c0.6,2.7,1.5,5.4,2.9,7.8l0.3,0.6c0.1,2.8,0.7,5.6,1.7,8.2l0.3,2.3c0.7,4.7,2.5,9.1,5.3,12.9l0.6,0.7c1.8,2.4,4,4.5,6.4,6.2
	C625.4,526.3,623.6,531,623.1,535.8z"
/>
</svg>    
)
  