import * as turf from '@turf/turf'

export const sleep = async (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const boundsCardinal = (bounds) => {
    return {
        north: bounds.getNorthEast().lat(),
        east: bounds.getNorthEast().lng(),
        south: bounds.getSouthWest().lat(),
        west: bounds.getSouthWest().lng()
    }
}

export const getBounds = (geometry) => {
    let north, east, south, west;
    geometry.coordinates.forEach(ring => {
        ring.forEach(coordinatePair => {
            if (east === undefined || coordinatePair[0] > east) {
                east = coordinatePair[0]
            }
            if (west === undefined || coordinatePair[0] < west) {
                west = coordinatePair[0]
            }
            if (north === undefined || coordinatePair[1] > north) {
                north = coordinatePair[1]
            }
            if (south === undefined || coordinatePair[1] < south) {
                south = coordinatePair[1]
            }
        })
    });

    return new google.maps.LatLngBounds(
        { lat: south, lng: west },
        { lat: north, lng: east }
    )
}

export const featureVisible = (feature, mapBounds) => {
    const { 
        north: mapNorth,
        east: mapEast,
        south: mapSouth,
        west: mapWest
     } = boundsCardinal(mapBounds);

    let featureNorth, featureEast, featureSouth, featureWest;

    if (feature.geometry.type === 'Polygon') {
        ({
            north: featureNorth,
            east: featureEast,
            south: featureSouth,
            west: featureWest
        } = boundsCardinal(getBounds(feature.geometry)))
    }

    return mapNorth > featureSouth
        && mapEast > featureWest
        && mapSouth < featureNorth
        && mapWest < featureEast
}

export const featureVisibleCenter = (feature, mapBounds) => {
    const { 
        north: mapNorth,
        east: mapEast,
        south: mapSouth,
        west: mapWest
     } = boundsCardinal(mapBounds);
     const { 
        north: featureNorth,
        east: featureEast,
        south: featureSouth,
        west: featureWest
     } = boundsCardinal(getBounds(feature.geometry));
     
    const north = Math.min(mapNorth, featureNorth)
    const east = Math.min(mapEast, featureEast)
    const south = Math.max(mapSouth, featureSouth)
    const west = Math.max(mapWest, featureWest)

    const latCenter = south + ((north - south) / 2)
    const lngCenter = west + ((east - west) / 2)

    return {
        lat: latCenter,
        lng: lngCenter
    }
}

export const featureShouldDisplay = (feature, mapBounds) => {
    const MIN_AREA_FACTOR = 0.01

    const { 
        north: mapNorth,
        east: mapEast,
        south: mapSouth,
        west: mapWest
     } = boundsCardinal(mapBounds);

    const featurePolygon = turf.polygon(feature.geometry.coordinates)
    const mapPolygon = turf.polygon([[
        [mapWest, mapNorth],
        [mapEast, mapNorth],
        [mapEast, mapSouth],
        [mapWest, mapSouth],
        [mapWest, mapNorth]
    ]])

    const polygonArea = turf.area(featurePolygon)
    const mapArea = turf.area(mapPolygon)

    return (polygonArea / mapArea) > MIN_AREA_FACTOR
}