import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useRef, useState } from 'react';

import { mapStyle } from 'assets/jss/google-maps';
import mapPanelStyle from "assets/jss/material-kit-pro-react/components/mapPanelStyle";
import { CONFIG } from 'constants/config';
import { DataType } from 'models/enums/DataType';
import { AsyncSearchSection } from './AsyncSearchSection';
import { ContourTilesMapSection } from './ContourTilesMapSection';
import { ParcelPointsMapSection } from './ParcelPointsMapSection';

//@ts-ignore
const useStyles = makeStyles(mapPanelStyle);

const MapPanel = (props) => {
    const {
        dataType,
        county,
        countyGeography
    } = props;
    
    const classes = useStyles();
    
    const mapRef = useRef();
    const [map, setMap] = useState<google.maps.Map>(null)

    useEffect(() => {
        setupMap()
    }, [mapRef]);

    const setupMap = () => {
        const map = new window.google.maps.Map(mapRef.current, {
            center: countyGeography.countyBoundaries.center,
            restriction: {
                latLngBounds: {
                    north: countyGeography.countyBoundaries.zoomBounds.ne.lat,
                    east: countyGeography.countyBoundaries.zoomBounds.ne.lng,
                    south: countyGeography.countyBoundaries.zoomBounds.sw.lat,
                    west: countyGeography.countyBoundaries.zoomBounds.sw.lng
                },
                strictBounds: true
            },
            zoom: 9,
            isFractionalZoomEnabled: true,
            mapTypeId: 'satellite',
            mapTypeControl: false,
            streetViewControl: false,
            gestureHandling: 'cooperative',
            fullscreenControl: false,
            maxZoom: 20,
            styles: mapStyle
        });
        countyGeography.countyBoundaries.outline.geometry.coordinates.forEach(polyline => {
            new google.maps.Polyline({
                map,
                path: polyline.map(point => ({
                    lat: point[1],
                    lng: point[0]
                })),
                strokeColor: 'white'
            })
        })
        map.fitBounds(new google.maps.LatLngBounds(countyGeography.countyBoundaries.bounds.sw, countyGeography.countyBoundaries.bounds.ne));
        setMap(map);
    }

    const renderMap = () => {
        switch (dataType) {
            case DataType.parcels.key:
                return (<ParcelPointsMapSection
                    map={map}
                    mapRef={mapRef}
                    county={county}
                    countyGeography={countyGeography}
                />)
            case DataType.contourLines.key:
                return (<ContourTilesMapSection
                    map={map}
                    mapRef={mapRef}
                    tileData={countyGeography.tiles}
                />)
        }
    }

    return (
        <div className={classes.container}>
            <div className={classes.dataSection}>
                <AsyncSearchSection
                    dataType={dataType}
                    map={map}
                    county={county}
                    countyGeography={countyGeography}
                />
            </div>
            <div className={classes.mapSection}>
                {
                    renderMap()
                }
            </div>
        </div>
    );
}

const MapPanelWrapper = (props) => {
    return (
        <Wrapper 
            apiKey={CONFIG.GOOGLE_MAPS_API_KEY}
            libraries={['places']}
            render={(status) => {
                switch (status) {
                    case Status.LOADING:
                      return <p>Loading...</p>;
                    case Status.FAILURE:
                      return <p>Error</p>;
                    case Status.SUCCESS:
                      return <MapPanel {...props}/>;
                }
            }}
        />
    );
}

export { MapPanelWrapper as MapPanel };
