import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// core components
import styles from "assets/jss/material-kit-pro-react/components/parallaxStyle";
import { Gis1Logo } from "components/Icons/Gis1Logo";

const useStyles = makeStyles(styles);

export default function Parallax({ small = true }) {
  let windowScrollTop;
  if (window.innerWidth >= 768) {
    windowScrollTop = window.pageYOffset / 3;
  } else {
    windowScrollTop = 0;
  }
  const [transform, setTransform] = React.useState(
    "translate3d(0," + windowScrollTop + "px,0)"
  );
  React.useEffect(() => {
    if (window.innerWidth >= 768) {
      window.addEventListener("scroll", resetTransform);
    }
    return function cleanup() {
      if (window.innerWidth >= 768) {
        window.removeEventListener("scroll", resetTransform);
      }
    };
  });
  const resetTransform = () => {
    var windowScrollTop = window.pageYOffset / 3;
    setTransform("translate3d(0," + windowScrollTop + "px,0)");
  };
  const classes = useStyles();
  const parallaxClasses = classNames({
    [classes.parallax]: true,
    [classes.small]: small,
  });
  return (
    <div
      className={parallaxClasses}
      style={{
        backgroundImage: "url(" + require("assets/img/map-background.png") + ")",
        transform: transform
      }}
    >
    <div className={classes.container}>
      <GridContainer>
        <GridItem>
          <div className={classes.brand}>
            <Gis1Logo 
              size={150} 
              primaryColor={'transparent'}
              secondaryColor={'white'}
            />
            <h1>Georgia GIS Clearinghouse</h1>
            <h2>Powered by GIS1</h2>
          </div>
        </GridItem>
      </GridContainer>
    </div>
    </div>
  );
}

Parallax.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.oneOf([
    "primary",
    "rose",
    "dark",
    "info",
    "success",
    "warning",
    "danger"
  ]),
  children: PropTypes.node,
  style: PropTypes.string,
  image: PropTypes.string,
  small: PropTypes.bool
};
