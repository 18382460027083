/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";

// Icons
import Layers from "@mui/icons-material/Layers";
import Terrain from "@mui/icons-material/Terrain";
import EditRoad from "@mui/icons-material/EditRoad";
import Place from "@mui/icons-material/Place";
import ShoppingCart from "@mui/icons-material/ShoppingCart";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import Button from "components/CustomButtons/Button";

import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle";
import { ROUTES } from "../../constants";
import { useSelector } from "react-redux";
import { MapsHomeWork } from "@mui/icons-material";
import { DownloadItem } from "models/DownloadItem";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const smoothScroll = (e, target) => {
    if (window.location.pathname === "/sections") {
      var isMobile = navigator.userAgent.match(
        /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
      );
      if (isMobile) {
        // if we are on mobile device the scroll into view will be managed by the browser
      } else {
        e.preventDefault();
        var targetScroll = document.getElementById(target);
        scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
      }
    }
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function() {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };
  var onClickSections = {};

  const { dropdownHoverColor } = props;
  const classes = useStyles();
  const shoppingCartItems = useSelector((state: any) => DownloadItem.hydrate(state.shoppingCart?.items))

  return (
    <List className={classes.list + " " + classes.mlAuto}>
      <Link to={ROUTES.CONTOUR_LINES.LIST.path} className={classes.dropdownLink}>
        <Terrain className={classes.icons} />
        { ROUTES.CONTOUR_LINES.title }
      </Link>
      <Link to={ROUTES.PARCEL_DATA.LIST.path} className={classes.dropdownLink}>
        <Layers className={classes.icons} />
        { ROUTES.PARCEL_DATA.title }
      </Link>
      <Link to={ROUTES.CAMA_DATA.LIST.path} className={classes.dropdownLink}>
        <MapsHomeWork className={classes.icons} />
        { ROUTES.CAMA_DATA.title }
      </Link>
      <Link to={ROUTES.ADDRESS_POINTS.LIST.path} className={classes.dropdownLink}>
        <Place className={classes.icons} />
        { ROUTES.ADDRESS_POINTS.title }
      </Link>
      <Link to={ROUTES.ROAD_CENTERLINES.LIST.path} className={classes.dropdownLink}>
        <EditRoad className={classes.icons} />
        { ROUTES.ROAD_CENTERLINES.title }
      </Link>
      <Link to={ROUTES.SHOPPING_CART.path} className={classes.dropdownLink}>
        <Button
            color={window.innerWidth < 960 ? "info" : "white"}
            className={classes.navButton}
            round
          >
            <ShoppingCart className={classes.icons} />
            { 
              ROUTES.SHOPPING_CART.title + (Object.values(shoppingCartItems).length ?
                ` (${Object.values(shoppingCartItems).length})` :
                ''
              )
            }
        </Button>
      </Link>
    </List>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ])
};
